<template>
    <div>
        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }" @sort-change="sortChange">

            <el-table-column prop="ip" label="ip地址" min-width="100" align="center"></el-table-column>
            <el-table-column prop="url" label="接口地址" min-width="150" align="center"></el-table-column>
            <el-table-column prop="methodName" label="方法名" min-width="70" align="center"></el-table-column>
            <el-table-column prop="method" label="请求方式" min-width="70" align="center"></el-table-column>
            <el-table-column prop="className" label="类名" min-width="150" align="center"></el-table-column>
            <el-table-column prop="time" label="耗时（毫秒）" sortable="custom" min-width="80" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="100" align="center"></el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    logPage
} from "@/api/api";
export default {
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            sort: '',
            dataList: [],
            formLabelWidth: "80px"
        };
    },
    methods: {
        search() {
            this.getLogList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getLogList();
        },
        sortChange(column) {
            if (column.prop == 'time') {
                this.sort = column.order === "ascending" ? 0 : column.order === "descending" ? 1 : ""
            }
            this.getLogList();
        },
        //分页获取实名认证列表
        getLogList() {
            logPage({
                current: this.page,
                size: this.size,
                sort: this.sort
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                })
        }
    },
    mounted() {
        this.getLogList();
    }
};
</script>