<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="请输入用户ID" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <!-- <el-form-item label="状态">
                    <el-select v-model="query.status" placeholder="请选择状态">
                        <el-option label="待审核" value="0"></el-option>
						<el-option label="审核通过" value="1"></el-option>
                        <el-option label="审核拒绝" value="2"></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="nickName" label="用户昵称" min-width="60" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="用户ID" min-width="60" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/'+ scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="unsealTime" label="封禁时间" min-width="80" align="center"></el-table-column>
            <el-table-column prop="remark" label="封禁理由" min-width="80" align="center"></el-table-column>
            <el-table-column prop="content" label="申诉理由" min-width="150" align="center"></el-table-column>
            <el-table-column prop="applyTime" label="申诉时间" min-width="80" align="center"></el-table-column>
            <el-table-column prop="refuseRemark" label="备注" min-width="80" align="center"></el-table-column>
            
            <el-table-column prop="status" label="状态" min-width="50" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.status=== 0" style="color: #E6A23C">待审核</span>
                    <span v-else-if="scope.row.status=== 1" style="color: #67C23A">同意</span>
					<span v-else-if="scope.row.status=== 2" style="color: #F56C6C">拒绝</span>
				</template>
			</el-table-column>
            
            <el-table-column fixed="right" label="操作" min-width="120" align="center">
                <template slot-scope="scope">
                    <el-button type="success" size="mini" icon="el-icon-check" v-if="scope.row.status == 0" @click="approveClick(scope.row.id)">解封</el-button>
                    <el-button type="warning" size="mini" icon="el-icon-close" v-if="scope.row.status == 0" @click="refuseClick(scope.row.id)">拒绝</el-button>
                    <!-- <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除</el-button> -->
                </template>
            </el-table-column>

        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    applyUnsealPage,
    applyUnsealPost,
    applyUnsealDelete,
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                nickName: "",
                userNumber: "",
                status: ""
            },
            formLabelWidth: "80px",
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getApplyUnsealList();
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getApplyUnsealList();
        },
        //获取封号申诉分页列表
        getApplyUnsealList() {
            applyUnsealPage({
                current: this.page,
                size: this.size,
                status: this.query.status,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                    
                })
        },
        approveClick(id) {
            applyUnsealPost({
                id,
                status: 1,
            }).then(response => {
                this.getApplyUnsealList()
            })
        },
        refuseClick(id) {
            this.$prompt('请输入备注','拒绝后 将永久封号 请谨慎操作', {
                closeOnClickModal: false
            }).then(({value}) => {
                if (!value) {
                    this.$message.error('请输入备注')
                    return;
                }
                
                applyUnsealPost({
                    id,
                    status: 2,
                    remark: value
                }).then(response => {
                    this.getApplyUnsealList()
                })
            })
        },
        deleteClick(id) {
            this.$confirm('确定要删除此记录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
                let params = new URLSearchParams()
			    params.append("idList", [id])
				applyUnsealDelete(params).then(response => {
                    this.getApplyUnsealList()
                })
			})
        }
    },
    mounted() {
        this.getApplyUnsealList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>