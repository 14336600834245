<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item :label="typeName[type] + '卡'"></el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-circle-plus-outline" @click="addRow">添加</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="number" label="卡片张数" min-width="100" align="center"></el-table-column>
			<el-table-column prop="coinNumber" label="所需K币数量" min-width="100" align="center"></el-table-column>
			<el-table-column prop="label" label="促销标签" min-width="100" align="center"></el-table-column>
			<el-table-column prop="createTime" label="创建时间" min-width="100" align="center"></el-table-column>
			<el-table-column fixed="right" label="操作" min-width="100" align="center">
				<template slot-scope="scope">
					<el-button type="warning" size="mini" @click="editRow(scope.row)">修改</el-button>
					<el-button type="danger" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<!-- <el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination> -->
		</div>

        <el-dialog width="600px" :title="typeName[type] + '卡'" :visible.sync="formVisible" :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="110px" :rules="formRules">
                <el-form-item prop="number" :label="typeName[type] + '卡张数'">
                    <el-input v-model="form.number" type="number" :placeholder="`请输入${typeName[type]}卡张数`"></el-input>
                </el-form-item>
                <el-form-item prop="coinNumber" label="所需K币数量">
                    <el-input v-model="form.coinNumber" type="number" placeholder="请输入所需K币数量"></el-input>
                </el-form-item>
                <el-form-item prop="label" label="促销标签">
                    <el-input v-model="form.label" maxlength="4" placeholder="填写4字以内的促销内容 不填时不展示标签"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" :loading="formLoading" @click="formConfirm">确 定</el-button>
			</div>
        </el-dialog>
    </div>
</template>

<script>
import {
    matchProductPage,
	matchProductPost,
	matchProductUpdate,
    matchProductDelete
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				
            },
            type: null,
            typeName: ['匹配','同城'],

            formVisible: false,
            formLoading: false,
            form: {},
            formRules: {
                number: [
                    { required: true, message: '请输入卡片张数' }
                ],
                coinNumber: [
                    { required: true, message: '请输入所需k币数量' }
                ],
                /* sort: [
                    { required: true, message: '请输入排序数值' }
                ] */
            }
        }
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        },
        '$route.meta.type': {
            immediate: true,
            handler(val) {
                this.type = val
                //获取匹配卡列表
		        this.getMatchCardList()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getMatchCardList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.lastPayTime = []
			this.expireTime = []

			this.page = 1
			this.getMatchCardList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getMatchCardList()
		},
		changeClick(page) {
			this.page = page
			this.getMatchCardList()
		},
		//获取匹配卡列表接口
		getMatchCardList() {
			matchProductPage({
				"current": this.page,
				"size": this.size,
                "type": this.type
			})
				.then((response) => {
					// this.total = response.total
					// this.tableData = response.records
					this.tableData = response
				})
				.catch((error) => {
					console.log(error)
				});
		},
        formConfirm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const formData = {...this.form}

                    const send = formData.id ? matchProductUpdate : matchProductPost

                    send(formData).then(res => {
                        this.formVisible = false
                        this.getMatchCardList()
                    })
                }
            })
        },
        addRow() {
            this.form = {
                coinNumber: '',
                number: '',
                label: '',
                type: this.type
            }
            this.formVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        editRow(row) {
            this.form = {
                id: row.id,
                coinNumber: row.coinNumber,
                number: row.number,
                label: row.label
            }
            this.formVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        deleteRow(id) {
            this.$confirm('确定要删除此产品?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = new URLSearchParams()
                params.append("idList", [id])
                matchProductDelete({ params })
                    .then(() => {
                        this.getMatchCardList()
                    })
			})
        }
	},
	created() {
		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
</style>