<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
			<el-form :inline="true" :model="query" size="mini" class="demo-form-inline">
                <el-form-item label="设备识别码">
                    <el-input v-model="query.deviceCode" placeholder="设备识别码" style="width: 140px;" @keydown.enter.native="search"></el-input>
                </el-form-item>
				<el-form-item label="ip">
					<el-input v-model="query.ip" placeholder="ip" style="width: 140px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="warning" icon="el-icon-circle-plus-outline" @click="addRow">添加</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="deviceCode" label="设备识别码" min-width="100" align="center"></el-table-column>
			<el-table-column prop="ip" label="ip" min-width="100" align="center"></el-table-column>
			<el-table-column prop="remark" label="备注" min-width="200" align="center"></el-table-column>
			<el-table-column prop="createTime" label="创建时间" min-width="100" align="center"></el-table-column>

			<el-table-column fixed="right" label="操作" min-width="60" align="center">
				<template slot-scope="scope">
					<el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteById(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

        <el-dialog width="600px" title="" :visible.sync="formVisible" :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="100px" :rules="formRules">
                <el-form-item prop="deviceCode" label="设备识别码">
                    <el-input v-model="form.deviceCode" placeholder="请输入设备识别码"></el-input>
                </el-form-item>
                <el-form-item prop="ip" label="ip">
                    <el-input v-model="form.ip" placeholder="请输入ip"></el-input>
                </el-form-item>
                <el-form-item prop="remark" label="备注">
                    <el-input v-model="form.remark" placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" :loading="formLoading" @click="formConfirm">确 定</el-button>
			</div>
        </el-dialog>
    </div>
</template>

<script>
import { deviceBlackPage, deviceBlackPost, deviceBlackDelete } from '@/api/api'
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,

            searchDate: [],

            query: {
                deviceCode: '',
                ip: '',
			},

            formVisible: false,
            formLoading: false,
            form: {},
            formRules: {
                ip: [
                    { required: true, message: '请输入ip' }
                ]
            }
        }
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getBlackList();
		},
        handleSizeChange(size) {
			this.size = size
			this.getBlackList()
		},
		changeClick(page) {
			this.page = page
			this.getBlackList()
		},
        getBlackList() {
			const params = {
				current: this.page,
				size: this.size,
				deviceCode: this.query.deviceCode,
				ip: this.query.ip,
			}

            deviceBlackPage(params).then(response => {
				this.total = response.total
				this.tableData = response.records
			})
        },
        formConfirm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const formData = {...this.form}

                    // const send = formData.id ? deviceBlackUpdate : deviceBlackPost
                    const send = deviceBlackPost

                    send(formData).then(res => {
                        this.formVisible = false
                        this.getBlackList()
                    })
                }
            })
        },
        addRow() {
            this.form = {
                deviceCode: '',
                ip: '',
                remark: ''
            }
            this.formVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        editRow(row) {
            this.form = {
                id: row.id,
                deviceCode: row.deviceCode,
                ip: row.ip,
                remark: row.remark
            }
            this.formVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
		deleteById(id) {
			this.$confirm('确定要删除此记录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = new URLSearchParams()
				params.append("idList", [id])
				deviceBlackDelete({ params }).then(() => {
					this.getBlackList() //删除成功，重新获取数据
				})
			})
		},
    },
    created() {
		//获取拉黑列表
		this.getBlackList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
.flex-center-box {
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>