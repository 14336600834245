<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="请输入用户ID" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="支付平台">
                    <el-select v-model="query.platform" placeholder="请选择支付平台" style="width: 80px;">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="苹果" value="ios"></el-option>
						<el-option label="安卓" value="android"></el-option>
						<el-option label="web" value="web"></el-option>
						<el-option label="其他" value="other"></el-option>
                        <el-option label="后台" value="admin"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否实名">
                    <el-select v-model="query.isRealName" placeholder="请选择是否实名" style="width: 90px;">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="已实名" value="1"></el-option>
						<el-option label="未实名" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="支付类型">
                    <el-select v-model="query.type" placeholder="请选择支付类型" style="width: 90px;">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="苹果" value="0"></el-option>
						<el-option label="微信" value="1"></el-option>
                        <el-option label="支付宝" value="2"></el-option>
                        <el-option label="银行卡" value="3"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="query.status" placeholder="请选择状态" style="width: 100px;">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="待支付" value="0"></el-option>
						<el-option label="已支付" value="1"></el-option>
                        <el-option label="取消支付" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="支付时间">
					<el-date-picker v-model="selectDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" :value-format="'yyyy-MM-ddTHH:mm:ss'" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']">
					</el-date-picker>
				</el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="warning" icon="el-icon-document" @click="exportData">导出excel</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="flex-shrink">
            <el-row :gutter="12">
            <el-col :span="4">
                <el-card shadow="hover" style="width: 100%;margin-bottom: 10px;">
                <div style="width: 100%;">
                    <el-statistic :value="rechargeUserNumber" title="充值人数"></el-statistic>
                </div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="hover" style="width: 100%;margin-bottom: 10px;">
                <div style="width: 100%;">
                    <el-statistic :value="rechargeNumber" title="充值笔数"></el-statistic>
                </div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="hover" style="width: 100%;margin-bottom: 10px; ">
                <div style="width: 100%;">
                    <el-statistic :value="rechargeMoney" title="充值金额"></el-statistic>
                </div>
                </el-card>
            </el-col>
            <el-col :span="4">
                <el-card shadow="hover" style="width: 100%;margin-bottom: 10px; ">
                <div style="width: 100%;">
                    <el-statistic :value="rechargeCoinNumber" title="充值K币"></el-statistic>
                </div>
                </el-card>
            </el-col>
            </el-row>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="nickName" label="用户昵称" min-width="150" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="用户ID" min-width="80" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="isRealName" label="是否实名" min-width="80" align="center">
                <template slot-scope="scope">
                    <div :class="['color-warning','color-success'][scope.row.isRealName]">{{['未实名','已实名'][scope.row.isRealName]}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="orderNum" label="订单编号" min-width="160" align="center"></el-table-column>
            <el-table-column prop="productCode" label="产品编号" min-width="120" align="center"></el-table-column>
            <el-table-column prop="productName" label="产品名称" min-width="120" align="center"></el-table-column>
            <el-table-column prop="productPrice" label="产品价格" min-width="100" align="center"></el-table-column>
            <el-table-column prop="productNum" label="产品数量" min-width="100" align="center"></el-table-column>
            <el-table-column prop="price" label="订单价格" min-width="100" align="center"></el-table-column>
            <el-table-column prop="platform" label="支付平台" min-width="100" align="center"></el-table-column>
            <el-table-column prop="type" label="支付类型" min-width="100" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.type=== 0">苹果</span>
                    <span v-else-if="scope.row.type=== 1">微信</span>
					<span v-else-if="scope.row.type=== 2">支付宝</span>
                    <span v-else-if="scope.row.type=== 3">银行卡</span>
				</template>
            </el-table-column>
            <el-table-column prop="transactionId" label="交易编号" min-width="150" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" min-width="80" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.status=== 0" style="color: #E6A23C">待支付</span>
                    <span v-else-if="scope.row.status=== 1" style="color: #67C23A">已支付</span>
					<span v-else-if="scope.row.status=== 2" style="color: #F56C6C">取消支付</span>
				</template>
			</el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="150" align="center"></el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    coinOrderPage,
    coinOrderStatistics
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                nickName: "",
                userNumber: "",
                isRealName: "",
                type: "",
                status: "1",
                platform: ""
            },
            formLabelWidth: "80px",

            rechargeNumber: 0, //充值笔数
            rechargeMoney: 0, //充值金额
            rechargeCoinNumber: 0, //充值K币
            rechargeUserNumber: 0, //充值人数

            pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

			selectDate: '',
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getCoinOrderList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getCoinOrderList();
        },
        //获取待审核动态分页列表
        getCoinOrderList() {
            coinOrderPage({
                current: this.page,
                size: this.size,
                status: this.query.status,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                isRealName: this.query.isRealName,
                type: this.query.type,
                platform: this.query.platform,
                startDate: this.selectDate != null ? this.selectDate[0] : null,
				endDate: this.selectDate != null ? this.selectDate[1] : null
            })
            .then(response => {
                this.total = response.total;
                this.dataList = response.records;
            })

            //获取统计数据
            coinOrderStatistics({
                status: this.query.status,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                isRealName: this.query.isRealName,
                type: this.query.type,
                platform: this.query.platform,
                startDate: this.selectDate != null ? this.selectDate[0] : null,
				endDate: this.selectDate != null ? this.selectDate[1] : null
            })
            .then(response => {
                this.rechargeNumber = response.rechargeNumber || 0
                this.rechargeMoney = response.rechargeMoney || 0
                this.rechargeCoinNumber = response.rechargeCoinNumber || 0
                this.rechargeUserNumber = response.rechargeUserNumber || 0
            })
        },
        exportData() {
            this.$prompt('请输入导出数据条数(默认100)','导出数据', {
				closeOnClickModal: false
			}).then(({value}) => {
				let number = value != null ? parseInt(value) : 100
				if (isNaN(number)) {
					this.$message.error('请输入整型数值！')
					return;
				} else if (number > 2147483647) {
					this.$message.info('数值超过int最大长度，已变更为2147483647！')
					number = 2147483647
				}

				const exportUrl = '/api/coinOrder/export'

				const params = {
					current: 1,
					size: number,
                    
                    status: this.query.status,
                    nickName: this.query.nickName,
                    userNumber: this.query.userNumber,
                    isRealName: this.query.isRealName,
                    type: this.query.type,
                    platform: this.query.platform,
                    startDate: this.selectDate != null ? this.selectDate[0] : null,
                    endDate: this.selectDate != null ? this.selectDate[1] : null
				}

				let urlParams = '?'
				Object.keys(params).forEach(key => {
					const value = params[key]
					if (value) {
						urlParams += '&' + key + '=' + encodeURIComponent(value)
					}
				})

				console.log(urlParams,'urlParams')
				const fileName = 'K币充值记录.xlsx'
				const xhr = new XMLHttpRequest()
				xhr.open('GET', exportUrl + urlParams, true)
				xhr.responseType = 'blob'
				xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token)
				xhr.onload = () => {
					if (xhr.status == 200) {
						const type = xhr.getResponseHeader('Content-Type')
						const blob = new Blob([xhr.response], {type})
						if (typeof window.navigator.msSaveBlob !== 'undefined') {
							window.navigator.msSaveBlob(blob, fileName)
						} else {
							const URL = window.URL || window.webkitURL
							const blobUrl = URL.createObjectURL(blob)

							const a = document.createElement('a')
							if (typeof a.download === 'undefined') {
								window.open(blobUrl)
							} else {
								a.href = blobUrl
								a.download = fileName
								document.body.appendChild(a)
								a.click()
								a.remove()
							}
						}
					} else {
						this.$message.error('导出失败')
					}
				}
				xhr.send()
			})
        }
    },
    mounted() {
        this.getCoinOrderList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>