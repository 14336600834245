<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="请输入用户ID" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="征友状态">
                    <el-select v-model="query.status" placeholder="请选择征友状态">
                        <el-option label="全部" value=''></el-option>
                        <!-- <el-option label="待审核" value=0></el-option> -->
						<el-option label="征友中" value=1></el-option>
                        <!-- <el-option label="审核拒绝" value=2></el-option> -->
                        <el-option label="取消征友" value=3></el-option>
                        <el-option label="沟通中" value=4></el-option>
                        <el-option label="征友成功" value=5></el-option>
                        <el-option label="征友失败" value=6></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="收益状态">
                    <el-select v-model="query.incomeStatus" placeholder="请选择收益状态">
                        <el-option label="全部" value=''></el-option>
                        <el-option label="待审核" value=0></el-option>
						<el-option label="审核通过" value=1></el-option>
                        <el-option label="审核拒绝" value=2></el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="排序">
                    <el-radio-group v-model="query.sort">
                        <div>
                            <el-radio :label="2">最后状态时间</el-radio>
                        </div>
                        <div>
                            <el-radio :label="1">申请时间</el-radio>
                        </div>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="nickName" label="发布人" min-width="60" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="发布人ID" min-width="50" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="userGender" label="发布人性别" min-width="60" align="center">
                <template slot-scope="scope">
                    {{ {1: '男', 2: '女'}[scope.row.userGender] }}
                </template>
            </el-table-column>
            <el-table-column prop="gender" label="征友性别" min-width="60" align="center">
                <template slot-scope="scope">
                    {{ ['全部','男','女'][scope.row.gender] }}
                </template>
            </el-table-column>
            <el-table-column prop="price" label="征友价格" min-width="60" align="center"></el-table-column>
            <el-table-column prop="hope" label="我希望" min-width="150" align="center">
                <template slot-scope="scope">
                    {{ scope.row.hope }}
                    <span style="margin-left: 10px;cursor: pointer;user-select: none;" @click="editRow(scope.row, 'hope')"><i class="el-icon-edit"></i></span>
                </template>
            </el-table-column>
            <el-table-column prop="refuse" label="我拒绝" min-width="150" align="center">
                <template slot-scope="scope">
                    {{ scope.row.refuse }}
                    <span style="margin-left: 10px;cursor: pointer;user-select: none;" @click="editRow(scope.row, 'refuse')"><i class="el-icon-edit"></i></span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="征友状态" min-width="60" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.status === 0" style="color: #E6A23C">待审核</span>
					<span v-else-if="scope.row.status === 1" style="color: #67C23A">征友中</span>
                    <span v-else-if="scope.row.status === 2" style="color: #F56C6C">审核拒绝</span>
                    <span v-else-if="scope.row.status === 3" style="color: #909399">取消征友</span>
                    <span v-else-if="scope.row.status === 4" style="color: #a94590">沟通中</span>
                    <span v-else-if="scope.row.status === 5" style="color: #15975c">征友成功</span>
                    <span v-else-if="scope.row.status === 6" style="color: #516310">征友失败</span>
				</template>
			</el-table-column>
            <!-- <el-table-column prop="incomeStatus" label="收益状态" min-width="60" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.incomeStatus === 0" style="color: #E6A23C">待审核</span>
					<span v-else-if="scope.row.incomeStatus === 1" style="color: #67C23A">审核通过</span>
                    <span v-else-if="scope.row.incomeStatus === 2" style="color: #F56C6C">审核拒绝</span>
				</template>
			</el-table-column> -->
            <el-table-column prop="updateTime" label="最后状态时间" min-width="80" align="center"></el-table-column>
            <el-table-column prop="createTime" label="发布时间" min-width="80" align="center"></el-table-column>
            <!-- <el-table-column fixed="right" label="操作" width="230" align="center">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.status === 0" type="warning" size="mini" icon="el-icon-edit" @click="okClick(scope.row.id)">同意
                    </el-button>
                    <el-button v-if="scope.row.status === 0" type="danger" size="mini" icon="el-icon-delete" @click="noClick(scope.row.id)">拒绝
                    </el-button>
                    <el-button v-if="scope.row.status === 5 && scope.row.incomeStatus === 0" type="warning" size="mini" icon="el-icon-edit" @click="agreeClick(scope.row.id)">收益通过
                    </el-button>
                    <el-button v-if="scope.row.status === 5 && scope.row.incomeStatus === 0" type="warning" size="mini" icon="el-icon-edit" @click="agreeClick(scope.row.id)">收益拒绝
                    </el-button>
                </template>
            </el-table-column> -->
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog title="编辑" :visible.sync="editDialog" :close-on-click-modal="false">
            <el-input v-model="editForm[editForm.field]"></el-input>

            <div slot="footer" class="dialog-footer">
                <el-button @click="closeEditDialog">取 消</el-button>
                <el-button type="primary" @click="editConfirm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    pullWirePage,
    pullWireUpdate,
    pullWireVerify,
    pullWireIncomePass,
    pullWireIncomeRefuse
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                nickName: "",
                status: "",
                incomeStatus: "",
                sort: 2
            },
            formLabelWidth: "80px",

            editForm: {
                id: '',
                refuse: '',
                hope: '',
                field: '',
            },
            editDialog: false
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getPullWireList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getPullWireList();
        },
        //获取待审核动态分页列表
        getPullWireList() {
            pullWirePage({
                current: this.page,
                size: this.size,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                status: this.query.status,
                sort: this.query.sort,
                incomeStatus: this.query.incomeStatus
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                })
        },
        okClick(id) {
            pullWireVerify({
                id: id,
                status: 1
            })
            .then(response => {
                this.getPullWireList();
            })
        },
        noClick(id) {
            this.$prompt('请输入拒绝理由', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false
            }).then(({ value }) => {
                pullWireVerify({
                    id: id,
                    status: 2,
                    remark: value
                })
                .then(response => {
                    this.getPullWireList();
                })
            }).catch(() => {});
        },
        agreeClick(id) {
            pullWireIncomePass(id)
            .then(response => {
                this.getPullWireList();
            })
        },
        refuseClick(id) {
            pullWireIncomeRefuse(id)
            .then(response => {
                this.getPullWireList();
            })
        },
        editRow(row, field) {
            this.editForm[field] = row[field]
            this.editForm.id = row.id
            this.editForm.field = field
            this.editDialog = true
        },
        editConfirm() {
            if (this.editForm[this.editForm.field] == '') {
                this.$message.error('请输入内容')
                return;
            }

            const params = {
                id: this.editForm.id,
            }
            params[this.editForm.field] = this.editForm[this.editForm.field]

            pullWireUpdate(params).then(res => {
                this.getPullWireList()
                this.closeEditDialog()
            })
        },
        closeEditDialog() {
            this.editDialog = false
        },
    },
    mounted() {
        this.getPullWireList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>