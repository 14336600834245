<template>
    <div :id="item.idClient" class="chat-message-item">
        <div class="chat-message-item-wrap" :class="{self: isSelf}" v-if="msgUserInfo">
            <div class="item-avatar">
                <avatar :src="msgUserInfo.avatar" />
            </div>
            <div class="item-content-box">
                <div class="item-nick">{{msgUserInfo.nick}}</div>
                <div class="item-content">
                    <div class="item-status"  v-if="isSelf">
                        <div class="common-percent-wrap">
                            <div class="icon" v-if="item.status == 'sending'"><i class="el-icon-loading"></i></div>
                            <div class="icon fail" v-else-if="item.status == 'fail'"><i class="el-icon-warning" @click="resend"></i></div>
                            <div class="icon" v-else-if="isReaded"><i class="el-icon-circle-check"></i></div>
                            <div class="unread" v-else-if="item.status == 'success'"></div>
                        </div>
                    </div>
                    <div class="item-body" @contextmenu.prevent="openContextMenu">
                        
                        <div class="body-txt" v-if="item.type == 'text'" v-html="textContent"></div>

                        <div class="body-image" v-else-if="item.type == 'custom' && customImage">
                            <el-image :src="customImage" :preview-src-list="[customImage]" :key="customImage" />
                        </div>
                        
                        <div class="body-image" v-else-if="item.type == 'image' && item.file">
                            <el-image :src="item.file.url" :preview-src-list="[item.file.url]" :key="item.file.url" />
                        </div>
                        <div class="body-video" v-else-if="item.type == 'video' && item.file">
                            <video :src="item.file.url" :key="item.file.url" controls></video>
                        </div>
                        <div class="body-audio" v-else-if="item.type == 'audio' && item.file">
                            <audio controls="controls">
                                <source :src="item.file.url" :type="item.file?.type">
                            </audio>
                        </div>

                        <div class="body-tip" v-else-if="item.type == 'tip'">{{item.tip}}</div>
                    </div>
                </div>
                <div class="item-date">{{msgDate}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import avatar from '../common/avatar'
export default {
    components: {
        avatar,
    },
    data() {
        return {
            fileDomain: 'https://file.oiki.cc/',
        }
    },
    props: {
        item: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        ...mapGetters('nim', ['sessionMap', 'myUserInfo', 'userInfos', 'emojisList']),
        isSelf () {
            return this.item.from === this.myUserInfo.account
        },
        msgUserInfo() {
            return this.isSelf ? this.myUserInfo : this.userInfos[this.item.from]
        },
        msgDate() {
            if (this.item.status == 'sending') return ''

            const date = new Date(this.item.time)
            const nowDate = new Date()

            const year = date.getFullYear().toString()
            const month = (date.getMonth() + 1).toString().padStart(2, '0')
            const day = date.getDate().toString().padStart(2, '0')
            const hour = date.getHours().toString().padStart(2, '0')
            const minute = date.getMinutes().toString().padStart(2, '0')
            const second = date.getSeconds().toString().padStart(2, '0')
            
            let timeStr = ''
            if (date.getFullYear() != nowDate.getFullYear()) {
                timeStr += `${year}-`
            }

            if (date.getDate() != nowDate.getDate()) {
                timeStr += `${month}-${day} `
            }

            timeStr += `${hour}:${minute}:${second}`

            return timeStr
        },
        isReaded() {
            if (this.isSelf) {
                const sessionId = `p2p-${this.item.to}`
                const { msgReceiptTime } = this.sessionMap[sessionId]
                if (this.item.time <= msgReceiptTime) {
                    return true
                }
            }
            return false
        },
        textContent() {
            return this.item.text.replace(/\[#(.*?)\]/g, (searchValue, matechValue) => {
                const src = this.fileDomain + this.getEmojiUrlByName(matechValue)
                const img = `<img class="emoji" src="${src}" />`
                return img;
            });
        },
        customImage() {
            const customContent = JSON.parse(this.item.content)
            console.log(customContent,'customContent')
            if (this.item.type == 'custom' && customContent.url) {
                return this.fileDomain + customContent.url
            }
            return ''
        },
        emojiData() {
            const emoji = {}
            for (const item of this.emojisList) {
                emoji[item.name] = item.url
            }
            // console.log(emoji,'emoji')
            return emoji
        },
    },
    methods: {
        ...mapActions('nim', ['deleteMsg', 'recallMsg']),

        getEmojiUrlByName(emoji) {
            return this.emojiData[emoji]
        },

        async getAuidoType () {
            if (this.item.type == 'audio' && this.item.file) {
                try {
                    const res = await fetch(this.item.file.url)
                    if (res.ok) {
                        const blob = await res.blob();
                        this.item.file.type = bold.type
                    }
                } catch {}
            }
        },

        openContextMenu(e) {
            // console.log(e,'eee')
            const items = []

            const deleteFn = {
                label: '删除',
                onClick: () => {
                    console.log(e,'删除')
                    this.deleteMsg(this.item)
                }
            }

            const recallFn = {
                label: '撤回',
                onClick: () => {
                    console.log(e,'撤回')
                    this.recallMsg(this.item)
                }
            }

            items.push(deleteFn)
            
            if (this.item.from == this.myUserInfo.account && this.item.time >= Date.now() - 2 * 60 * 1000) {
                items.push(recallFn)
            }

            this.$contextmenu({
                items,
                event: e,
                minWidth: 60
            })
        },

        resend() {
            if (this.item.type == 'text') {

            }
        }
    },
    created() {
        this.getAuidoType()
    }
}
</script>

<style scoped>
.chat-message-item-wrap {
    padding: 8px;
    display: flex;
    text-align: left;
}

.chat-message-item-wrap.self {
    flex-direction: row-reverse;
}

.chat-message-item-wrap .item-avatar {
    margin-right: 12px;
}

.chat-message-item-wrap.self .item-avatar {
    margin-left: 12px;
}

.chat-message-item-wrap .item-content-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.chat-message-item-wrap.self .item-content-box {
    align-items: flex-end;
}

.chat-message-item-wrap .item-nick {
    line-height: 22px;
    font-size: 14px;
    color: #666;
}

.chat-message-item-wrap .item-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-message-item-wrap .item-content .item-status {
    margin-right: 8px;
}

.chat-message-item-wrap .item-content .item-status .unread {
    width: 14px;
    height: 14px;
    border: 1px solid #537ff4;
    border-radius: 50%;
    box-sizing: border-box;
}

.chat-message-item-wrap .item-content .item-status .icon {
    font-size: 16px;
    color: #537ff4;
}

.chat-message-item-wrap .item-content .item-status .icon.fail {
    color: #ff4d4f;
    cursor: pointer;
}


.chat-message-item-wrap .item-content .item-body {
    max-width: 442px;
    min-width: 50px;
    line-height: 22px;
    background-color: #e8eaed;
    border-radius: 0 12px 12px 12px;
    overflow: hidden;
    white-space: break-spaces;
    word-break: break-all;
    word-wrap: break-word;
}

.chat-message-item-wrap.self .item-content .item-body {
    background-color: #d6e5f6;
    border-radius: 12px 0px 12px 12px;
}

.chat-message-item-wrap .item-content .item-body .body-txt {
    padding: 12px 16px;
}

.chat-message-item-wrap .item-content .item-body .body-txt >>> .emoji {
    width: 22px;
    height: 22px;
}

.chat-message-item-wrap .item-content .item-body .body-image {
    max-width: 200px;
    line-height: 0;
}

.chat-message-item-wrap .item-content .item-body .body-video {

}

.chat-message-item-wrap .item-content .item-body .body-video video {
    max-width: 300px;
    min-width: 150px;
    max-height: 300px;
    min-height: 150px;
    object-fit: cover;
    vertical-align: middle;
}

.chat-message-item-wrap .item-content .item-body .body-audio {

}

.chat-message-item-wrap .item-content-box .item-date {
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    color: #999;
    margin-top: 4px;
}
</style>