import { render, staticRenderFns } from "./RewardReview.vue?vue&type=template&id=4b4b1875&scoped=true&"
import script from "./RewardReview.vue?vue&type=script&lang=js&"
export * from "./RewardReview.vue?vue&type=script&lang=js&"
import style0 from "./RewardReview.vue?vue&type=style&index=0&id=4b4b1875&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b4b1875",
  null
  
)

export default component.exports