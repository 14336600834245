<template>
    <div>
        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item>
                <el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="请输入用户ID"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="nickName" label="用户昵称" min-width="100" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="用户ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/'+ scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="content" label="反馈内容" min-width="180" align="center"></el-table-column>
            <el-table-column prop="images" label="反馈图片" width="400" align="center">
                <template slot-scope="scope">
                    <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.images" :key="index" :src="item"
                        :preview-src-list="scope.row.images" >
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="video" label="反馈视频" width="180" align="center">
                <template slot-scope="scope">
                    <video v-if="scope.row.video" muted="muted" class="video" :src="scope.row.video" type="video/mp4" autoplay="autoplay" style="width: 150px; height: 150px; padding-top: 10px;" controls="controls" loop="-1">
                        <p>你的浏览器不支持video标签.</p>
                    </video>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="150" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="120" align="center">
                <template slot-scope="scope">
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    questionPage,
    questionDelete
} from "@/api/api";
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            query: {
                nickName: "",
                userNumber: "",
            },
            formLabelWidth: "80px",
        };
    },
    methods: {
        search() {
            this.page = 1;
            this.getQuestionList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getQuestionList();
        },
        //分页获取问题反馈列表
        getQuestionList() {
            questionPage({
                current: this.page,
                size: this.size,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                    for (let i = 0; i < this.dataList.length; i++) {
                        let question = this.dataList[i];
                        if (question.image != null && question.image.length > 0) {
                            question.images = question.image.split(",")
                            for(let j = 0; j < question.images.length; j++) {
                                question.images[j] = "https://file.oiki.cc/" + question.images[j]
                            }
                        }
                        if (question.video != null && question.video.length > 0) {
                            question.video = "https://file.oiki.cc/" + question.video
                        }
                    }
                })
        },
        //删除事件
        deleteClick(id) {
            this.$confirm('确定要删除此问题反馈?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteComment(id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
        },
        deleteComment(id) {
            let params = new URLSearchParams()
			params.append("idList", [id])
			questionDelete({ params })
				.then(() => {
					window.location.reload() //刷新页面
				})
        }
    },
    mounted() {
        this.getQuestionList();
    }
};
</script>