<template>
    <div class="chat-message-input">
        <div class="chat-message-input-wrap">
            <div class="chat-message-input-content">
                <!-- <textarea class="ant-input chat-message-input-textarea" v-model="inputText" @keydown.enter.prevent="inputEnter" /> -->
                <el-input ref="input" class="ant-input chat-message-input-textarea" type="textarea" :autosize="{ minRows: 1, maxRows: 5}" :placeholder="placeholder" v-model="inputText" @keydown.enter.prevent.native="inputEnter" @paste.native="inputPaste" />
                <div class="chat-message-input-icon-box">
                    <el-popover
                        v-model="emojiVisible"
                        trigger="click"
                        placement="top"
                        :visible-arrow="false"
                    >
                        <div class="emoji-content">
                            <div class="emoji-list">
                                <div class="item" :title="item.name" v-for="item in emojisList" :key="item.id" @click="writeEmoji(item.name)">
                                    <el-image :src="fileDomain + item.url"></el-image>
                                </div>
                            </div>
                        </div>
                        <div class="message-btn face" slot="reference"></div>
                    </el-popover>
                    <div class="message-btn pictrue" @click="chooseImg"></div>
                    <!-- <div class="message-btn folder"></div> -->
                    <el-popover
                        v-model="quickVisible"
                        trigger="click"
                        placement="top-end"
                        :visible-arrow="false"
                    >
                        <div class="quick-send-list">
                            <div class="item" v-for="(text, index) in quickList" :key="index" @click="quickSend(text)">{{text}}</div>
                        </div>
                        <div class="message-btn send" slot="reference" _click="send"></div>
                    </el-popover>
                </div>
                <!-- <input type="file" id="chatFileInput" style="display: none;" @change="fileChange" /> -->
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    data () {
        return {
            inputText: '',
            sending: false,  // 发送中状态

            fileDomain: 'https://file.oiki.cc/',
            emojiVisible: false,
            quickVisible: false,

            quickList: [
                '您好',
                '是遇到什么问题了吗？'
            ]
        }
    },
    computed: {
        ...mapGetters('nim', ['currentUser', 'userInfos', 'emojisList']),
        placeholder() {
            const userInfo = this.userInfos[this.currentUser]
            let str = ''
            if (userInfo && userInfo.nick) {
                str += `发送给 ${userInfo.nick}`
            }
            str += '（按enter直接发送，shift+enter换行）'

            return str
        }
    },
    methods: {
        ...mapMutations('nim', ['addHook']),
        ...mapActions('nim', ['sendText', 'sendImage', 'previewFile']),
        async inputEnter(e) {
            if (this.sending) return;
            
            if (e.shiftKey) {   // shift + 回车
                const { selectionStart, selectionEnd } = e.target
                console.log(selectionStart, selectionEnd,'selectionStart')
                const inputValue = this.inputText
                this.inputText = inputValue.substring(0, selectionStart) + '\n' + inputValue.substring(selectionEnd);   // 在光标处插入换行符

                this.$nextTick(() => {
                    // 移动光标位置
                    e.target.setSelectionRange(selectionStart + 1, selectionStart + 1);
                    e.target.scrollTop = e.target.scrollHeight
                });
                return;
            }

            this.send()
        },

        async send() {
            if (this.sending) return;

            if (this.inputText == '') {
                this.$message.warning('不能发送空白消息')
                return;
            }

            this.sending = true

            await this.sendText(this.inputText)
            this.inputText = ''
            this.sending = false
        },

        quickSend(text) {
            this.sendText(text)
            this.quickVisible = false
        },

        inputPaste(e) {
            console.log(e,'inputPaste')

            const clipboardData = e.clipboardData || window.clipboardData

            console.log(clipboardData.items.length,'clipboardData.items.length')
            for (let i = 0; i < clipboardData.items.length; i++) {
                const item = clipboardData.items[i]
                console.log(item,'item')
                if (item.kind === 'file' && item.type.startsWith('image/')) {
                    const file = item.getAsFile()

                    /* const blob = file.slice(0, file.size, file.type)
                    this.previewFile(blob, 'image').then(res => {
                        this.sendImage(res)
                    }) */
                }
            }

            console.log(clipboardData,'clipboardData')
        },

        chooseImg() {
            const inputId = 'chatFileInput'
            
            const oldFileInput = document.getElementById(inputId)
            if (oldFileInput) oldFileInput.remove()
            
            // const fileInput = document.getElementById(inputId)
            const fileInput = document.createElement('input')
            fileInput.id = inputId
            fileInput.type = 'file'
            fileInput.accept = 'image/jpeg,image/png,image/gif'
            fileInput.style.display = 'none'
            fileInput.onchange = (e) => {
                console.log(e.target.value,'files')
                this.sendImage(inputId).then(res => {
                    fileInput.remove()
                })
            }

            document.body.appendChild(fileInput)
            fileInput.click()
        },

        /* async fileChange(e) {
            const inputId = 'chatFileInput'
            const fileInput = document.getElementById(inputId)
            // const fileInput = document.getElementById('chatFileInput')
            
            this.sendImage(inputId)
            
            // fileInput.value = ''
        }, */

        writeEmoji(emoji) {
            const input = this.$refs.input.getInput()

            const emojiText = `[#${emoji}]`
            
            const { selectionStart, selectionEnd } = input
            console.log(selectionStart, selectionEnd,'selectionStart')
            const inputValue = this.inputText
            this.inputText = inputValue.substring(0, selectionStart) + emojiText + inputValue.substring(selectionEnd);   // 在光标处插入emoji
            this.emojiVisible = false

            this.$nextTick(() => {
                const newStart = emojiText.length
                // 移动光标位置
                input.setSelectionRange(selectionStart + newStart, selectionStart + newStart);
                this.$refs.input.focus()
            });
        },

    },
    created() {

        this.addHook({
            hookName: 'sendMsg',
            funcName: 'clearInput',
            func: (msg) => {
                console.log(msg, 'clearInput')
            }
        })
        this.addHook({
            hookName: 'changeSession',
            funcName: 'saveInput',
            func: ({oldAccount, newAccount}) => {
                // console.log(oldAccount, newAccount, 'change')
            }
        })
        
        this.addHook({
            hookName: 'writeInput',
            funcName: 'receiveInput',
            func: (text) => {
                // console.log(text,'text')
                this.inputText = text
                this.$refs.input.focus()
            }
        })

        /* this.addHook({
            hookName: 'sendMsgSuccess',
            funcName: ''
        }) */
    }
}
</script>

<style scoped>
.chat-message-input-textarea {
    /* height: 46px; */
    /* max-height: 68px; */
    resize: none;
}

.chat-message-input-textarea >>> .el-textarea__inner {
    padding: 0;
    border: 0;
    resize: none;
}

.chat-message-input-textarea:focus {
    border-color: transparent;
    box-shadow: none;
}

.chat-message-input-icon-box {
    padding: 12px 0;
    display: flex;
    align-items: flex-end;
}

.chat-message-input-icon-box .message-btn {
    width: 20px;
    height: 20px;
    color: #aaa;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin-right: 12px;
    opacity: .5;
    user-select: none;
    cursor: pointer;
}

.emoji-content {
    width: 420px;
    max-height: 327px;
}

.emoji-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.emoji-list .item {
    width: 42px;
    height: 36px;
    padding: 7px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.emoji-list .item:hover {
    background-color: #eee;
}

.quick-send-list {
    max-width: 300px;
    padding: 5px 0;
    margin: -12px;
}

.quick-send-list .item {
    padding: 5px 10px;
    user-select: none;
    cursor: pointer;
}

.quick-send-list .item:hover {
    background-color: #efefef;
}

.quick-send-list .item:not(:first-child) {
    border-top: 1px solid #efefef;
}

.message-btn.face {background-image: url('@/assets/images/face.svg');}
.message-btn.pictrue {background-image: url('@/assets/images/pictrue.svg');}
.message-btn.folder {background-image: url('@/assets/images/folder.svg');}
.message-btn.send {background-image: url('@/assets/images/send.svg');}
</style>