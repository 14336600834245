<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="用户编号">
                    <el-input v-model="query.userNumber" placeholder="请输入用户编号"></el-input>
                </el-form-item>
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="query.status" placeholder="请选择状态">
                        <el-option label="待审核" value="0"></el-option>
						<el-option label="审核通过" value="1"></el-option>
                        <el-option label="审核拒绝" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="定制状态">
                    <el-select v-model="query.customStatus" placeholder="请选择定制状态">
                        <el-option label="待定制" value="0"></el-option>
						<el-option label="定制中" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="userNumber" label="用户编号" min-width="120" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="nickName" label="用户昵称" min-width="120" align="center"></el-table-column>
            <el-table-column prop="age" label="年龄" min-width="80" align="center"></el-table-column>
            <el-table-column prop="phone" label="登录手机" min-width="120" align="center"></el-table-column>
            <el-table-column prop="realName" label="实名姓名" min-width="100" align="center"></el-table-column>
            <el-table-column prop="idCard" label="身份证号" min-width="150" align="center"></el-table-column>
            <el-table-column prop="realImage" label="实名照片" min-width="100" align="center">
                <template slot-scope="scope">
                    <el-image v-if="scope.row.realImage" style="height: 60px; width: 60px; padding: 2px;" :src="scope.row.realImage" :preview-src-list="[scope.row.realImage]"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="score" label="颜值分" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-button type="success" plain size="mini" @click="scoreClick(scope.row)">{{scope.row.score}}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="canGo" label="交友地区" min-width="240" align="center">
                <template slot-scope="scope">
                    <span>{{scope.row.canGo}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="monthFee" label="月消费" min-width="150" align="center">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.monthFeeStatus=== 0" type="danger" plain size="mini" @click="checkClick(scope.row.id, 1, '月消费')">{{scope.row.monthFee/10000}}万元</el-button>
                    <span v-else>{{scope.row.monthFee/10000}}万元</span>
                </template>
            </el-table-column>
            <el-table-column prop="careerName" label="职业" min-width="100" align="center"></el-table-column>
            <el-table-column prop="coverImage" label="封面图" min-width="210" align="center">
                <template slot-scope="scope">
                    <el-image style="height: 60px; width: 60px; padding: 2px;" :src="'https://file.oiki.cc/' + scope.row.coverImage" :preview-src-list="['https://file.oiki.cc/' + scope.row.coverImage]">
                    </el-image>
                    <div>
                        <el-button v-if="scope.row.coverStatus=== 0" type="danger" plain size="mini" @click="checkClick(scope.row.id, 2, '封面图')">审核</el-button>
                        <el-button type="primary" plain size="mini" @click="replaceClick(scope.row)">替换</el-button>
                        <el-button type="primary" plain size="mini" @click="mosaicClick(scope.row)">打码</el-button>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="mosaicImage" label="马赛克封面" min-width="150" align="center">
                <template slot-scope="scope">
                    <el-image v-if="scope.row.mosaicImage" style="height: 60px; width: 60px; padding: 2px;" :src="'https://file.oiki.cc/' + scope.row.mosaicImage" :preview-src-list="['https://file.oiki.cc/' + scope.row.mosaicImage]">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="faceImages" label="颜值照片" min-width="320" align="center">
                <template slot-scope="scope">
                    <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.faceImages"
                        :key="index" :src="item" :preview-src-list="scope.row.faceImages">
                    </el-image>
                    <el-button v-if="scope.row.faceStatus=== 0" type="danger" plain size="mini" @click="checkClick(scope.row.id, 3, '五官照')">审核</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="wholeImages" label="身材照片" min-width="400" align="center">
                <template slot-scope="scope">
                    <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.wholeImages"
                        :key="index" :src="item" :preview-src-list="scope.row.wholeImages">
                    </el-image>
                    <el-button v-if="scope.row.wholeStatus=== 0" type="danger" plain size="mini" @click="checkClick(scope.row.id, 4, '全身照')">审核</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="customerStatus" label="客服微信" min-width="100" align="center">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.customerStatus=== 0 || scope.row.customerStatus=== 2" type="danger" plain size="mini" @click="checkClick(scope.row.id, 5, '客服微信')">未添加</el-button>
                    <span style="color:#67C23A" v-else>已添加</span>
                </template>
            </el-table-column>
            <el-table-column prop="customStatus" label="定制状态" min-width="90" align="center">
				<template slot-scope="scope">
					<el-button v-if="scope.row.customStatus=== 0" size="mini" style="color: #E6A23C" @click="customClick(scope.row, 1)">待定制</el-button>
                    <el-button v-else-if="scope.row.customStatus=== 1" size="mini" style="color: #67C23A" @click="customClick(scope.row, 0)">定制中</el-button>
				</template>
			</el-table-column>
            <el-table-column prop="status" label="状态" min-width="80" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.status=== 0" style="color: #E6A23C">待审核</span>
                    <span v-else-if="scope.row.status=== 1" style="color: #67C23A">通过</span>
					<span v-else-if="scope.row.status=== 2" style="color: #F56C6C">拒绝</span>
				</template>
			</el-table-column>
            <el-table-column prop="customerRemark" label="客服备注" min-width="200" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="140" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" min-width="180" align="center">
                <template slot-scope="scope">
                    <!-- <el-button v-if="scope.row.status === 0" type="success" size="mini" @click="verifyClick(scope.row.id, 1, 0, null)">一键同意</el-button> -->
                    <el-button type="primary" size="mini" icon="el-icon-edit" @click="remarkClick(scope.row)">备注</el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog
            title="封面图"
            :visible.sync="uploadShow"
            :close-on-click-modal="false"
            width="410px"
            center>
            <el-upload
                drag
                :limit="1"
                action="api/file/upload"
                :headers="headers" 
                :data="uploadData"
                :on-success="imageSuccess"
                :on-remove="imageRemove"
                multiple>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            </el-upload>
            <span slot="footer" class="dialog-footer">
                <el-button @click="uploadShow = false">取 消</el-button>
                <el-button type="primary" @click="updateImageClick">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    beautyGirlPage,
    beautyGirlUpdate,
    beautyGirlVerify,
    beautyGirlDelete,
    beautyGirlSuccess
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                userNumber: "",
                nickName: "",
                status: "",
                customStatus: "",
                type: 1
            },
            formLabelWidth: "80px",

            updateShow: false,
            updateForm: {
                id: "",
                score: "",
                customStatus: "",
                userId: ""
            },

            uploadShow: false,
            headers: {
                Authorization: "Bearer " + localStorage.token
            },
            uploadData: {
                code: "3001",
                index: 0
            },
            uploadImage: "",

            type: 0 //类型 0替换 1打码  默认为0
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getBeautyGirlList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getBeautyGirlList();
        },
        //分页获取实名认证列表
        getBeautyGirlList() {
            beautyGirlPage({
                current: this.page,
                size: this.size,
                type: this.query.type,
                status: this.query.status,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                customStatus: this.query.customStatus
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                    for (let i = 0; i < this.dataList.length; i++) {
                        let beautyGirl = this.dataList[i];
                        if (beautyGirl.realImage != null && beautyGirl.realImage.length > 0) {
                            beautyGirl.realImage = "https://file.oiki.cc/" + beautyGirl.realImage
                        }
                        if (beautyGirl.faceImage != null && beautyGirl.faceImage.length > 0) {
                            beautyGirl.faceImages = beautyGirl.faceImage.split(",")
                            for (let j = 0; j < beautyGirl.faceImages.length; j++) {
                                beautyGirl.faceImages[j] = "https://file.oiki.cc/" + beautyGirl.faceImages[j]
                            }
                        }
                        if (beautyGirl.wholeImage != null && beautyGirl.wholeImage.length > 0) {
                            beautyGirl.wholeImages = beautyGirl.wholeImage.split(",")
                            for (let j = 0; j < beautyGirl.wholeImages.length; j++) {
                                beautyGirl.wholeImages[j] = "https://file.oiki.cc/" + beautyGirl.wholeImages[j]
                            }
                        }
                    }
                })
        },
        //颜值打分
        scoreClick(row) {
            this.$prompt('颜值打分', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPlaceholder: '请输入0-100的用户颜值分数',
                center: true,
                closeOnClickModal: false
            }).then(({ value }) => {
                this.updateForm.id = row.id
                this.updateForm.userId = row.userId
                this.updateForm.score = value
                this.submitUpdate()
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });
            });
        },
        //单条审核
        checkClick(id, type, checkName) {
            if (type == 5) {
                this.$confirm(checkName + '审核', {
                    confirmButtonText: '拒绝',
                    cancelButtonText: '同意',
                    center: true,
                    distinguishCancelAndClose: true,
                    closeOnClickModal: false
                }).then(() => {
                    //点击拒绝按钮
                    this.verifyClick(id, 2, type, null)
                }).catch((action) => {
                    //点击同意按钮
                    if (action === 'cancel') {
                        this.verifyClick(id, 1, type, null)
                    }
                });
            } else {
                this.$prompt(checkName+'审核', {
                    confirmButtonText: '拒绝',
                    cancelButtonText: '同意',
                    inputPlaceholder: '请输入拒绝理由，拒绝时必填',
                    center: true,
                    distinguishCancelAndClose: true,
                    closeOnClickModal: false
                }).then(({ value }) => {
                    if (value == null) {
                        this.$message.error("请输入拒绝理由");
                    } else {
                        //点击拒绝按钮
                        this.verifyClick(id, 2, type, value)
                    }
                }).catch((action) => {
                    //点击同意按钮
                    if (action === 'cancel') {
                        this.verifyClick(id, 1, type, null)
                    }
                });
            }
            
        },
        //一键同意
        verifyClick(id, status, type, remark) {
            beautyGirlVerify({
                id: id,
                status: status,
                type: type,
                remark: remark
            }).then(response => {
                this.getBeautyGirlList();
            })
        },
        //改变定制状态
        customClick(row, status) {
            this.updateForm.id = row.id
            this.updateForm.customStatus = status
            this.updateForm.userId = row.userId
            if (status == 0) {
                this.$confirm('', '重新订制', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    center: true
                }).then(({ value }) => {
                    //确定
                    this.submitUpdate()
                })
            } else {
                this.$prompt('', '订制成功', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPlaceholder: '请输入对方用户编号',
                    center: true,
                    closeOnClickModal: false
                }).then(({ value }) => {
                    //确定
                    this.successRequest(value)
                })
            }
        },
        //备注事件
        remarkClick(row) {
            this.$prompt('','客服备注', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValue: row.customerRemark,
                inputPlaceholder: '请输入客服备注',
                center: true,
                closeOnClickModal: false
            }).then(({ value }) => {
                if (value == null) {
                    this.$message.error("请输入备注内容");
                } else {
                    //点击确定按钮
                    this.updateForm.id = row.id
                    this.updateForm.userId = row.userId
                    this.updateForm.customerRemark = value
                    this.submitUpdate()
                }
            }).catch(() => {});
        },
        //编辑提交
        submitUpdate() {
            let newCoverImage = null
            let mosaicImage = null
            if (this.uploadImage.length > 0) {
                if (this.type == 0) {
                    newCoverImage = this.uploadImage
                } else {
                    mosaicImage = this.uploadImage
                }
            };
            beautyGirlUpdate({
                id: this.updateForm.id,
                score: this.updateForm.score,
                userId: this.updateForm.userId,
                newCoverImage: newCoverImage,
                mosaicImage: mosaicImage,
                customStatus: this.updateForm.customStatus,
                customerRemark: this.updateForm.customerRemark
            }).then(() => {
                this.getBeautyGirlList();
            })
            this.updateShow = false
        },
        //订制成功
        successRequest(value) {
            beautyGirlSuccess({
                id: this.updateForm.id,
                customStatus: this.updateForm.customStatus,
                userId: this.updateForm.userId,
                userNumber: value
            }).then(() => {
                this.getBeautyGirlList();
            })
            this.updateShow = false
        },
        //删除事件
        deleteClick(id) {
            this.$confirm('确定要删除此美女?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deleteBeautyGirl(id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除',
                    center: true
                });
            });
        },
        //删除请求
        deleteBeautyGirl(id) {
            beautyGirlDelete(id, null)
                .then(() => {
                    // window.location.reload() //刷新页面
                    this.getBeautyGirlList()
                })
        },
        //图片替换
        replaceClick(row) {
            this.type = 0
            this.uploadImage = null
            this.uploadShow = true

            this.updateForm.id = row.id
            this.updateForm.userId = row.userId
        },
        //图片打码
        mosaicClick(row) {
            this.type = 1
            this.uploadImage = null
            this.uploadShow = true

            this.updateForm.id = row.id
            this.updateForm.userId = row.userId
        },
        //图片上传成功回调
        imageSuccess(response, file, fileList) {
            if (response.code == 0) {
                //上传成功
                this.uploadImage = response.data
            }
        },
        //图片删除
        imageRemove() {
            this.uploadImage = null
        },
        //图片更新
        updateImageClick() {
            this.uploadShow = false
            this.submitUpdate()
        },
    },
    mounted() {
        this.getBeautyGirlList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>
