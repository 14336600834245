<template>
    <div>

        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="分类">
                    <el-select v-model="query.categoryId" placeholder="请选择教程分类">
						<el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="success" @click="addClick">添加</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="title" label="标题" min-width="150" align="center"></el-table-column>
            <el-table-column prop="image" label="内容图片" min-width="150" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 100px; height: 100px; padding-top: 5px;" :src="'https://file.oiki.cc/' + scope.row.image"
                        :preview-src-list="['https://file.oiki.cc/' + scope.row.image]">
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="categoryName" label="所属分类" min-width="150" align="center"></el-table-column>
            <el-table-column prop="type" label="类型" min-width="150" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.type === 0">安全课堂</span>
					<span v-else-if="scope.row.type === 1">关于我们</span>
				</template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="150" align="center"></el-table-column>
            <el-table-column prop="createName" label="创建人" min-width="150" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="150" align="center">
                <template slot-scope="scope">
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog title="添加教程" :visible.sync="addShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form :model="addForm">
				<el-form-item label="分类" :label-width="formLabelWidth">
                    <el-select v-model="addForm.categoryId" placeholder="请选择教程分类">
						<el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="标题" :label-width="formLabelWidth">
					<el-input v-model="addForm.title" placeholder="请输入类名" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="内容图片" :label-width="formLabelWidth">
					<el-upload action="api/file/upload"
                        :headers="headers"
                        :data="uploadData"
                        list-type="picture-card"
						:on-preview="handlePictureCardPreview" :class="{ disabled: uploadDisabled }" :limit="1"
						:file-list="imageList" :on-remove="imageRemove" :on-success="imageSuccess"
						:on-change="handleChange" :on-error="uploadError">
						<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addShow = false">取 消</el-button>
				<el-button type="primary" @click="addCourse">确 定</el-button>
			</div>
		</el-dialog>

    </div>
</template>

<script>
import {
    courseCategoryList,
    coursePage,
    courseSave,
    courseDelete
} from "@/api/api";
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            categoryList: [],
            dataList: [],
            query: {
                categoryId: ""
            },
            formLabelWidth: "80px",

            addShow: false,
            addForm: {
				title: '',
                categoryId: "",
                image: ""
			},

            uploadDisabled: false,
            dialogImageUrl: '',
            dialogVisible: false,
            headers: {
                Authorization: "Bearer " + localStorage.token
            },
            uploadData: {
                code: "9001",
                index: 0
            },
            imageList: [], //图片列表
        };
    },
    methods: {
        search() {
            this.getCourseList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getCourseList();
        },
        //获取所有分类列表
        getCourseCategoryList() {
            courseCategoryList()
                .then(response => {
                    this.categoryList = response;
                })
        },
        //获取待审核动态分页列表
        getCourseList() {
            coursePage({
                current: this.page,
                size: this.size,
                name: this.query.name,
                type: this.query.type
            })
            .then(response => {
                this.dataList = response.records;
            })
        },
        
        //添加教程事件
		addClick() {
			this.addShow = true
		},
        //新增教程
		addCourse() {
			courseSave({
				title: this.addForm.title,
                categoryId: this.addForm.categoryId,
                image: this.addForm.image
			})
				.then(() => {
					this.getCourseList() //保存成功，重新获取数据
				})
			this.addShow = false
		},

        //删除分类事件
        deleteClick(id) {
            this.$confirm('确定要删除此课程?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteCourse(id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
        },
        deleteCourse(id) {
            let params = new URLSearchParams()
			params.append("idList", [id])
			courseDelete({ params })
				.then(() => {
					window.location.reload() //刷新页面
				})
        },


        //查看图片调用
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
        handleChange(file, fileList) {
            if (fileList.length >= 1) {
                this.uploadDisabled = true;
            } else {
                this.uploadDisabled = false;
            }
        },
        //图片删除
        imageRemove() {
            this.uploadDisabled = false;
            this.addForm.image = '' // 主图地址设置为空
        },
        // 上传图片出错
        uploadError(err, file, fileList) {
            this.$message.error("上传出错");
        },
        //图片上传成功回调
        imageSuccess(response, file, fileList) {
            if (response.code==0) {
                //上传成功
                this.addForm.image = response.data
            } else {
                //上传失败，删除图片
                for (let i = 0; i < fileList.length; i++) {
                    if (file == fileList[i]) {
                        fileList.splice(i, 1)
                    }
                }
            }
        },
        //显示大图
        showImgList(image) {
            let images = [image]
            return images
        },
    },
    mounted() {
        this.getCourseList();
        this.getCourseCategoryList();
    }
};
</script>