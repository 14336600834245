<template>
    <div>
        <el-dialog :title="title" :visible.sync="deleteFormVisible" :close-on-click-modal="false">
			<el-form ref="deleteForm" :model="deleteForm" label-width="80px" :rules="deleteFormRules" style="text-align: left">
				<el-form-item label="原因" prop="type">
					<el-select v-model="deleteReason" placeholder="请选择类型" @change="selectDeleteConfig">
                        <el-option
                            v-for="item in deleteConfigList"
                            :key="item.id"
                            :label="item.reason"
                            :value="item"
                        ></el-option>
                    </el-select>
				</el-form-item>
				<el-form-item label="内容" prop="content">
					<el-input v-model="deleteForm.content" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark" v-if="showRemark">
					<el-input v-model="deleteForm.remark" type="textarea" :rows="4" placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">取 消</el-button>
				<el-button type="primary" :loading="loading" @click="deleteConfirm">确 定</el-button>
			</div>
		</el-dialog>
    </div>
</template>

<script>
import { deleteConfigPage } from "@/api/api";
export default {
    data() {
        return {
            deleteConfigList: [],
            deleteLoading: false,
            deleteFormVisible: false,
            deleteReason: null,
            deleteForm: {
                id: null,
                content: '',
                remark: '',
            },
            deleteFormRules: {
                content: [
                    { required: this.contentRequired, message: '请输入内容' }
                ],
                remark: [
                    { required: this.remarkRequired, message: '请输入备注' }
                ]
            },
        }
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        type: {
            type: Number,
            inquired: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        showRemark: {
            type: Boolean,
            default: false
        },
        contentRequired: {
            type: Boolean,
            default: true
        },
        remarkRequired: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getDeleteConfig() {
            deleteConfigPage({
                current: 1,
                size: 20,
                type: this.type
            }).then(res => {
                console.log(res,'res')
                this.deleteConfigList = res.records
            })
        },
        selectDeleteConfig(item) {
            this.deleteForm.content = item.content
            this.deleteForm.remark = item.remark
        },
        async deleteConfirm() {
            await new Promise((resolve) => {
                this.$refs.deleteForm.validate(valid => {
                    if (valid) {
                        resolve()
                    }
                })
            })

            this.$emit('confirm', {...this.deleteForm})
            this.closeDialog()
            this.$nextTick(() => {
                this.clearForm()
            })
        },
        clearForm() {
            for (let key of Object.keys(this.deleteForm)) {
                this.$set(this.deleteForm, key, '')
            }
            this.$nextTick(() => {
                this.$refs.deleteForm.clearValidate()
            })
        },
        setId(id) {
            this.deleteForm.id = id
        },
        showDialog() {
            this.deleteFormVisible = true
        },
        closeDialog() {
            this.deleteFormVisible = false
            this.deleteReason = null
            this.$set(this.deleteForm, 'id', '')
            console.log(this.deleteReason, this.deleteForm, 'closeDialog')
        }
    },
    created() {
        this.getDeleteConfig()
    }
}
</script>

<style>
</style>