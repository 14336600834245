<template>
    <div>
        <el-container class="layout-container" style="height: 100%;">

            <el-header style="text-align: right; font-size: 12px; height: 60px;">
                <span
                    style="display:block; font-weight: bold; color: black; float: left; font-size: 20px;">伊洛信息科技有限公司</span>
                <div class="toolbar">
                    <el-button type="primary" size="mini" style="margin-right: 10px;" @click="openWebChat">打开web版聊天</el-button>

                    <el-dropdown @command="handleCommand">
                        <i class="el-icon-setting" style="margin-right: 5px"></i>
                        <template slot="dropdown">
                            <el-dropdown-menu>
                                <el-dropdown-item command="info">修改信息</el-dropdown-item>
                                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                    <span>欢迎 {{ user ? user.nickName : "" }}</span>
                </div>
            </el-header>

            <el-container style="height: calc(100vh - 60px);">

                <el-aside width="210px">
                    <el-scrollbar>
                        <el-menu router :default-active="this.$route.path" class="el-menu-vertical-demo" @open="handleOpen"
                            @close="handleClose" unique-opened background-color="#001529" text-color="#fff" text-font="10px"
                            active-text-color="#ffd04b" style="border: 1px solid #001529; text-align: left;">

                            <template v-for="(item, index) in menuList">
                                <el-submenu v-if="item.childList.length > 0" :key="index" :index="item.menuUrl">
                                    <template slot="title">
                                        <i :class="item.menuIcon"></i>
                                        <span>{{ item.menuName }}</span>
                                    </template>
                                    <el-menu-item v-for="(itemChild, childIndex) in item.childList" :key="childIndex"
                                        :index="itemChild.menuUrl">
                                        <i :class="itemChild.menuIcon"></i>
                                        <span slot="title">{{ itemChild.menuName }}</span>
                                    </el-menu-item>
                                </el-submenu>
                                <el-menu-item v-else :index="item.menuUrl">
                                    <i :class="item.menuIcon"></i>
                                    <span slot="title">{{ item.menuName }}</span>
                                </el-menu-item>
                            </template>

                        </el-menu>
                    </el-scrollbar>
                </el-aside>

                <el-main style="background-color: #FFFFFF; padding: 20px; height: 100%">
                    <router-view />
                </el-main>

            </el-container>
        </el-container>

        <el-dialog title="编辑个人信息" :visible.sync="centerDialogVisible" :close-on-click-modal="false" width="30%" center zIndex=1999>
            <el-form ref="updateForm" :model="update" :rules="rules" label-width="80px">
                <el-form-item label="用户名">
                    <el-input v-model="update.username" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="用户密码" prop="password">
                    <el-input v-model="update.password" placeholder="请输入新密码" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="手机号">
                    <el-input v-model="update.phone" placeholder="请输入手机号码" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用户昵称">
                    <el-input v-model="update.nickName" placeholder="请输入昵称" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitUpdate('updateForm')">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
import {
    userMenuTree,
    userLogout,
    userUpdate
} from '@/api/api';
import { mapGetters, mapMutations } from 'vuex'
export default {
    data() {
        return {
            //菜单列表
            menuList: [],
            //用户信息
            user: {},
            //编辑用户信息
            centerDialogVisible: false,
            update: {
                id: '',
                username: '',
                password: '',
                phone: '',
                nickName: ''
            },
            // 表单验证，需要在 el-form-item 元素中增加 prop 属性
            rules: {
                password: [{
                    required: true,
                    message: '密码不可为空',
                    trigger: 'blur'
                }]
            },
        }
    },
    computed: {
        ...mapGetters('nim', ['nim']),
    },
    methods: {
        ...mapMutations('nim', ['clearNim']),
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        handleCommand(command) {
            if (command == "logout") {
                userLogout().then(() => {
                    if (this.nim) {
                        this.nim.logout()
                        console.log(this.nim,'nim')
                        this.clearNim()
                    }

                    //退出清除缓存
                    localStorage.removeItem("user");
                    localStorage.removeItem("token");
                    this.$router.push("/login");
                }).catch((error) => {
                    console.log(error)
                })
            } else {
                //点击修改信息按钮
                this.centerDialogVisible = true
                this.update.id = this.user.id
                this.update.username = this.user.username
                this.update.phone = this.user.phone
                this.update.nickName = this.user.nickName
            }
        },
        //获取用户菜单列表
        getMenuList() {
            userMenuTree(null)
                .then((response) => {
                    this.menuList = response
                })
        },
        //提交用户编辑事件
        submitUpdate(formName) {
            // 为表单绑定验证功能
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.centerDialogVisible = false
                    userUpdate({
                        id: this.update.id,
                        username: this.update.username,
                        password: this.update.password,
                        mobile: this.update.mobile,
                        nickName: this.update.nickName
                    }).then((response) => {
                        this.user = response
                        localStorage.setItem("user", JSON.stringify(response))
                    }).catch((error) => {
                        console.log(error)
                    })
                }
            })
        },

        openWebChat() {
            const token = localStorage.getItem('token')
            const url = process.env.NODE_ENV === 'development' ? 'http://192.168.1.32:84/' : 'https://chat.oiki.cc/'
            if (token) {
                window.open(url + '?aa=' + token)
            } else {
                this.$message.error('缺少关键参数')
            }
        }
    },
    created() {
        this.user = JSON.parse(localStorage.getItem("user"))
        this.getMenuList()
    }
}

</script>
  
<style scoped>
body,
html {
    height: 100%;
}

/* 
.layout-container .el-header {
    position: relative;
    background-color: var(--el-color-primary-light-7);
    color: var(--el-text-color-primary);
} */

.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
}

.el-aside {
    color: #333;
}

.layout-container .el-aside {
    color: var(--el-text-color-primary);
    background: var(--el-color-primary-light-8);
}

.layout-container .el-menu {
    border-right: none;
}

.layout-container .el-main {
    padding: 0;
}

.layout-container .toolbar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    right: 20px;
}

.el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
}

.el-dropdown-link {
    cursor: pointer;
    color: white;
}

.el-icon-arrow-down {
    font-size: 12px;
}
</style>
  