<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="回复类型">
                    <el-select v-model="query.ilk" placeholder="请选择回复类型" style="width: 100px;">
                        <el-option label="全部" value=""></el-option>
                        <el-option :label="label" :value="value" v-for="(label, value) in ilkAry" :key="value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="回复者昵称">
                    <el-input v-model="query.nickName" placeholder="请输入回复者昵称" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="回复者用户编号">
                    <el-input v-model="query.userNumber" placeholder="请输入回复者用户编号" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="动态ID">
                    <el-input v-model="query.articleId" placeholder="请输入动态ID" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="回复内容">
                    <el-input v-model="query.content" placeholder="请输入回复内容" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="content" label="回复内容" min-width="150" align="center">
                <template slot-scope="scope">
                    <audio controls :key="scope.row.id" v-if="scope.row.ilk == 1">
                        <source :src="fileDomain + scope.row.audio" :type="scope.row.audioType">
                    </audio>
                    <div class="content" v-else>
                        <div>{{ scope.row.content }}</div>
                        <el-image style="height: 60px; width: 60px; padding: 2px;" :src="fileDomain + scope.row.emote"
                        :preview-src-list="[fileDomain + scope.row.emote]" v-if="scope.row.emote" />
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="articleId" label="动态ID" min-width="40" align="center">
                <template slot-scope="scope">
                    <a :href="'/article/' + scope.row.articleId" target="_blank">{{ scope.row.articleId }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="nickName" label="回复者昵称" min-width="60" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="回复者用户编号" min-width="50" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="回复时间" min-width="80" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>

        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <!-- <delete-dialog ref="deleteDialog" title="删除回复" :type="1" @confirm="deleteConfirm"></delete-dialog> -->
    </div>
</template>

<script>
import {
    replyPage,
    replyDelete
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
// import deleteDialog from '@/components/deleteDialog'
export default {
    components: {
        // deleteDialog
    },
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                ilk: "",
                nickName: "",
                userNumber: "",
                status: "",
                articleId: "",
                content: ""
            },
            ilkAry: ['文本', '语音', '表情'],
            fileDomain: 'https://file.oiki.cc/'
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getReplyList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getReplyList();
        },
        //获取待审核动态分页列表
        getReplyList() {
            replyPage({
                current: this.page,
                size: this.size,
                ilk: this.query.ilk,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                status: this.query.status,
                articleId: this.query.articleId,
                content: this.query.content,
            }).then(response => {
                this.total = response.total;
                this.dataList = response.records.map(item => {
                    if (item.ilk == 1) {
                        const audioAry = item.audio.split('.')
                        const audioSuffix = audioAry[audioAry.length - 1]
                        item.audioType = audioSuffix == 'aac' ? 'audio/aac' : 'audio/mpeg'
                    }

                    return item
                });
            })
        },
        //删除事件
        deleteClick(id) {
            this.$confirm('确认要删除该回复吗?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
                closeOnClickModal: false
			}).then(({ value }) => {
				this.deleteReply(id)
			})

            // this.$refs.deleteDialog.setId(id)
            // this.$refs.deleteDialog.showDialog()
        },
        deleteConfirm(data) {
            this.deleteReply(data.id, data.content)
        },
        deleteReply(id, remark) {
            let params = new URLSearchParams()
			params.append("idList", [id])
			// params.append("remark", remark)
            this.deleteLoading = true
			replyDelete({ params })
				.then(() => {
					this.getReplyList();

                    this.deleteLoading = false
                    this.deleteFormVisible = false
                    this.$nextTick(() => {
                        this.deleteForm.reason = ''
                        this.$refs.deleteForm.resetFields()
                    })
				})
        },
        
    },
    mounted() {
        this.getReplyList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>