<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="用户昵称">
					<el-input v-model="query.nickName" placeholder="用户昵称" style="width: 155px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="用户编号">
					<el-input v-model="query.userNumber" placeholder="用户编号" style="width: 110px" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="性别">
					<el-select v-model="query.gender" placeholder="性别" style="width: 80px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="男" value="1"></el-option>
						<el-option label="女" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否置顶">
					<el-select v-model="query.isTop" placeholder="是否置顶" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="是" value="1"></el-option>
						<el-option label="否" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="置顶时长">
					<el-select v-model="query.topDays" placeholder="置顶时长" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.label" :value="item.value" v-for="item in topDaysAry" :key="item.value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="征友信息状态">
					<el-select v-model="query.status" placeholder="征友信息状态" style="width: 120px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="label" :value="value" v-for="(label,value) in statusAry" :key="value"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="是否实名">
					<el-select v-model="query.isRealName" placeholder="是否实名" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="是" value="1"></el-option>
						<el-option label="否" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="发布时间">
					<el-date-picker v-model="publishTime" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
            <el-table-column prop="isAuto" label="征友信息" width="330" align="center">
                <template slot-scope="scope">
                    <div class="img-box">
                        <template v-for="(img, index) in scope.row.images">
                            <el-popover
                                placement="right"
                                trigger="hover"
                                popper-class="pointer-events-none"
                                :open-delay="0"
                                :close-delay="0"
                                :key="index"
                            >
                                <el-image style="height: 60px; width: 60px; padding: 2px;" :src="img" :preview-src-list="scope.row.images" slot="reference" ></el-image>
                                <el-image style="height: auto; max-height: 90vh; max-width: 400px;" :src="img" ></el-image>
                            </el-popover>
                        </template>
                        <div class="more">
							<a :href="'/friendsPublishDetail/' + scope.row.id" target="_blank" >详情</a>
						</div>
                    </div>
                </template>
            </el-table-column>
			<el-table-column prop="nickName" label="用户昵称" min-width="100" align="center"></el-table-column>
			<el-table-column prop="userNumber" label="用户ID" min-width="80" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="gender" label="性别" min-width="50" align="center">
                <template slot-scope="scope">
                    {{ scope.row.gender ? scope.row.gender == 1 ? '男' : '女' : '' }}
                </template>
            </el-table-column>
			<el-table-column prop="isTop" label="是否置顶" min-width="50" align="center">
                <template slot-scope="scope">
                    {{ scope.row.isTop == 1 ? '是' : '否' }}
                </template>
            </el-table-column>
			<el-table-column prop="isRealName" label="是否实名" min-width="50" align="center">
                <template slot-scope="scope">
                    {{ scope.row.isRealName == 1 ? '是' : '否' }}
                </template>
            </el-table-column>
            <el-table-column prop="number" label="座位数" min-width="50" align="center"></el-table-column>
            <el-table-column prop="status" label="征友信息状态" min-width="60" align="center">
                <template slot-scope="scope">
					<span :class="[statusColor[scope.row.status]]">{{ statusAry[scope.row.status] }}</span>
                </template>
            </el-table-column>
			<el-table-column prop="totalCoinNumber" label="支付K币" min-width="60" align="center"></el-table-column>
			<el-table-column prop="createTime" label="发布时间" min-width="100" align="center"></el-table-column>
			<el-table-column fixed="right" label="操作" min-width="100" align="center">
				<template slot-scope="scope">
					<el-button size="mini" type="warning" @click="rejectClick(scope.row.id)" v-if="scope.row.status == 0">驳回</el-button>
					<el-button size="mini" type="danger" @click="deleteClick(scope.row.id)" v-if="scope.row.status == 0">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=false layout="slot, total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
                <span class="page-text">发布者：{{statisticsData.totalUserNumber || 0}}人</span>
				<span class="page-text">征友信息：{{statisticsData.number}}条</span>
				<span class="page-text">K币数量：{{statisticsData.totalCoinNumber}}</span>
			</el-pagination>
		</div>

		<delete-dialog ref="deleteDialog" :title="deleteType == 4 ? '驳回' : '删除'" :type="deleteType" @confirm="deleteConfirm"></delete-dialog>
    </div>
</template>

<script>
import {
    seekFriendPage,
	seekFriendStatistics,
	seekFriendReject,
	seekFriendDelete
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
import deleteDialog from '@/components/deleteDialog'
export default {
	components: {
        deleteDialog
    },
    data() {
        return {
			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
			statisticsData: {},
            query: {
				nickName: '',
				userNumber: '',
				gender: '',
				isTop: '',
				isRealName: '',
				topDays: '',
				status: ''
            },
			topDaysAry: [
				{ label: '7天', value: 7 },
				{ label: '15天', value: 15 },
				{ label: '30天', value: 30 },
			],
			statusAry: ['征友中','已驳回','已删除','已过期','已完成'],
            statusColor: ['color-warning', 'color-danger', 'color-danger', 'color-danger','color-success',''],
			fileDomain: 'https://file.oiki.cc/',

			deleteType: 4,

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			publishTime: []
        }
    },
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getSeekFriendList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.publishTime = []

			this.page = 1
			this.getSeekFriendList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getSeekFriendList()
		},
		changeClick(page) {
			this.page = page
			this.getSeekFriendList()
		},
		//获取新征友列表接口
		getSeekFriendList() {
			
			const params = {
				"nickName": this.query.nickName,
				"userNumber": this.query.userNumber,
				"gender": this.query.gender,
				"isTop": this.query.isTop,
				"isRealName": this.query.isRealName,
				"topDays": this.query.topDays,
				"status": this.query.status,
				"startTime": this.publishTime != null ? this.publishTime[0] : null,
				"endTime": this.publishTime != null ? this.publishTime[1] : null
			}

			seekFriendPage({
				"current": this.page,
				"size": this.size,
				...params
			}).then((response) => {
				this.total = response.total
				this.tableData = response.records.map(item => {
					if (item.image) {
						const imgAry = item.image.split(',')
						const images = imgAry.map(img => this.fileDomain + img)
						item.images = images
					}

					return item
				})
			})

			seekFriendStatistics(params).then(response => {
				this.statisticsData = response
			})
		},
        rejectClick(id) {
			this.deleteType = 4
            this.$refs.deleteDialog.setId(id)
            this.$refs.deleteDialog.showDialog()
        },
        deleteClick(id) {
			this.deleteType = 5
            this.$refs.deleteDialog.setId(id)
            this.$refs.deleteDialog.showDialog()
        },
        deleteConfirm(data) {
			const fn = this.deleteType == 4 ? seekFriendReject : seekFriendDelete
			const formData = { id: data.id, remark: data.content }
            this.deleteLoading = true
			fn(formData).then(() => {
				this.getSeekFriendList();

				this.deleteLoading = false
				this.deleteFormVisible = false
				this.$nextTick(() => {
					this.deleteForm.reason = ''
					this.$refs.deleteForm.resetFields()
				})
			})
        },
	},
	created() {
		//获取新征友列表
		this.getSeekFriendList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
.img-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.img-box .more {
    color: #409EFF;
    margin-left: 10px;
    user-select: none;
    cursor: pointer;
}


</style>