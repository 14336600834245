<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
			<el-form :inline="true" :model="query" class="demo-form-inline">
				<el-form-item label="类型">
					<el-select v-model="query.type" placeholder="类型" style="width: 110px;">
						<el-option label="全部" value=""></el-option>
                        <el-option
                            v-for="(label, value) in typeAry"
                            :key="value"
                            :label="label"
                            :value="value"
                        ></el-option>
                    </el-select>
				</el-form-item>
				<!-- <el-form-item label="原因">
					<el-input v-model="query.reason" placeholder="原因" style="width: 160px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="内容">
					<el-input v-model="query.content" placeholder="内容" style="width: 160px" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="备注">
					<el-input v-model="query.remark" placeholder="备注" style="width: 160px" @keydown.enter.native="search"></el-input>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="success" @click="showForm()">添加</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="type" label="类型" min-width="60" align="center">
                <template slot-scope="scope">
                    {{ typeAry[scope.row.type] }}
                </template>
            </el-table-column>
			<el-table-column prop="reason" label="原因" min-width="60" align="center"></el-table-column>
			<el-table-column prop="content" label="内容" min-width="60" align="center"></el-table-column>
			<el-table-column prop="remark" label="备注" min-width="60" align="center"></el-table-column>
			
			<el-table-column prop="createTime" label="创建时间" min-width="100" align="center"></el-table-column>

			<el-table-column fixed="right" label="操作" min-width="60" align="center">
				<template slot-scope="scope">
					<el-button type="primary" size="mini" icon="el-icon-edit" @click="showForm(scope.row)">修改</el-button>
					<el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteItem(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

        <el-dialog :title="(form.id ? '编辑' : '添加') + '配置'" :visible.sync="formVisible" :close-on-click-modal="false">
			<el-form ref="form" :model="form" label-width="80px" :rules="formRules" style="text-align: left">
				<el-form-item label="类型" prop="type">
					<el-select v-model="form.type" placeholder="请选择类型">
                        <el-option
                            v-for="(label, value) in typeAry"
                            :key="value"
                            :label="label"
                            :value="value"
                        ></el-option>
                    </el-select>
				</el-form-item>
				<el-form-item label="原因" prop="reason">
					<el-input v-model="form.reason" placeholder="请输入原因"></el-input>
				</el-form-item>
				<el-form-item label="内容" prop="content">
					<el-input v-model="form.content" type="textarea" :rows="4" placeholder="请输入内容"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model="form.remark" type="textarea" :rows="4" placeholder="请输入备注"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" :loading="submitLoading" @click="submitForm">确 定</el-button>
			</div>
		</el-dialog>
    </div>
</template>

<script>
import setTableHeight from '@/utils/setTableHeight'
import { deleteConfigPage, deleteConfigPost, deleteConfigUpdate, deleteConfigDelete } from '@/api/api'
export default {
    data() {
        return {
            page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
			query: {
				type: '',
				reason: '',
				content: '',
				remark: ''
			},

            typeAry: ['用户封禁','动态删除','动态审核','收益审核'],

			fileDomain: 'https://file.oiki.cc/',

			headers: {
                Authorization: "Bearer " + localStorage.token
            },

			formVisible: false,
			form: {},
			formRules: {
				type: [
					{ required: true, message: '请选择类型' }
				],
				reason: [
					{ required: true, message: '请输入原因' }
				],
				content: [
					{ required: true, message: '请输入内容' }
				],
			},
			submitLoading: false
        }
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			this.page = 1;
			this.getList();
		},
        handleSizeChange(size) {
			this.size = size
			this.getList()
		},
		changeClick(page) {
			this.page = page
			this.getList()
		},
		getList() {
			
			const params = {
				current: this.page,
				size: this.size,
				type: this.query.type,
				reason: this.query.reason,
				content: this.query.content,
				remark: this.query.remark,
			}

            deleteConfigPage(params).then(response => {
				this.total = response.total
				this.tableData = response.records
			})
		},
        showForm(formInfo) {
			const fields = ['id','type','reason','content','remark']

			for(const field of fields) {
				const value = formInfo ? formInfo[field] : ''
				this.$set(this.form, field, value)
			}

			this.formVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
		},
		async submitForm() {
			await new Promise((resolve) => {
				this.$refs.form.validate(valid => {
					if (valid) {
						resolve()
					}
				})
			})

			let fn;
			if (this.form.id) {
				fn = deleteConfigUpdate
			} else {
				fn = deleteConfigPost
			}

			this.submitLoading = true
			fn(this.form).then(res => {
				this.getList()
				this.formVisible = false
				this.submitLoading = false
			}).catch(() => {
				this.submitLoading = false
			})
		},
		deleteItem(id) {
			this.$confirm('确定要删除此配置?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = new URLSearchParams()
				params.append("idList", [id])
				deleteConfigDelete({ params }).then(() => {
					this.getList()
				})
			})
		}
    },
    created() {
		//获取表情列表
		this.getList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style>
</style>