<template>
    <div class="chat-avatar" :style="{width: avatarSize, height: avatarSize, lineHeight: avatarSize}" @click="avatarTap">
        <img :src="avatarSrc" alt="">
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    props: {
        src: {
            type: String,
            // required: true
        },
        size: {
            type: [String, Number],
            default: 36
        }
    },
    computed: {
        avatarSize() {
            if (typeof this.size === 'string') {
                return parseInt(this.size) === NaN ? 36 + 'px' : this.size
            }
            return this.size + 'px'
        },
        avatarSrc() {
            if (this.src) {
                const notSplicedAry = ['http://', 'https://', 'ttfile://', 'wxfile://', '/static', './static', 'data:image', 'blob:']
			    const spliced = notSplicedAry.every(prefix => this.src.slice(0, prefix.length) != prefix)

                if (spliced) {
                    return 'https://file.oiki.cc/' + this.src
                }

                return this.src
            }
            return ''
        }
    },
    methods: {
        avatarTap() {
            this.$emit('avatarTap')
        }
    }
}
</script>

<style scoped>
.chat-avatar {
    padding: 0;
    border-radius: 50%;
    margin: 0;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
}

.chat-avatar > img {
    width: 100%;
    height: 100%;
    display: block;
}
</style>