import Vue from 'vue'
import { Message } from 'element-ui'
import { sysEmotePage } from '@/api/api'
import { getDefaultAvatar } from '@/utils'

const state = {
    nim: undefined,
    hooks: {
        connect: {},
        msg: {},
        changeSession: {}
    },
    currentUser: undefined,

    receiveMsgBtnVisible: false,

    emojiDatas: [],

    /**
     * key: sessionId
     * value.msgList: 已经拉取到内存中的会话的消息列表，最新的消息在队列头部
     * value.fetching: 是否上次调用getHistoryMsgs的结果还未返回
     * value.complete: 是否已经加载完了
     */
    sessionMsgs: {},

    /**
     * key: sessionId
     * value: 会话对象，包含会话的基本信息。包括 lastMsg 显示会话的最后一条消息
     */
    sessionMap: {},

    /**
     * 用户信息
     */
    userInfos: {},

    /**
     * 我的个人资料
     */
    myUserInfo: {},
}

const getters = {
    nim: (state) => state.nim,
    currentUser: (state) => state.currentUser,
    sessionMsgs: (state) => state.sessionMsgs,
    sessionMap: (state) => state.sessionMap,
    myUserInfo: (state) => state.myUserInfo,
    userInfos: (state) => state.userInfos,
    receiveMsgBtnVisible: (state) => state.receiveMsgBtnVisible,
    emojisList: (state) => state.emojiDatas,
    sessionUser: (state) => state.currentUser ? state.sessionMap[`p2p-${state.currentUser}`] : undefined,

    // 排序会话列表
    orderedSessions: (state) => {
        const list = Object.values(state.sessionMap)
        list.sort((a,b) => {
            // 按最新消息排序
            const aa = a.lastMsg?.time || a.updateTime
            const bb = b.lastMsg?.time || b.updateTime
            // console.log(a,aa,b,bb,'order')
            return bb - aa
        })
        // console.log(list,'orderedSessions')
        return list
    }
}

const mutations = {
    setNim(state, nim) {
        state.nim = nim
    },
    addHook(state, {hookName, funcName, func}) {
        if (funcName && typeof func === 'function') {
            if (!state.hooks.hasOwnProperty(hookName)) state.hooks[hookName] = {}
    
            state.hooks[hookName][funcName] = func
        }
    },

    removeHook(state, hookName, funcName) {
        delete state.hooks[hookName][funcName]
    },

    setCurrentUser(state, account) {
        state.currentUser = account
    },

    createP2pSession(state, session) {
        const { lastMsg, msgReceiptTime } = session
        // console.log(session,'session')

        
        const account = session.id ? session.id.replace('p2p-', '') : (session.to || session.account)
        const sessionId = session.id || `p2p-${account}`
        console.log(account, sessionId, session, 'createP2pSession')
        if (!state.sessionMap[sessionId]) {
            const unread = localStorage.getItem('nim-unread-' + account)
            const data = {
                id: sessionId,
                scene: 'p2p',
                to: account,
                unread: unread ? parseInt(unread) : 0,
                updateTime: Date.now(),
                msgReceiptTime,
                lastMsg: {}
            }
            // console.log(lastMsg, lastMsg.status, session,'lastMsg')
            if (lastMsg && lastMsg.status != 'fail') {
                data.lastMsg = lastMsg
            }

            console.log(sessionId, data, 'createP2pSession success')
            Vue.set(state.sessionMap, sessionId, data)
        }
    },

    setMyUserInfo(state, data) {
        Vue.set(state, 'myUserInfo', data)
    },

    interUserInfo(state, data) {
        Vue.set(state.userInfos, data.account, data)
    },

    initSessionMsgs(state, sessionId) {
        const data = {
            msgList: [],
            fetching: false,
            complete: false
        }
        Vue.set(state.sessionMsgs, sessionId, data)
    },

    interMessageItem(state, data) {
        const { account, msgItem, unshift } = data
        const fn = unshift ? 'unshift' : 'push'
        const sessionId = `p2p-${account}`
        // console.log(sessionId, state.sessionMsgs[sessionId],'state.sessionMsgs[sessionId]')
        const msgList = state.sessionMsgs[sessionId].msgList
        msgList[fn](msgItem)
        
        if (!unshift) {
            Vue.set(state.sessionMap[sessionId], 'lastMsg', msgItem)
            Vue.set(state.sessionMap[sessionId], 'updateTime', Date.now())
        }
    },

    updateMessageItem(state, data) {
        const { account, msgItem } = data
        const sessionId = `p2p-${account}`
        const { msgList } = state.sessionMsgs[sessionId]
        
        for (let i in msgList) {
            const item = msgList[i]
            if (item.idClient == msgItem.idClient) {
                msgList.splice(i, 1, msgItem)
                break;
            }
        }

        const lastMsg = state.sessionMap[sessionId].lastMsg
        if (lastMsg.idClient == msgItem.idClient) {
            Vue.set(state.sessionMap[sessionId], 'lastMsg', msgItem)
        }
    },

    removeMessageItem(state, data) {
        const { sessionId, idClient } = data
        const index = state.sessionMsgs[sessionId].msgList.findIndex(item => item.idClient == idClient)
        if (index >= 0) state.sessionMsgs[sessionId].msgList.splice(index, 1)
    },

    addUnreadNumber(state, account) {
        const sessionId = `p2p-${account}`
        const unread = state.sessionMap[sessionId].unread + 1
        localStorage.setItem('nim-unread-' + account, unread)
        Vue.set(state.sessionMap[sessionId], 'unread', unread)
    },
    clearUnreadNumber(state, account) {
        const sessionId = `p2p-${account}`
        localStorage.removeItem('nim-unread-' + account)
        Vue.set(state.sessionMap[sessionId], 'unread', 0)
    },


    openSessionMsgFetching(state, sessionId) {
        Vue.set(state.sessionMsgs[sessionId], 'fetching', true)
    },

    closeSessionMsgFetching(state, sessionId) {
        Vue.set(state.sessionMsgs[sessionId], 'fetching', false)
    },

    setSessionMsgComplete(state, sessionId) {
        Vue.set(state.sessionMsgs[sessionId], 'complete', true)
    },

    setReceiveMsgBtnVisible(state, data) {
        Vue.set(state, 'receiveMsgBtnVisible', data)
    },

    updateMsgReceiptTime(state, session) {
        // console.log(session,'session')
        const { id: sessionId, msgReceiptTime } = session
        Vue.set(state.sessionMap[sessionId], 'msgReceiptTime', msgReceiptTime)
    },

    setEmojiDatas(state, data) {
        state.emojiDatas = data
    },

    clearNim(state) {
        state.hooks = {}
        state.currentUser = undefined
        state.sessionMsgs = {}
        state.sessionMap = {}
        state.userInfos = {}
        state.myUserInfo = {}
        state.nim = undefined
    }
}

const actions = {
    runHook({state}, data) {
        const hookName = typeof data === 'string' ? data : data.hookName
        const params = data.params || undefined

        if (state.hooks.hasOwnProperty(hookName)) {
            const functions = Object.values(state.hooks[hookName])
            for (let func of functions) {
                if (typeof func === 'function') func(params)
            }
        }
    },

    getUserInfo({state, getters, commit}, account) {
        const { nim } = getters

        return new Promise((resolve) => {
            nim.getUser({
                account: account,
                sync: true,
                done: (err, userInfo) => {
                    resolve(userInfo)
                }
            })
        })
    },

    setCurrentUser({state, getters, commit, dispatch}, account) {
        const oldaccount = state.currentUser
        // console.log(oldaccount,'oldaccount')
        commit('setCurrentUser', account)
        commit('clearUnreadNumber', account)
        dispatch('runHook', { hookName: 'changeSession', params: { oldAccount: oldaccount, newAccount: account } })
    },

    getEmojiDatas({commit}) {
        sysEmotePage({
            current: 1,
            size: 1000
        }).then(res => {
            commit('setEmojiDatas', res.records)
        })
    },

    async getSessions({getters, commit, dispatch}) {
        const { nim, myUserInfo } = getters

        const limit = 100
        let sessionsList = []
        
        try {
            await new Promise((resolve, reject) => {
                nim.getServerSessions({
                    limit,
                    done: (err, data) => {
                        if (err) {
                            // console.log(err,'err getServerSessions')
                            reject(err)
                        } else {
                            sessionsList = data.sessionList
                            resolve(data)
                        }
                    }
                })
            })
        } catch {
            await new Promise((resolve, reject) => {
                nim.getLocalSessions({
                    limit,
                    reverse: false,
                    done: (err, data) => {
                        if (err) {
                            reject(err)
                        } else {
                            sessionsList = data.sessions
                            // console.log(data,'data getLocalSessions')
                            resolve(data)
                        }
                    }
                })
            })
        }

        // console.log(sessionsList,'sessionsList')
        for (let item of sessionsList) {
            let account;
            if (item.lastMsg) {
                account = item.lastMsg.from == myUserInfo.account ? item.lastMsg.to : item.lastMsg.from

                // 通过云端获取到会话列表没有已读消息时间，所以需要通过 nim.isMsgRemoteRead() 进行判断，并更新会话列表中的已读消息时间
                if (!item.msgReceiptTime) {
                    const time = nim.isMsgRemoteRead(item.lastMsg) ? item.lastMsg.time : 0
                    if (time) item.msgReceiptTime = time
                }
            } else {
                account = item.id.replace('p2p-', '')
                item.to = account
            }
            // console.log(account, item,'account')
            commit('createP2pSession', item)
            dispatch('getUserInfo', account).then(userinfo => {
                if (!userinfo.avatar) {
                    userinfo.avatar = getDefaultAvatar(userinfo.gender)
                }
                commit('interUserInfo', userinfo)
            })
        }
    },

    loadMoreMsgOfSession({state, getters, commit, dispatch}, account) {
        const { nim, sessionMsgs, sessionMap } = getters
        const sessionId = `p2p-${account}`

        let lastMsg

        const { msgReceiptTime } = sessionMap[sessionId]

        if (!sessionMsgs[sessionId]) {
            commit('initSessionMsgs', sessionId)
        } else {
            const { msgList, fetching, complete } = sessionMsgs[sessionId]
            if (fetching || complete) return;

            lastMsg = msgList[0]
        }


        const limit = 10

        const params = {
            scene: 'p2p',
            to: account,
            asc: true,
            limit,
        }

        if (lastMsg) {
            params.lastMsgId = lastMsg.idServer
            params.endTime = lastMsg.time
        }

        commit('openSessionMsgFetching', sessionId)
        // console.log(params,'getHistoryMsgs params')
        return new Promise((resolve, reject) => {
            nim.getHistoryMsgs({
                ...params,
                done: (err, data) => {
                    console.log(err,data,'get history')
                    setTimeout(() => {
                        commit('closeSessionMsgFetching', sessionId)
                    }, 200)
                    
                    if (err) {
                        dispatch('runHook', {hookName: 'getHistoryMsgFail', params: err})
                        reject()
                    } else {
                        if (data && data.msgs && data.msgs.length < limit) {
                            commit('setSessionMsgComplete', sessionId)
                        }

                        if (data.msgs && data.msgs.length) {
                            data.msgs.reverse()
                        }
                        
                        let time = 0
                        for (let index in data.msgs) {
                            const item = data.msgs[index]
                            commit('interMessageItem', { account, msgItem: item, unshift: true })

                            if (!msgReceiptTime && nim.isMsgRemoteRead(item)) {
                                time = item.time > time ? item.time : time
                            }
                        }

                        if (!msgReceiptTime && time) {
                            commit('updateMsgReceiptTime', { id: sessionId, msgReceiptTime: time })
                        }
                        
                        resolve(data.msgs)
                    }
                }
            })
        })
    },

    addSession({getters, commit, dispatch}, userinfo) {
        // console.log(userinfo,'user')
        if (!userinfo.avatar) {
            userinfo.avatar = getDefaultAvatar(userinfo.gender)
        }
        commit('interUserInfo', userinfo)
        commit('createP2pSession', {to: userinfo.account})
        dispatch('loadMoreMsgOfSession', userinfo.account)
    },

    // 接收离线消息
    receiveOfflineMsgs({getters, commit}, datas) {
        const { sessionId, msgs, to: account } = datas
        const { sessionMsgs, sessionMap } = getters
        if (!sessionMsgs[sessionId]) {
            commit('initSessionMsgs', sessionId)
        }
        
        if (!sessionMap[sessionId]) {
            const session = {
                to: account,
                lastMsg: msgs[msgs.length - 1]
            }
            commit('createP2pSession', session)
        }

        for (let index in msgs) {
            const item = msgs[index]
            commit('interMessageItem', { account, msgItem: item })
            commit('addUnreadNumber', account)
        }
    },

    // 接收会话消息
    receiveMsg({getters, commit, dispatch}, msg) {
        const { currentUser, sessionMsgs, sessionMap } = getters
        // const account = msg.from
        // const sessionId = `p2p-${account}`
        const sessionId = msg.sessionId
        const account = sessionId.replace('p2p-', '')

        // console.log(account, msg)
        console.log('receiveMsg !sessionMap[sessionId]', !sessionMap[sessionId], sessionMap[sessionId])

        if (!sessionMap[sessionId]) {
            const session = {
                to: account,
                lastMsg: msg
            }
            console.log('receiveMsg createP2pSession', session)
            commit('createP2pSession', session)
        }

        if (!sessionMsgs[sessionId]) {
            commit('initSessionMsgs', sessionId)
        }
        
        commit('interMessageItem', { account, msgItem: msg })
        
        if (currentUser !== account && msg.from === account) {
            console.log('receiveMsg addUnreadNumber', account)
            commit('addUnreadNumber', account)
        }
    },

    // 接收撤回消息
    receiveRecallMsg({ getters, commit }, data) {
        if (data.type !== 'deleteMsg') return;

        const { sessionId, idClient } = data.msg
        const account = sessionId.replace('p2p-', '')
        
        commit('updateMessageItem', { account, msgItem: { ...data.msg, type: 'system', status: 'recall' } })
    },

    deleteMsg({ getters, commit }, msg) {
        // console.log(msg,'msg')
        const { nim } = getters

        return new Promise((resolve, reject) => {
            nim.deleteMsgSelf({
                msg,
                done: (err) => {
                    
                    if (err) {
                        reject(err)
                    } else {
                        commit('removeMessageItem', msg)
                        resolve()
                    }
                }
            })
        })
    },

    recallMsg({ getters, commit }, msg) {
        // console.log(msg,'recallMsg msg')
        if (msg.time < Date.now() - 2 * 60 * 1000) {
            Message.error('只能撤回两分钟以内的消息！')
            return;
        }

        const { nim } = getters

        return new Promise((resolve, reject) => {
            nim.recallMsg({
                msg,
                done: (err, data) => {
                    if (err) {
                        reject(err)
                    } else {

                        const account = msg.to
                        commit('updateMessageItem', { account, msgItem: { ...msg, type: 'system', status: 'recall' } })

                        resolve()
                    }
                    // console.log(err, 'recall done')
                }
            })
        })
    },

    sendText({dispatch}, content) {
        dispatch('sendMsg', { content })
    },

    previewFile({getters, dispatch}, file) {
        // const { nim } = getters

        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = e => resolve(e.target?.result)
            reader.onerror = e => reject(e)
            reader.readAsDataURL(file)
            /* nim.previewFile({
                blob: fileBlob,
                type,
                done: (err, data) => {
                    if (err) {
                        reject(err)
                    } else {
                        resolve(data)
                    }
                }
            }) */
        })
    },

    sendImage({dispatch}, fileInput) {
        return dispatch('sendMsg', { type: 'image', fileInput })
    },

    sendMsg({getters, commit, dispatch}, { type, content, fileInput }) {
        
        if (((!type || type == 'text') && !content) || (['image','video'].includes(type) && !fileInput)) return;

        const { nim, currentUser } = getters
        
        const sendData = {
            scene: 'p2p',
            to: currentUser,
        }

        let sendFn
        switch(type) {
            case 'image':
            case 'video':
                sendFn = 'sendFile'
                sendData.type = type
                sendData.fileInput = fileInput
            break;
            case 'text':
            default:
                sendFn = 'sendText'
                sendData.text = content
            break;
        }
        // console.log(sendData,'sendData')
        // return;

        return new Promise((resolve, reject) => {
            let fileTmpUrl = ''

            const msg = nim[sendFn]({
                ...sendData,
                done: (err, msg) => {
                    // console.log(err,msg,'sendMsg')
                    // console.log(msg.status,'msg.status')
                    commit('updateMessageItem', { account: currentUser, msgItem: msg })
                    if (err) {
                        dispatch('runHook', {hookName: 'sendMsgFail', params: msg})
                        Message.error(err.message)
                        // console.log(msg,'send error')
                        // reject(err)
                    } else {
                        dispatch('runHook', {hookName: 'sendMsgSuccess', params: msg})
                        // console.log(msg,'send success')
                        // resolve(msg)
                    }
                    URL.revokeObjectURL(fileTmpUrl)
                }
            })
            // console.log(msg,'send msg')

            if (sendFn === 'sendFile') {
                const inputDom = document.getElementById(fileInput)
                if (inputDom && inputDom.files[0]) {
                    fileTmpUrl = URL.createObjectURL(inputDom.files[0])
                    msg.file = { url: fileTmpUrl }
                }
            }
            commit('interMessageItem', { account: currentUser, msgItem: msg })
            dispatch('runHook', {hookName: 'sendMsg', params: msg})
            resolve(msg)
        })
    },

    sendMsgReceipt({ getters }, msg) {
        const { nim } = getters

        return new Promise((resolve, reject) => {
            if (!msg) {
                reject()
                return;
            }

            nim.sendMsgReceipt({
                msg,
                done: (err, data) => {
                    // console.log(data,err, msg, 'sendMsgReceipt')
                    if (err) {
                        reject(err)
                    } else {
                        resolve(data)
                    }
                }
            })
        })
    },
}

export default { namespaced: true, state, getters, mutations, actions }