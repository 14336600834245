<template>
    <div :id="item.id" class="contact-item" :class="[sessionUser && sessionUser.to == userInfo.account ? 'item-select' : '']" @click="itemTap" v-if="userInfo">
        <div class="avatar">
            <el-badge :value="unread" :max="99" :hidden="!unread">
                <avatar :src="userInfo.avatar" />
            </el-badge>
        </div>
        <div class="content">
            <div class="name">{{userInfo.nick}}</div>
            <div class="msg">
                <div class="status" v-if="lastMsg.from == myUserInfo.account">
                    <template>
                        <div class="icon" v-if="lastMsg.status == 'sending'"><i class="el-icon-loading"></i></div>
                        <div class="icon fail" v-if="lastMsg.status == 'fail'"><i class="el-icon-warning"></i></div>
                        <div class="icon" v-if="isReaded"><i class="el-icon-circle-check"></i></div>
                        <div class="unread" v-if="lastMsg.status == 'success'"></div>
                    </template>
                </div>
                <div class="text">{{msgText}}</div>
            </div>
        </div>
        <div class="state">
            <div class="time">{{lastTime}}</div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import avatar from '../common/avatar'
export default {
    components: {
        avatar
    },
    data() {
        return {

        }
    },
    props: {
        item: {
            type: Object,
            default: {}
        }
    },
    computed: {
        ...mapGetters('nim', ['sessionMap', 'sessionMsgs', 'userInfos', 'sessionUser', 'myUserInfo']),

        userInfo() {
            const account = this.item.to
            return this.userInfos[account]
        },

        session() {            
            const sessionId = `p2p-${this.item.to}`
            return this.sessionMap[sessionId] ? this.sessionMap[sessionId] : undefined
        },

        unread() {
            if (this.session) {
                return this.session.unread
            }

            return 0
        },
        
        lastMsg() {
            let lastMsgData
            if(this.session) {
                lastMsgData = this.session.lastMsg
            }
            return lastMsgData || {}
        },

        isSelf() {
            return this.lastMsg.from == this.myUserInfo.account
        },

        msgText() {
            if (this.lastMsg) {
                if (this.lastMsg.type == 'text') {
                    return this.lastMsg.text
                } else if (this.lastMsg.type == 'tip') {
                    return this.lastMsg.tip
                } else if (this.lastMsg.type == 'image') {
                    return '[图片]'
                } else if (this.lastMsg.type == 'video') {
                    return '[视频]'
                }
            }

            return ''
        },

        lastTime() {

            if (this.lastMsg && this.lastMsg.status) {

                const date = new Date(this.lastMsg.time)
                const nowDate = new Date()

                const year = date.getFullYear().toString()
                const month = (date.getMonth() + 1).toString().padStart(2, '0')
                const day = date.getDate().toString().padStart(2, '0')
                const hour = date.getHours().toString().padStart(2, '0')
                const minute = date.getMinutes().toString().padStart(2, '0')
                const second = date.getSeconds().toString().padStart(2, '0')
                
                let timeStr = ''

                if (date.getDate() != nowDate.getDate()) {
                    timeStr += `${month}-${day} `
                } else {
                    timeStr += `${hour}:${minute}`
                }

                return timeStr
            }

            return ''
        },

        isReaded() {
            if (this.isSelf) {
                const sessionId = `p2p-${this.lastMsg.to}`
                const { msgReceiptTime } = this.sessionMap[sessionId]
                if (this.lastMsg.time <= msgReceiptTime) {
                    return true
                }
            }
            return false
        },
    },
    methods: {
        ...mapActions('nim', ['setCurrentUser']),
        itemTap() {
            if (!this.sessionUser || this.sessionUser.to !== this.userInfo.account) {
                this.setCurrentUser(this.userInfo.account)
            }
        }
    },
    created() {
        
    }
}
</script>

<style scoped>
.contact-item {
    background-color: #fff;
    padding: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    user-select: none;
}

.contact-item.item-select {
    background-color: #ebf3fc;
}

.contact-item .content {
    min-width: 0;
    display: flex;
    flex-direction: column;
    margin-right: 6px;
    margin-left: 10px;
    flex-grow: 1;
}

.contact-item .content .name {
    font-size: 14px;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.contact-item .content .msg {
    font-size: 12px;
    color: #999;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.contact-item .content .msg .status {
    width: 14px;
    height: 14px;
    margin-right: 4px;
    box-sizing: border-box;
    flex-shrink: 0;
}

.contact-item .content .msg .status .unread {
    width: 14px;
    height: 14px;
    border: 1px solid #537ff4;
    border-radius: 50%;
    box-sizing: border-box;
}

.contact-item .content .msg .status .icon {
    line-height: 16px;
    font-size: 16px;
    color: #537ff4;
}

.contact-item .content .msg .status .icon.fail {
    color: #ff4d4f;
    cursor: pointer;
}

.contact-item .content .msg .text {
    min-width: 0;
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contact-item .state {
    align-self: flex-start;
    flex-shrink: 0;
}

.contact-item .state .time {
    line-height: 22px;
    font-size: 12px;
    color: #999;
}
</style>