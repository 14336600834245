<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item>
                <el-form-item label="用户编号">
                    <el-input v-model="query.userNumber" placeholder="请输入用户编号"></el-input>
                </el-form-item>
                <el-form-item label="退款类型">
                    <el-select v-model="query.type" placeholder="请选择退款类型">
                        <el-option label="全部" value="" ></el-option>
                        <el-option
                            v-for="(label,value) in typeAry"
                            :key="value"
                            :label="label"
                            :value="value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="query.status" placeholder="请选择状态">
                        <el-option label="全部" value="" ></el-option>
                        <el-option
                            v-for="(label,value) in statusAry"
                            :key="value"
                            :label="label"
                            :value="value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="userNumber" label="用户编号" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/'+ scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="nickName" label="用户昵称" min-width="150" align="center"></el-table-column>
            <el-table-column prop="type" label="退款类型" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="[typeColor[scope.row.type]]">{{ typeAry[scope.row.type] }}</span>
				</template>
            </el-table-column>
            <el-table-column prop="status" label="状态" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="[statusColor[scope.row.status]]">{{ statusAry[scope.row.status] }}</span>
				</template>
            </el-table-column>
            <el-table-column prop="applyTime" label="申请时间" min-width="150" align="center"></el-table-column>
            <el-table-column prop="refundTime" label="退款时间" min-width="150" align="center"></el-table-column>
            <el-table-column prop="refundNumber" label="退款次数" min-width="100" align="center"></el-table-column>
            <el-table-column prop="rewardNumber" label="打赏次数" min-width="100" align="center"></el-table-column>
            <el-table-column prop="orderNum" label="订单编号" min-width="160" align="center"></el-table-column>
            <el-table-column prop="productCode" label="产品编号" min-width="100" align="center"></el-table-column>
            <el-table-column prop="price" label="订单价格" min-width="100" align="center"></el-table-column>
            <el-table-column prop="platform" label="平台" min-width="80" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="120" align="center">
                <template slot-scope="scope">
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>
    </div>
</template>

<script>
import {
    refundRecordPage,
    refundRecordDelete
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
			size: 10,
			total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                nickName: "",
                userNumber: "",
                type: "",
                status: ""
            },
            statusAry: ['申请退款','退款成功'],
            statusColor: ['color-warning','color-danger'],
            
            typeAry: ['K币退款','vip退款'],
            typeColor: ['color-warning','color-danger'],
            formLabelWidth: "80px",
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getRefundRecordList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getRefundRecordList();
        },
        handleSizeChange(size) {
			this.size = size
			this.getRefundRecordList();
		},
        //分页获取列表
        getRefundRecordList() {
            refundRecordPage({
                current: this.page,
				size: this.size,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                type: this.query.type,
                status: this.query.status
            })
                .then(response => {
                    this.total = response.total
                    this.dataList = response.records
                })
        },

        //删除
        deleteClick(id) {
            this.$confirm('确定要删除退款记录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteGift(id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
        },
        deleteGift(id) {
            let params = new URLSearchParams()
			params.append("idList", [id])
			refundRecordDelete({ params })
				.then(() => {
					window.location.reload() //刷新页面
				})
        }
    },
    mounted() {
        this.getRefundRecordList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>