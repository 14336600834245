<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="时间">
                    <el-select v-model="query.days" style="width: 120px">
                        <el-option :label="item.label" :value="item.value" v-for="(item,index) in daysAry" :key="index"></el-option>
                    </el-select>
                </el-form-item>
				<el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="用户ID" style="width: 140px" @keydown.enter.native="search"></el-input>
				</el-form-item>
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="用户昵称" style="width: 140px;" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="是否实名">
                    <el-select v-model="query.isRealName" style="width: 120px">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="是" value="1"></el-option>
                        <el-option label="否" value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="用户性别">
                    <el-select v-model="query.gender" style="width: 120px">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="男" value="1"></el-option>
                        <el-option label="女" value="2"></el-option>
                    </el-select>
                </el-form-item>
				<el-form-item>
					<el-button type="primary" @click="search">刷新</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="userNumber" label="用户ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="`/user/${scope.row.userId}`" target="_blank">{{ scope.row.userNumber }}</a>
				</template>
			</el-table-column>
            <el-table-column prop="nickName" label="用户昵称" min-width="100" align="center"></el-table-column>
			<el-table-column prop="gender" label="用户性别" min-width="40" align="center">
                <template slot-scope="scope">
                    {{ ['','男','女'][scope.row.gender] }}
				</template>
			</el-table-column>
			<el-table-column prop="isRealName" label="是否实名" min-width="40" align="center">
                <template slot-scope="scope">
                    {{ ['否','是'][scope.row.isRealName] }}
				</template>
			</el-table-column>
			<el-table-column prop="totalCount" label="评论回复数" min-width="40" align="center"></el-table-column>

			<el-table-column fixed="right" label="操作" min-width="80" align="center">
				<template slot-scope="scope">
					<el-button type="danger" size="mini" @click="resetById(scope.row.userId)">重置</el-button>
                    &nbsp;
                    <el-link type="primary" @click="showView(scope.row, 'comment')">查看评论({{scope.row.commentCount || 0}})</el-link>
                    &nbsp;
                    <el-link type="primary" @click="showView(scope.row, 'reply')">查看回复({{scope.row.replyCount || 0}})</el-link>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

        <el-dialog :title="'查看' + viewLabel"width="1000px" :visible.sync="viewVisible">
            <div class="user-info">
                <div class="txt">用户ID: {{viewRow.userNumber}}</div>
                <div class="txt">用户昵称: {{viewRow.nickName}}</div>
            </div>
            <el-table :max-height="tableHeight" :data="viewList" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
                <el-table-column prop="content" :label="viewLabel + '内容'" min-width="150" align="center">
                    <template slot-scope="scope">
                        <audio controls :key="scope.row.id" v-if="scope.row.ilk == 1">
                            <source :src="fileDomain + scope.row.audio" :type="scope.row.audioType">
                        </audio>
                        <div class="content" v-else>{{ scope.row.content }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="articleId" label="动态ID" min-width="40" align="center">
                    <template slot-scope="scope">
                        <a :href="'/article/' + scope.row.articleId" target="_blank">{{ scope.row.articleId }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" :label="viewLabel + '时间'" min-width="80" align="center"></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { commentRefreshPage, commentRefreshPost, commentRefreshList } from '@/api/api'
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,

            fileDomain: 'https://file.oiki.cc/',

			daysAry: [
                { label: '1天内', value: '1' },
                { label: '3天内', value: '3' },
                { label: '7天内', value: '7' },
            ],

            query: {
                days: '1',
                nickName: '',
				userNumber: '',
				isRealName: '',
				gender: '',
			},

            viewVisible: false,
            viewType: null,
            viewList: [],
            viewRow: {}
        }
    },
    computed: {
        viewLabel() {
            return this.viewType === 'comment' ? '评论' : '回复'
        },
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getCommentList();
		},
        handleSizeChange(size) {
			this.size = size
			this.getCommentList()
		},
		changeClick(page) {
			this.page = page
			this.getCommentList()
		},
        getCommentList() {
			const params = {
				current: this.page,
				size: this.size,
				days: this.query.days,
				nickName: this.query.nickName,
				userNumber: this.query.userNumber,
				isRealName: this.query.isRealName,
				gender: this.query.gender,
			}

            commentRefreshPage(params).then(response => {
				this.total = response.total
				this.tableData = response.records.map(item => {
					if (item.category == 1) {
						const images = item.content.split(',')
						item.images = images.map(img => this.fileDomain + img)
					} else if ([2,3].includes(item.category)) {
						const fields = {2: 'video', 3: 'audio'}
						item[fields[item.category]] = this.fileDomain + item.content
					}

					return item;
				})
			})
        },
		resetById(userId) {
			this.$confirm('确定要重置此评论?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'danger'
			}).then(() => {
                console.log(userId,'userId')
				commentRefreshPost({ userId }).then(() => {
					this.getCommentList()
				})
			})
		},
        showView(row, type) {
            this.viewVisible = true
            this.viewRow = row
            this.viewType = type
            this.viewList = []
            commentRefreshList({
                userId: row.userId,
                days: this.query.days,
                type: type === 'comment'? 0 : 1,
            }).then((response) => {
                this.viewList = response.map(item => {
                    if (item.ilk == 1) {
                        const audioAry = item.audio.split('.')
                        const audioSuffix = audioAry[audioAry.length - 1]
                        item.audioType = audioSuffix == 'aac' ? 'audio/aac' : 'audio/mpeg'
                    }

                    return item
                })
            })
        },
    },
    created() {
		//获取评论列表
		this.getCommentList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
.user-info {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
</style>