export function privacyText(text, startLen = 1, endLen = 0, ellipsis = '*') {
	if (typeof text === 'number') {
		text = text.toString()
	}
	
	if (typeof text !== 'string') return ''
	
	const start = text.slice(0, startLen)
	const end = endLen == 0 ? '' : text.slice(-1 * endLen)
	const ellipsisLength = text.length - startLen - endLen
	return ellipsisLength > 0 ? start + ellipsis.repeat(ellipsisLength) + end : text
}


export function getDefaultAvatar(gender) {
    return `mp-images/default-avatar-${gender}.png`
}

export function montageDomain (path, fileDomain) {
    if (typeof path != 'string' || path == '') return ''
    const notSplicedAry = ['http://', 'https://', 'ttfile://', 'wxfile://', '/static', './static', 'data:image', 'blob:']
    const spliced = notSplicedAry.every(prefix => path.slice(0, prefix.length) != prefix)
    
    if (spliced) {
        return fileDomain + path
    }
    
    return path
}