<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="昵称">
					<el-input v-model="query.nickName" placeholder="昵称" style="width: 155px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="用户编号">
					<el-input v-model="query.userNumber" placeholder="用户编号" style="width: 110px" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<!-- <el-form-item label="产品名">
					<el-select _model="" placeholder="产品名" style="width: 80px;">
						<el-option label="全部" value=""></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="是否实名">
					<el-select v-model="query.isRealName" placeholder="是否实名" style="width: 90px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="已实名" value="1" ></el-option>
                        <el-option label="未实名" value="0" ></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付平台">
					<el-select v-model="query.platform" placeholder="支付平台" style="width: 80px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="ios" value="ios" ></el-option>
                        <el-option label="android" value="android" ></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付方式">
					<el-select v-model="query.type" placeholder="支付方式" style="width: 90px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="label" :value="value" v-for="(label, value) in typeAry" :key="value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付类型">
					<el-select v-model="query.goodsType" placeholder="支付类型" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="label" :value="value" v-for="(label, value) in goodsTypeAry" :key="value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付状态">
					<el-select v-model="query.status" placeholder="支付状态" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="label" :value="value" v-for="(label, value) in statusAry" :key="value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="创建时间">
					<el-date-picker v-model="selectDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
					<el-button type="warning" icon="el-icon-document" @click="exportData">导出excel</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="nickName" label="用户昵称" min-width="80" align="center"></el-table-column>
			<el-table-column prop="userNumber" label="用户编号" min-width="80" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="isRealName" label="是否实名" min-width="40" align="center">
				<template slot-scope="scope">
					<div :class="['color-0','color-1'][scope.row.isRealName]">{{['未实名','已实名'][scope.row.isRealName]}}</div>
				</template>
			</el-table-column>
            <el-table-column prop="goodsType" label="支付类型" min-width="40" align="center">
				<template slot-scope="scope">
					<div>{{goodsTypeAry[scope.row.goodsType]}}</div>
				</template>
			</el-table-column>
            <el-table-column prop="goodsName" label="产品名" min-width="60" align="center"></el-table-column>
			<el-table-column prop="platform" label="支付平台" min-width="40" align="center"></el-table-column>
			<el-table-column prop="type" label="支付方式" min-width="40" align="center">
				<template slot-scope="scope">
					<div>{{typeAry[scope.row.type]}}</div>
				</template>
			</el-table-column>
            <el-table-column prop="price" label="支付金额" min-width="40" align="center"></el-table-column>
			<el-table-column prop="status" label="支付状态" min-width="40" align="center">
				<template slot-scope="scope">
					<div :class="'color-' + scope.row.status">{{statusAry[scope.row.status]}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="transactionId" label="交易编号" min-width="80" align="center"></el-table-column>
			<el-table-column prop="createTime" label="创建时间" min-width="80" align="center"></el-table-column>
			<!-- <el-table-column fixed="right" label="操作" width="60" align="center">
				<template slot-scope="scope">
					<el-link type="primary">详情</el-link>
				</template>
			</el-table-column> -->
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page="false" layout="slot, total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
				<span class="page-text">充值金额：{{ rechargeMoney }}</span>
				<span class="page-text">充值笔数：{{ rechargeNumber }}</span>
				<span class="page-text">充值人数：{{ rechargeUserNumber }}</span>
			</el-pagination>
		</div>
    </div>
</template>

<script>
import {
	vipPricePage,
    vipOrderPage,
	vipOrderPost,
	vipOrderUpdate,
    vipOrderDelete,
	vipOrderStatistics
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				nickName: '',
				userNumber: '',
				isRealName: '',
				orderNum: '',
				platform: '',
				goodsType: '',
				status: 1,
				type: '',
            },

			rechargeMoney: 0,
			rechargeNumber: 0,
			rechargeUserNumber: 0,


			goodsTypeAry: ['普通支付','自动续费'],
			typeAry: ['苹果','微信','支付宝','银行卡'],
			statusAry: ['待支付', '已支付', '取消支付'],

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

			selectDate: [],
        }
    },
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getOrderList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.selectDate = []

			this.page = 1
			this.getOrderList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getOrderList()
		},
		changeClick(page) {
			this.page = page
			this.getOrderList()
		},
		//获取订单列表接口
		getOrderList() {
			const params = {
				current: this.page,
				size: this.size,
				nickName: this.query.nickName,
				userNumber: this.query.userNumber,
				isRealName: this.query.isRealName,
				orderNum: this.query.orderNum,
				platform: this.query.platform,
				goodsType: this.query.goodsType,
				status: this.query.status,
				type: this.query.type,
				startTime: this.selectDate != null ? this.selectDate[0] : null,
				endTime: this.selectDate != null ? this.selectDate[1] : null,
			}
			
			vipOrderPage(params).then((response) => {
				this.total = response.total
				this.tableData = response.records
			})

			vipOrderStatistics(params).then((response) => {
				this.rechargeMoney = response.rechargeMoney
				this.rechargeNumber = response.rechargeNumber
				this.rechargeUserNumber = response.rechargeUserNumber
			})
		},
		exportData() {
            this.$prompt('请输入导出数据条数(默认100)','导出数据', {
				closeOnClickModal: false
			}).then(({value}) => {
				let number = value != null ? parseInt(value) : 100
				if (isNaN(number)) {
					this.$message.error('请输入整型数值！')
					return;
				} else if (number > 2147483647) {
					this.$message.info('数值超过int最大长度，已变更为2147483647！')
					number = 2147483647
				}

				const exportUrl = '/api/vipOrder/export'

				const params = {
					current: 1,
					size: number,
                    
                    status: this.query.status,
                    nickName: this.query.nickName,
                    userNumber: this.query.userNumber,
                    isRealName: this.query.isRealName,
                    type: this.query.type,
                    platform: this.query.platform,
                    startDate: this.selectDate != null ? this.selectDate[0] : null,
                    endDate: this.selectDate != null ? this.selectDate[1] : null
				}

				let urlParams = '?'
				Object.keys(params).forEach(key => {
					const value = params[key]
					if (value) {
						urlParams += '&' + key + '=' + encodeURIComponent(value)
					}
				})

				console.log(urlParams,'urlParams')
				const fileName = '贵族支付记录.xlsx'
				const xhr = new XMLHttpRequest()
				xhr.open('GET', exportUrl + urlParams, true)
				xhr.responseType = 'blob'
				xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token)
				xhr.onload = () => {
					if (xhr.status == 200) {
						const type = xhr.getResponseHeader('Content-Type')
						const blob = new Blob([xhr.response], {type})
						if (typeof window.navigator.msSaveBlob !== 'undefined') {
							window.navigator.msSaveBlob(blob, fileName)
						} else {
							const URL = window.URL || window.webkitURL
							const blobUrl = URL.createObjectURL(blob)

							const a = document.createElement('a')
							if (typeof a.download === 'undefined') {
								window.open(blobUrl)
							} else {
								a.href = blobUrl
								a.download = fileName
								document.body.appendChild(a)
								a.click()
								a.remove()
							}
						}
					} else {
						this.$message.error('导出失败')
					}
				}
				xhr.send()
			})
        }
	},
	created() {
		//获取订单列表
		this.getOrderList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
.color-0 {color: #E6A23C;}
.color-1 {color: #67C23A;}
.color-2 {color: #F56C6C;}
</style>