<template>
    <div>
        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="店名">
                    <el-input v-model="query.name" placeholder="请输入小店店名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="success" @click="addClick">添加</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="name" label="店名" min-width="150" align="center"></el-table-column>
            <el-table-column prop="image" label="店铺图片" min-width="250" align="center">
                <template slot-scope="scope">
                    <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.images" :key="index" :src="item"
                        :preview-src-list="scope.row.images" >
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="详细地址" min-width="200" align="center"></el-table-column>
            <el-table-column prop="startTime" label="营业开始时间" min-width="120" align="center"></el-table-column>
            <el-table-column prop="endTime" label="营业结束时间" min-width="120" align="center"></el-table-column>
            <el-table-column prop="date" label="经营日期" min-width="220" align="center"></el-table-column>
            <el-table-column prop="content" label="经营内容" min-width="250" align="center"></el-table-column>
            <el-table-column prop="liaison" label="联系人" min-width="120" align="center"></el-table-column>
            <el-table-column prop="phone" label="联系电话" min-width="120" align="center"></el-table-column>
            <el-table-column prop="rule" label="交友规则" min-width="250" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="150" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="100" align="center">
                <template slot-scope="scope">
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="添加小店" :visible.sync="addShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
            <el-form :model="addForm">
                <el-form-item label="店名" :label-width="formLabelWidth">
                    <el-input v-model="addForm.name" placeholder="请输入小店店名" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="店铺图片" :label-width="formLabelWidth">
                    <el-upload action="api/file/upload" :headers="headers" :data="uploadData" list-type="picture"
                        :on-preview="handlePictureCardPreview" :limit="9" :file-list="imageList" :on-remove="imageRemove"
                        :on-success="imageSuccess" :on-change="handleChange" :on-error="uploadError">
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="店铺地址" :label-width="formLabelWidth">
                    <el-input v-model="addForm.address" placeholder="店铺地址" autocomplete="off" :disabled="true">
                        <el-button slot="append" @click="showMap">请选择地址</el-button>
                    </el-input>
                </el-form-item>
                <el-form-item label="开始时间" :label-width="formLabelWidth">
                    <el-time-select v-model="addForm.startTime" :picker-options="{
                        start: '08:00',
                        step: '00:15',
                        end: '18:30'
                    }" placeholder="选择开始时间">
                    </el-time-select>
                </el-form-item>
                <el-form-item label="结束时间" :label-width="formLabelWidth">
                    <el-time-select v-model="addForm.endTime" :picker-options="{
                        start: '18:30',
                        step: '00:15',
                        end: '23:30'
                    }" placeholder="选择结束时间">
                    </el-time-select>
                </el-form-item>
                <el-form-item label="经营日期" :label-width="formLabelWidth">
                    <el-select v-model="date" multiple placeholder="请选择经营日期">
                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                            :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="经营内容" :label-width="formLabelWidth">
                    <el-input v-model="addForm.content" type="textarea" :rows="2" placeholder="请输入经营内容"
                        autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系人" :label-width="formLabelWidth">
                    <el-input v-model="addForm.liaison" placeholder="请输入联系人" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" :label-width="formLabelWidth">
                    <el-input v-model="addForm.phone" placeholder="请输入联系电话" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="交友规则" :label-width="formLabelWidth">
                    <el-input v-model="addForm.rule" type="textarea" :rows="2" placeholder="请输入交友规则"
                        autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addShow = false">取 消</el-button>
                <el-button type="primary" @click="addSmallStore">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="选择地址" :visible.sync="mapShow" :close-on-click-modal="false" width="50%" center>

            <div style="width: 100%; height: 400px; margin: 10px;">
                <!-- 搜索框 -->
                <el-amap-search-box :search-option="searchOption" :on-search-result="onSearchResult" style="margin-bottom: 10px;"/>
                <!-- 地图 -->
                <el-amap class="amap-box" :zoom="amap.zoom" :center="amap.center" :plugin="amap.plugin"
                    :events="amap.events">
                    <!-- 当前位置图标 -->
                    <el-amap-marker v-for="(marker, index) in markers" :key="'marker' + index"
                        :position="marker.position" />
                    <!-- 位置名称显示 -->
                    <el-amap-text v-for="(marker, index) in markers" :key="'text' + index" :text="marker.text"
                        :offset="marker.offset" :position="marker.position" />
                </el-amap>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="mapShow = false">取 消</el-button>
                <el-button type="primary" @click="mapShow = false">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import {
    smallStorePage,
    smallStoreSave,
    smallStoreDelete
} from "@/api/api";
export default {
    data() {
        let self = this; //定义当前对象(data) 为 self
        return {
            dataList: [],
            query: {
                name: "",
                platform: ""
            },
            formLabelWidth: "80px",

            options: [{
                value: '星期一',
                label: '星期一'
            }, {
                value: '星期二',
                label: '星期二'
            }, {
                value: '星期三',
                label: '星期三'
            }, {
                value: '星期四',
                label: '星期四'
            }, {
                value: '星期五',
                label: '星期五'
            }, {
                value: '星期六',
                label: '星期六'
            }, {
                value: '星期日',
                label: '星期日'
            }],
            date: [],

            addShow: false,
            addForm: {
                name: '',
                image: '',
                longitude: '',
                latitude: '',
                province: '',
                city: '',
                area: '',
                areaCode: '',
                address: '',
                startTime: '',
                endTime: '',
                date: [],
                content: '',
                liaison: '',
                phone: '',
                rule: ''
            },

            updateShow: false,
            updateForm: {
                id: '',
                name: '',
                code: '',
                price: '',
                number: '',
                platform: ''
            },


            uploadDisabled: false,
            dialogImageUrl: '',
            dialogVisible: false,
            headers: {
                Authorization: "Bearer " + localStorage.token
            },
            uploadData: {
                code: "9001",
                index: 0
            },
            imageList: [], //图片列表


            mapShow: false,
            // 地图搜索对象
            searchOption: {
                city: "全国",
                citylimit: true,
            },
            lng: 0,
            lat: 0,
            markers: [],
            // 地图对象
            amap: {
                zoom: 16,
                center: [116.319802, 39.98294],
                events: {
                    // 点击获取地址的数据
                    click(e) {
                        console.log("点击地图1")
                        const { lng, lat } = e.lnglat;
                        self.lng = lng;
                        self.lat = lat;
                        console.log("点击地图2")
                        // 这里通过高德 SDK 完成。
                        var geocoder = new AMap.Geocoder({
                            radius: 100,
                            extensions: "all",
                        });
                        geocoder.getAddress([lng, lat], function (status, result) {
                            if (status === "complete" && result.info === "OK") {
                                if (result && result.regeocode) {
                                    console.log("点击获取地址的数据", result);
                                    self.addForm.longitude = lng ? lng.toString() : ""
                                    self.addForm.latitude = lat ? lat.toString() : ""
                                    self.addForm.province = result.regeocode.addressComponent.province
                                    self.addForm.city = result.regeocode.addressComponent.city
                                    self.addForm.area = result.regeocode.addressComponent.district
                                    self.addForm.areaCode = result.regeocode.addressComponent.adcode
                                    self.addForm.address = result.regeocode.formattedAddress;
                                    self.markers = [];
                                    const obj = {
                                        position: [lng, lat],
                                        text: result.regeocode.formattedAddress,
                                        offset: [0, 30],
                                    };
                                    self.markers.push(obj);
                                }
                            }
                        });
                        self.$nextTick();
                    },
                },

                plugin: [
                    {
                        // 定位
                        pName: "Geolocation",
                        events: {
                            init(o) {
                                // o是高德地图定位插件实例
                                o.getCurrentPosition((status, result) => {
                                    console.log("定位：", result);
                                    if (result && result.position) {
                                        // 设置经度
                                        self.lng = result.position.lng;
                                        // 设置维度
                                        self.lat = result.position.lat;
                                        // 设置坐标
                                        self.amap.center = [self.lng, self.lat];

                                        self.addForm.longitude = result.position.lng
                                        self.addForm.latitude = result.position.lat
                                        self.addForm.province = result.addressComponent.province
                                        self.addForm.city = result.addressComponent.city
                                        self.addForm.area = result.addressComponent.district
                                        self.addForm.areaCode = result.addressComponent.adcode
                                        self.addForm.address = result.formattedAddress

                                        let op = {
                                            position: [self.lng, self.lat],
                                            text: result.formattedAddress,
                                            offset: [0, 30],
                                        };
                                        self.markers.push(op);
                                        console.log(111111111)
                                        console.log(self.markers)
                                        // 页面渲染好后
                                        self.$nextTick();
                                    }
                                });
                            },
                        },
                    },
                ],
                // 
                markers: [],
            },
        };
    },
    methods: {
        search() {
            this.getSmallStoreList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getSmallStoreList();
        },
        //分页获取小店列表
        getSmallStoreList() {
            smallStorePage({
                name: this.query.name
            })
                .then(response => {
                    this.dataList = response.records;
                    for (let i = 0; i < this.dataList.length; i++) {
                        let smallStore = this.dataList[i];
                        if (smallStore.image != null && smallStore.image.length > 0) {
                            smallStore.images = smallStore.image.split(",")
                            for(let j = 0; j < smallStore.images.length; j++) {
                                smallStore.images[j] = "https://file.oiki.cc/" + smallStore.images[j]
                            }
                        }
                    }
                })
        },

        //添加小店事件
        addClick() {
            this.addShow = true
        },
        //新增小店
        addSmallStore() {
            for (let m = 0; m < this.date.length; m++) {
                if (m == 0) {
                    this.addForm.date = this.date[m]
                } else {
                    this.addForm.date = this.addForm.date + "," + this.date[m]
                }
            }
            for (let i = 0; i < this.imageList.length; i++) {
                if (i == 0) {
                    this.addForm.image = this.imageList[i].response.data
                } else {
                    this.addForm.image = this.addForm.image + "," + this.imageList[i].response.data
                }
            }
            smallStoreSave({
                name: this.addForm.name,
                image: this.addForm.image,
                longitude: this.addForm.longitude,
                latitude: this.addForm.latitude,
                province: this.addForm.province,
                city: this.addForm.city,
                area: this.addForm.area,
                areaCode: this.addForm.areaCode,
                address: this.addForm.address,
                startTime: this.addForm.startTime,
                endTime: this.addForm.endTime,
                date: this.addForm.date,
                content: this.addForm.content,
                liaison: this.addForm.liaison,
                phone: this.addForm.phone,
                rule: this.addForm.rule
            })
                .then(() => {
                    this.getSmallStoreList() //保存成功，重新获取数据
                })
            this.addShow = false
        },

        //删除小店事件
        deleteClick(id) {
            this.$confirm('确定要删除此小店?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deleteSmallStore(id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除',
                    center: true
                });
            });
        },
        deleteSmallStore(id) {
            let params = new URLSearchParams()
            params.append("idList", [id])
            smallStoreDelete({ params })
                .then(() => {
                    window.location.reload() //刷新页面
                })
        },



        //查看图片调用
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
        handleChange(file, fileList) {

        },
        //图片删除
        imageRemove(file, fileList) {
            this.imageList = fileList
        },
        // 上传图片出错
        uploadError(err, file, fileList) {
            this.$message.error("上传出错");
        },
        //图片上传成功回调
        imageSuccess(response, file, fileList) {
            console.log(fileList)
            if (response.code == 0) {
                //上传成功
                this.imageList = fileList
            } else {
                //上传失败，删除图片
                for (let i = 0; i < fileList.length; i++) {
                    if (file == fileList[i]) {
                        fileList.splice(i, 1)
                    }
                }
            }
        },
        //显示大图
        showImgList(image) {
            let images = [image]
            return images
        },


        showMap() {
            console.log("显示地图")
            this.mapShow = true
        },
        // 地图搜索回调
        onSearchResult(pois) {
            const self = this;
            self.amap.markers = [];
            let latSum = 0;
            let lngSum = 0;
            console.log("地图回调", pois);
            if (pois.length > 0) {
                pois.forEach((poi, index) => {
                    const { lng, lat } = poi;
                    if (index === 0) {
                        lngSum = lng;
                        latSum = lat;
                        const obj = {
                            position: [poi.lng, poi.lat],
                            text: poi.name,
                            offset: [0, 30],
                        };
                        self.markers.push(obj);
                        console.log("地图搜索回调", poi);
                        self.addForm.address = poi.name;
                        self.addForm.latitude = poi.lat ? poi.lat.toString() : "";
                        self.addForm.longitude = poi.lng ? poi.lng.toString() : "";
                    }
                });
                self.amap.center = [lngSum, latSum];
            }
        },
    },
    mounted() {
        this.getSmallStoreList();
    }
};
</script>