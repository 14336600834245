<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="昵称">
					<el-input v-model="query.nickName" placeholder="昵称" style="width: 155px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="用户编号">
					<el-input v-model="query.userNumber" placeholder="用户编号" style="width: 110px" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="靓号">
					<el-input v-model="query.cornet" placeholder="靓号" style="width: 110px" @keydown.enter.native="search"></el-input>
				</el-form-item>
                <el-form-item label="性别">
					<el-select v-model="query.gender" placeholder="性别" style="width: 80px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="男" value="1"></el-option>
						<el-option label="女" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="贵族等级">
					<el-select v-model="query.vipIndex" placeholder="贵族等级" style="width: 80px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="power.name" :value="index" v-for="(power, index) in powerList" :key="index"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="最后支付时间">
					<el-date-picker v-model="lastPayTime" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="过期时间">
					<el-date-picker v-model="expireTime" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="自动续费">
					<el-select v-model="query.isAuto" placeholder="自动续费" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="是" value="1"></el-option>
						<el-option label="否" value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="贵族状态">
					<el-select v-model="query.status" placeholder="贵族状态" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="label" :value="value" v-for="(label,value) in statusAry" :key="value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="nickName" label="昵称" min-width="150" align="center"></el-table-column>
			<el-table-column prop="userNumber" label="编号" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="gender" label="性别" min-width="60" align="center">
                <template slot-scope="scope">
                    {{ ['','男','女'][scope.row.gender] }}
                </template>
            </el-table-column>
            <el-table-column prop="cornet" label="靓号" min-width="60" align="center"></el-table-column>
            <el-table-column prop="exp" label="贵族经验" min-width="60" align="center"></el-table-column>
			<el-table-column prop="vipName" label="贵族等级" min-width="60" align="center"></el-table-column>
			<el-table-column prop="lastPayTime" label="最后支付时间" min-width="100" align="center"></el-table-column>
			<el-table-column prop="isAuto" label="是否开通自动续费" min-width="80" align="center">
				<template slot-scope="scope">
					<span style="color: #85ce61;" v-if="scope.row.isAuto == 1">是</span>
					<span v-else>否</span>
				</template>
			</el-table-column>
			<el-table-column prop="status" label="贵族状态" min-width="50" align="center">
				<template slot-scope="scope">
					<span :class="[['color-success','color-danger'][scope.row.status]]">{{ statusAry[scope.row.status] }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="expireTime" label="贵族过期时间" min-width="100" align="center"></el-table-column>
			<!-- <el-table-column fixed="right" label="操作" width="60" align="center">
				<template slot-scope="scope">
					<el-link type="primary" @click="toDetail(scope.row.id)">详情</el-link>
				</template>
			</el-table-column> -->
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

		
    </div>
</template>

<script>
import {
    vipPage,
	vipPost,
	vipUpdate,
    vipDelete,
	vipPowerPage
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				nickName: '',
				userNumber: '',
				cornet: '',
				gender: '',
				vipIndex: '',
				isAuto: '',
				status: 0
            },
			statusAry: ['未过期','已过期'],

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			powerList: [],
			lastPayTime: [],
			expireTime: []
        }
    },
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getVipList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.lastPayTime = []
			this.expireTime = []

			this.page = 1
			this.getVipList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getVipList()
		},
		changeClick(page) {
			this.page = page
			this.getVipList()
		},
		//获取用户列表接口
		getVipList() {
			let minExp = ''
			let maxExp = ''
			if (this.query.vipIndex !== '' && this.powerList[this.query.vipIndex]) {
				const vipPower = this.powerList[this.query.vipIndex]
				minExp = vipPower.minExp
				maxExp = vipPower.maxExp
			}

			vipPage({
				"current": this.page,
				"size": this.size,
				"nickName": this.query.nickName,
				"userNumber": this.query.userNumber,
				"cornet": this.query.cornet,
				"gender": this.query.gender,
				"minExp": minExp,
				"maxExp": maxExp,
				"isAuto": this.query.isAuto,
				"status": this.query.status,
				"lastStartTime": this.lastPayTime != null ? this.lastPayTime[0] : null,
				"lastEndTime": this.lastPayTime != null ? this.lastPayTime[1] : null,
				"expireStartTime": this.expireTime != null ? this.expireTime[0] : null,
				"expireEndTime": this.expireTime != null ? this.expireTime[1] : null
			})
				.then((response) => {
					this.total = response.total
					this.tableData = response.records.map(item => {
						if (item.expireTime) {
							item.status = this.getVipStatus(item)
						}

						return item
					})
				})
				.catch((error) => {
					console.log(error)
				});
		},
		getPowerList() {
			vipPowerPage({
                current: 1,
                size: 10
            }).then(response => {
                this.powerList = response
            })
		},
		getVipStatus(item) {
			if (!item.expireTime) return ''
			const nowDate = new Date()
			const expireDate = new Date(item.expireTime.replace(/-/g,'/'))
			
			return expireDate.getTime() > nowDate.getTime() ? 0 : 1
		}
	},
	created() {
		//获取用户列表
		this.getVipList()
		// 获取贵族等级列表
		this.getPowerList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style>
</style>