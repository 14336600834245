<template>
    <div>

        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="类名">
                    <el-input v-model="query.name" placeholder="请输入类名"></el-input>
                </el-form-item>
                <el-form-item label="类型" :label-width="formLabelWidth">
					<el-select v-model="query.type" placeholder="请选择类型">
						<el-option label="全部" value=""></el-option>
						<el-option label="安全课堂" value=0></el-option>
						<el-option label="关于我们" value=1></el-option>
					</el-select>
				</el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="success" @click="addClick">添加</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="name" label="类名" min-width="150" align="center"></el-table-column>
            <el-table-column prop="type" label="类型" min-width="150" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.type === 0">安全课堂</span>
					<span v-else-if="scope.row.type === 1">关于我们</span>
				</template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="150" align="center"></el-table-column>
            <el-table-column prop="createName" label="创建人" min-width="150" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="150" align="center">
                <template slot-scope="scope">
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="添加分类" :visible.sync="addShow" :close-on-click-modal="false" width="18%" center zIndex=1999>
			<el-form :model="addForm">
				<el-form-item label="类名" :label-width="formLabelWidth">
					<el-input v-model="addForm.name" placeholder="请输入类名" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="类型" :label-width="formLabelWidth">
					<el-select v-model="addForm.type" placeholder="请选择类型">
						<el-option label="安全课堂" value=0></el-option>
						<el-option label="关于我们" value=1></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addShow = false">取 消</el-button>
				<el-button type="primary" @click="addCategory">确 定</el-button>
			</div>
		</el-dialog>

    </div>
</template>

<script>
import {
    courseCategoryList,
    courseCategorySave,
    courseCategoryDelete
} from "@/api/api";
export default {
    data() {
        return {
            total: 0,
            dataList: [],
            query: {
                name: "",
                type: ""
            },
            formLabelWidth: "80px",

            addShow: false,
            addForm: {
				name: '',
                type: ""
			},
        };
    },
    methods: {
        search() {
            this.getCourseCategoryList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getCourseCategoryList();
        },
        //获取所有分类列表
        getCourseCategoryList() {
            courseCategoryList({
                name: this.query.name,
                type: this.query.type
            })
                .then(response => {
                    this.dataList = response;
                })
        },
        
        //添加分类事件
		addClick() {
			this.addShow = true
		},
        //新增分类
		addCategory() {
			courseCategorySave({
				name: this.addForm.name,
                type: this.addForm.type
			})
				.then(() => {
					this.getCourseCategoryList() //保存成功，重新获取数据
				})
			this.addShow = false
		},

        //删除分类事件
        deleteClick(id) {
            this.$confirm('确定要删除此分类?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteCategory(id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
        },
        deleteCategory(id) {
            let params = new URLSearchParams()
			params.append("idList", [id])
			courseCategoryDelete({ params })
				.then(() => {
					window.location.reload() //刷新页面
				})
        }
    },
    mounted() {
        this.getCourseCategoryList();
    }
};
</script>