<template>
    <div>
        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="产品名称">
                    <el-input v-model="query.name" placeholder="请输入产品名称"></el-input>
                </el-form-item>
                <el-form-item label="平台">
                    <el-select v-model="query.platform" placeholder="请选择平台">
                        <el-option label="web" value="web"></el-option>
                        <el-option label="苹果" value="ios"></el-option>
						<el-option label="安卓" value="android"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="success" @click="addClick">添加</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="name" label="产品名称" min-width="150" align="center"></el-table-column>
            <el-table-column prop="code" label="产品编号" min-width="150" align="center"></el-table-column>
            <el-table-column prop="price" label="产品价格" min-width="150" align="center"></el-table-column>
            <el-table-column prop="number" label="K币数量" min-width="150" align="center"></el-table-column>
            <el-table-column prop="platform" label="平台" min-width="150" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.platform === 'ios'" style="color: #67C23A">苹果</span>
					<span v-else-if="scope.row.platform === 'android'" style="color: #409EFF">安卓</span>
                    <span v-else-if="scope.row.platform === 'web'" style="color: #E6A23C">web</span>
				</template>
			</el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="150" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="180" align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">编辑
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="添加产品" :visible.sync="addShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form :model="addForm">
				<el-form-item label="产品名称" :label-width="formLabelWidth">
					<el-input v-model="addForm.name" placeholder="请输入产品名称" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="产品编号" :label-width="formLabelWidth">
					<el-input v-model="addForm.code" placeholder="请输入产品编号" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="产品价格" :label-width="formLabelWidth">
					<el-input v-model="addForm.price" placeholder="请输入产品价格" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="K币数量" :label-width="formLabelWidth">
					<el-input v-model="addForm.number" placeholder="请输入K币数量" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="平台" :label-width="formLabelWidth">
					<el-select v-model="addForm.platform" placeholder="请选择平台">
                        <el-option label="web" value="web"></el-option>
						<el-option label="苹果" value="ios"></el-option>
						<el-option label="安卓" value="android"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addShow = false">取 消</el-button>
				<el-button type="primary" @click="addProduct">确 定</el-button>
			</div>
		</el-dialog>

        <el-dialog title="编辑产品" :visible.sync="updateShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form :model="updateForm">
				<el-form-item label="产品名称" :label-width="formLabelWidth">
					<el-input v-model="updateForm.name" placeholder="请输入产品名称" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="产品编号" :label-width="formLabelWidth">
					<el-input v-model="updateForm.code" placeholder="请输入产品编号" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="产品价格" :label-width="formLabelWidth">
					<el-input v-model="updateForm.price" placeholder="请输入产品价格" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="K币数量" :label-width="formLabelWidth">
					<el-input v-model="updateForm.number" placeholder="请输入K币数量" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="平台" :label-width="formLabelWidth">
					<el-select v-model="updateForm.platform" placeholder="请选择平台">
                        <el-option label="web" value="web"></el-option>
						<el-option label="苹果" value="ios"></el-option>
						<el-option label="安卓" value="android"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="updateShow = false">取 消</el-button>
				<el-button type="primary" @click="submitUpdate">确 定</el-button>
			</span>
		</el-dialog>

    </div>
</template>

<script>
import {
    productList,
    productSave,
    productUpdate,
    productDelete
} from "@/api/api";
export default {
    data() {
        return {
            dataList: [],
            query: {
                name: "",
                platform: ""
            },
            formLabelWidth: "80px",

            addShow: false,
            addForm: {
				name: '',
                code: '',
				price: '',
				number: '',
				platform: ''
			},

            updateShow: false,
            updateForm: {
                id: '',
				name: '',
                code: '',
				price: '',
				number: '',
				platform: ''
			},
        };
    },
    methods: {
        search() {
            this.getProductList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getProductList();
        },
        //获取产品分页列表
        getProductList() {
            productList({
                name: this.query.name,
                platform: this.query.platform
            })
                .then(response => {
                    this.dataList = response;
                })
        },

        //添加产品事件
		addClick() {
			this.addShow = true
		},
        //新增产品
		addProduct() {
			productSave({
				name: this.addForm.name,
                code: this.addForm.code,
				price: this.addForm.price,
				number: this.addForm.number,
				platform: this.addForm.platform
			})
				.then(() => {
					this.getProductList() //保存成功，重新获取数据
				})
			this.addShow = false
		},

        //编辑产品事件
		updateClick(row) {
			this.updateShow = true
			this.updateForm.id = row.id
			this.updateForm.name = row.name
            this.updateForm.code = row.code
			this.updateForm.price = row.price
			this.updateForm.number = row.number.toString()
			this.updateForm.platform = row.platform
		},
        //提交产品编辑事件
		submitUpdate() {
			productUpdate({
                id: this.updateForm.id,
                name: this.updateForm.name,
                code: this.updateForm.code,
                price: this.updateForm.price,
                number: this.updateForm.number,
                platform: this.updateForm.platform
            }).then(() => {
                this.getProductList();
            })
            this.updateShow = false
		},

        //删除产品事件
        deleteClick(id) {
            this.$confirm('确定要删除此产品?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteProduct(id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
        },
        deleteProduct(id) {
            let params = new URLSearchParams()
			params.append("idList", [id])
			productDelete({ params })
				.then(() => {
					// window.location.reload() //刷新页面
                    this.getProductList();
				})
        }
    },
    mounted() {
        this.getProductList();
    }
};
</script>