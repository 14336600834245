<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="打招呼内容">
					<el-input v-model="query.content" placeholder="打招呼内容" style="width: 155px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="匹配角色">
					<el-select v-model="query.type" placeholder="匹配角色" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="label" :value="value" v-for="(label, value) in typeAry" :key="value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="warning" icon="el-icon-circle-plus-outline" @click="addRow">添加</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="content" label="打招呼内容" min-width="300" align="center"></el-table-column>
			<el-table-column prop="type" label="匹配角色" min-width="100" align="center">
                <template slot-scope="scope">
                    {{ typeAry[scope.row.type] }}
                </template>
            </el-table-column>
			<el-table-column prop="createTime" label="创建时间" min-width="100" align="center"></el-table-column>
			<el-table-column fixed="right" label="操作" min-width="100" align="center">
				<template slot-scope="scope">
					<el-button type="warning" size="mini" @click="editRow(scope.row)">修改</el-button>
					<el-button type="danger" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

        <el-dialog width="600px" title="" :visible.sync="formVisible" :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="100px" :rules="formRules" style="text-align: left;">
                <el-form-item prop="content" label="打招呼内容">
                    <el-input v-model="form.content" placeholder="请输入打招呼内容"></el-input>
                </el-form-item>
                <el-form-item prop="type" label="匹配角色">
                    <el-select v-model="form.type" placeholder="匹配角色" style="width: 140px;">
						<el-option :label="label" :value="value" v-for="(label, value) in typeAry" :key="value"></el-option>
					</el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" :loading="formLoading" @click="formConfirm">确 定</el-button>
			</div>
        </el-dialog>
    </div>
</template>

<script>
import {
    matchChatPage,
	matchChatPost,
	matchChatUpdate,
    matchChatDelete
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				content: '',
				type: '',
            },
            typeAry: ['主动匹配','被动匹配'],

            formVisible: false,
            formLoading: false,
            form: {},
            formRules: {
                content: [
                    { required: true, message: '请输入打招呼内容' }
                ],
                type: [
                    { required: true, message: '请选择匹配角色' }
                ]
            }
        }
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getMatchChatList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.lastPayTime = []
			this.expireTime = []

			this.page = 1
			this.getMatchChatList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getMatchChatList()
		},
		changeClick(page) {
			this.page = page
			this.getMatchChatList()
		},
		//获取打招呼列表接口
		getMatchChatList() {
			matchChatPage({
				"current": this.page,
				"size": this.size,
				"content": this.query.content,
				"type": this.query.type,
			})
				.then((response) => {
					this.total = response.total
					this.tableData = response.records
				})
				.catch((error) => {
					console.log(error)
				});
		},
        formConfirm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const formData = {...this.form}

                    const send = formData.id ? matchChatUpdate : matchChatPost

                    send(formData).then(res => {
                        this.formVisible = false
                        this.getMatchChatList()
                    })
                }
            })
        },
        addRow() {
            this.form = {
                content: '',
                type: '',
            }
            this.formVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        editRow(row) {
            this.form = {
                id: row.id,
                content: row.content,
                type: row.type,
            }
            this.formVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        deleteRow(id) {
            this.$confirm('确定要删除此内容?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = new URLSearchParams()
                params.append("idList", [id])
                matchChatDelete({ params })
                    .then(() => {
                        this.getMatchChatList()
                    })
			})
        }
	},
	created() {
		//获取打招呼列表
		this.getMatchChatList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
</style>