<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
                <el-form-item label="申请人昵称">
                    <el-input v-model="query.nickName" placeholder="请输入申请人昵称" style="width: 150px;" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="申请人ID">
                    <el-input v-model="query.userNumber" placeholder="请输入申请人ID" style="width: 135px;" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="被申请人昵称">
                    <el-input v-model="query.addNickName" placeholder="请输入被申请人昵称" style="width: 150px;" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="被申请人ID">
                    <el-input v-model="query.addUserNumber" placeholder="请输入被申请人ID" style="width: 135px;" @keydown.enter.native="search" clearable></el-input>
                </el-form-item>
                <el-form-item label="申请状态">
                    <el-select v-model="query.status" placeholder="请选择申请状态" style="width: 90px;">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="申请中" :value="0"></el-option>
                        <el-option label="同意" :value="1"></el-option>
                        <el-option label="拒绝" :value="2"></el-option>
                        <el-option label="取消" :value="3"></el-option>
                        <el-option label="成功" :value="4"></el-option>
                        <el-option label="失败" :value="5"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序">
                    <el-radio-group v-model="query.sort">
                        <div>
                            <el-radio :label="2">最后状态时间</el-radio>
                        </div>
                        <div>
                            <el-radio :label="1">申请时间</el-radio>
                        </div>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="申请时间">
					<el-date-picker v-model="selectDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 350px;">
					</el-date-picker>
				</el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="nickName" label="申请人" min-width="150" align="center">
                <template slot-scope="scope">
                    {{ scope.row.nickName }} - 
                    <span style="color: #67C23A">{{ {1: '男', 2: '女'}[scope.row.gender] }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="userNumber" label="申请人ID" min-width="60" align="center">
                <template slot-scope="scope">
                    <a :href="`/user/${scope.row.userId}`" target="_blank" >{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="addUserNickName" label="被申请人" min-width="150" align="center">
                <template slot-scope="scope">
                    {{ scope.row.addUserNickName }} - 
                    <span style="color: #67C23A">{{ {1: '男', 2: '女'}[scope.row.addUserGender] }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="userNumber" label="被申请人ID" min-width="60" align="center">
                <template slot-scope="scope">
                    <a :href="`/user/${scope.row.addUserId}`" target="_blank" >{{ scope.row.addUserNumber }}</a>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="gender" label="可牵线性别" min-width="60" align="center">
                <template slot-scope="scope">
                    {{ ['全部','男','女'][scope.row.gender] }}
                </template>
            </el-table-column> -->
            <el-table-column prop="coinNumber" label="申请好友价格" min-width="150" align="center"></el-table-column>
            <el-table-column prop="status" label="申请状态" min-width="60" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.status === 0" style="color: #E6A23C">申请中</span>
					<span v-else-if="scope.row.status === 1" style="color: #67C23A">同意</span>
                    <span v-else-if="scope.row.status === 2" style="color: #F56C6C">拒绝</span>
                    <span v-else-if="scope.row.status === 3" style="color: #909399">取消</span>
                    <span v-else-if="scope.row.status === 4" style="color: #15975c">成功</span>
                    <span v-else-if="scope.row.status === 5" style="color: #516310">失败</span>
				</template>
			</el-table-column>
            <!-- <el-table-column prop="incomeStatus" label="收益状态" min-width="60" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.incomeStatus === 0" style="color: #E6A23C">待审核</span>
					<span v-else-if="scope.row.incomeStatus === 1" style="color: #67C23A">审核通过</span>
                    <span v-else-if="scope.row.incomeStatus === 2" style="color: #F56C6C">审核拒绝</span>
				</template>
			</el-table-column> -->
            <el-table-column prop="updateTime" label="最后状态时间" min-width="150" align="center">
                <template slot-scope="scope">
                    {{ scope.row[scope.row.status == 0 ? 'createTime' : 'updateTime'] }}
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="申请时间" min-width="150" align="center"></el-table-column>

        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background layout="slot, total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
                <span class="page-text">人数：{{ statisticsData.userNumber }}</span>
                <span class="page-text">k币数量：{{ statisticsData.coinNumber }}</span>
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    addFriendPage,
    addFriendPass,
    addFriendNoPass,
    addFriendStatistics
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                nickName: "",
                userNumber: "",
                addNickName: "",
                addUserNumber: "",
                status: "",
                sort: 2
            },
            formLabelWidth: "80px",

            selectDate: [],

            pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

            statisticsData: {}
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getAddFriendList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getAddFriendList();
        },
        //获取待审核动态分页列表
        getAddFriendList() {
            const params = {
                //incomeStatus: 0,
                status: this.query.status,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                addNickName: this.query.addNickName,
                addUserNumber: this.query.addUserNumber,
                startTime: this.selectDate ? this.selectDate[0] : '',
                endTime: this.selectDate ? this.selectDate[1] : '',
            }

            addFriendPage({
                current: this.page,
                size: this.size,
                sort: this.query.sort,
                ...params
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                })

            addFriendStatistics(params).then(res => {
                this.statisticsData = res
            })
        },
        agreeClick(id) {
            addFriendPass(id, null)
            .then(response => {
                this.getAddFriendList();
            })
        },
        async refuseClick(id) {
            await this.$prompt('拒绝原因','收益拒绝', {
                closeOnClickModal: false
            }).then(({ value }) => {
                this.refuse(id, value)
            }).catch()
        },
        refuse(id, remark) {
            addFriendNoPass({id, remark, status: 2})
            .then(response => {
                this.getAddFriendList();
            })
        }
    },
    mounted() {
        this.getAddFriendList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>