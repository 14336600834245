<template>
    <div>
        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
                <el-form-item label="举报人">
                    <el-input v-model="query.nickName" placeholder="请输入举报人" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="举报人ID">
                    <el-input v-model="query.userNumber" placeholder="请输入举报人ID" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="被举报人">
                    <el-input v-model="query.reportNickName" placeholder="请输入被举报人" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="被举报人ID">
                    <el-input v-model="query.reportUserNumber" placeholder="请输入被举报人ID" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="处理状态">
                    <el-select v-model="query.status" style="width: 120px">
                        <el-option label="全部" value=""></el-option>
                        <el-option :label="label" :value="value" v-for="(label, value) in status" :key="value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="举报类型">
                    <el-select v-model="query.type" style="width: 120px">
                        <el-option label="全部" value=""></el-option>
                        <el-option :label="label" :value="value" v-for="(label, value) in typeAry" :key="value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="nickName" label="举报人" min-width="100" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="举报人ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="`/user/${scope.row.userId}`" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="reportNickName" label="被举报人" min-width="100" align="center"></el-table-column>
            <el-table-column prop="reportUserNumber" label="被举报人ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="`/user/${scope.row.reportUserId}`" target="_blank">{{ scope.row.reportUserNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="reportNumber" label="历史被举报次数" min-width="130" align="center"></el-table-column>
            <el-table-column prop="category" label="举报类别" min-width="130" align="center"></el-table-column>
            <el-table-column prop="content" label="举报内容" min-width="200" align="center"></el-table-column>
            <el-table-column prop="images" label="举报图片" min-width="240" align="center">
                <template slot-scope="scope">
                    <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.images" :key="index" :src="item"
                        :preview-src-list="scope.row.images" >
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="type" label="举报类型" min-width="80" align="center">
                <template slot-scope="scope">
                    <span :class="['color-success','color-danger','color-warning'][scope.row.type]" >{{ typeAry[scope.row.type] }}</span>
				</template>
            </el-table-column>
            <el-table-column prop="articleContent" label="动态内容" min-width="200" align="center"></el-table-column>
            <el-table-column prop="articleImages" label="动态图片" min-width="240" align="center">
                <template slot-scope="scope">
                    <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.articleImages" :key="index" :src="item"
                        :preview-src-list="scope.row.articleImages" >
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="150" align="center"></el-table-column>
            <el-table-column fixed="right" prop="status" label="状态" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-link :type="statusColor[scope.row.status]" :underline="false">{{status[scope.row.status]}}</el-link>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="160" align="center">
                <template slot-scope="scope">
                    <!-- <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除</el-button> -->
                    <template v-if="scope.row.status == 0">
                        <el-button type="success" size="mini" @click="updateStatus(scope.row.id, 1)">处理</el-button>
                        <el-button type="danger" size="mini" @click="updateStatus(scope.row.id, 2)">忽略</el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    reportPage,
    reportDelete,
    reportUpdate
} from "@/api/api";
export default {
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            dataList: [],
            query: {
                nickName: "",
                userNumber: "",
                reportNickName: "",
                reportUserNumber: "",
                status: 0,
                type: '',
            },
            status: ['待处理','已处理','已忽略'],
            statusColor: ['warning','success','danger'],
            typeAry: ['用户','动态','匹配'],
            formLabelWidth: "80px",
        };
    },
    methods: {
        search() {
            this.page = 1;
            this.getReportList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getReportList();
        },
        //分页获取举报列表
        getReportList() {
            reportPage({
                current: this.page,
                size: this.size,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                reportNickName: this.query.reportNickName,
                reportUserNumber: this.query.reportUserNumber,
                status: this.query.status,
                type: this.query.type,
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                    for (let i = 0; i < this.dataList.length; i++) {
                        let report = this.dataList[i];
                        if (report.image != null && report.image.length > 0) {
                            report.images = report.image.split(",")
                            for(let j = 0; j < report.images.length; j++) {
                                report.images[j] = "https://file.oiki.cc/" + report.images[j]
                            }
                        }
                        if (report.articleImage != null && report.articleImage.length > 0) {
                            report.articleImages = report.articleImage.split(",")
                            for(let j = 0; j < report.articleImages.length; j++) {
                                report.articleImages[j] = "https://file.oiki.cc/" + report.articleImages[j]
                            }
                        }
                    }
                })
        },
        //删除事件
        deleteClick(id) {
            this.$confirm('确定要删除此举报?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteComment(id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
        },
        deleteComment(id) {
            let params = new URLSearchParams()
			params.append("idList", [id])
			reportDelete({ params })
				.then(() => {
					window.location.reload() //刷新页面
				})
        },
        updateStatus(id, status) {
            reportUpdate({id, status}).then(() => {
                this.getReportList()
            })
        }
    },
    mounted() {
        this.getReportList();
    }
};
</script>