import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Contextmenu from 'vue-contextmenujs'
import AMap from 'vue-amap';
import store from './store'

Vue.use(ElementUI)

Vue.use(AMap)
Vue.use(Contextmenu)

Vue.config.productionTip = false

// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: '87b822a74bb8cfdbb12b46e245541d5b',
  // 插件集合 （插件按需引入）
  plugin: [
    'AMap.Geolocation', //定位控件，用来获取和展示用户主机所在的经纬度位置
    'AMap.PlaceSearch', //POI搜索插件
    'AMap.Scale', //右下角缩略图插件 比例尺
    'AMap.OverView', //地图鹰眼插件
    'AMap.ToolBar', //地图工具条
    'AMap.Geocoder', // 逆地理编码,通过经纬度获取地址所在位置详细信息
  ]
});
//设置高德地图安全密钥
window._AMapSecurityConfig = {
  securityJsCode: "56a55e3df3669aeff2220b54c094df91"
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
