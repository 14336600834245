<template>
    <div>
        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="权限编码">
                    <el-input v-model="query.code" placeholder="请输入权限编码"></el-input>
                </el-form-item>
                <el-form-item label="权限名称">
                    <el-input v-model="query.name" placeholder="请输入权限名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="success" @click="addClick">添加</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="name" label="权限名称" min-width="100" align="center"></el-table-column>
            <el-table-column prop="code" label="权限编码" min-width="100" align="center"></el-table-column>
            <el-table-column prop="url" label="权限链接" min-width="100" align="center"></el-table-column>
            <el-table-column prop="menuName" label="绑定菜单" min-width="100" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="120" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" min-width="120" align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">编辑
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteById(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>

        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog title="添加权限" :visible.sync="addShow" :close-on-click-modal="false" width="25%" center>
            <el-form :model="addForm">
                <el-form-item label="权限名称" :label-width="formLabelWidth">
                    <el-input v-model="addForm.name" placeholder="请输入权限名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权限编码" :label-width="formLabelWidth">
                    <el-input v-model="addForm.code" placeholder="请输入权限编码" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权限链接" :label-width="formLabelWidth">
                    <el-input v-model="addForm.url" placeholder="请输入权限链接" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="选择菜单" :label-width="formLabelWidth">
                    <el-cascader placeholder="请选择菜单" v-model="selectOption" :options="menuList" :props="defaultParams"
                        @change="handleChange" filterable :show-all-levels="false"></el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addShow = false">取 消</el-button>
                <el-button type="primary" @click="addOperation">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑权限" :visible.sync="updateShow" :close-on-click-modal="false" width="25%" center>
            <el-form :model="updateForm">
                <el-form-item label="权限名称" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.name" placeholder="请输入权限名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权限编码" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.code" placeholder="请输入权限编码" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="权限链接" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.url" placeholder="请输入权限链接" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="选择菜单" :label-width="formLabelWidth">
                    <el-cascader placeholder="请选择菜单" v-model="selectOption" :options="menuList" :props="defaultParams"
                        @change="handleChange" filterable :show-all-levels="false"></el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="updateShow = false">取 消</el-button>
                <el-button type="primary" @click="submitUpdate">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    menuTreeList,
    operationPage,
    operationSave,
    operationDelete,
    operationUpdate
} from "@/api/api";
export default {
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            dataList: [],
            query: {
                code: "",
                name: ""
            },
            addShow: false,
            updateShow: false,
            menuId: 0,
            addForm: {
                name: "",
                code: "",
                url: ""
            },
            updateForm: {
                id: "",
                name: "",
                code: "",
                url: ""
            },
            formLabelWidth: "80px",

            //级联选择器信息
            selectOption: [],
            menuList: [],
            defaultParams: {
                label: "menuName",
                value: "id",
                children: "childList",
                checkStrictly: false
            }
        };
    },
    methods: {
        //添加权限事件
        addClick() {
            this.addShow = true;
            this.selectOption = [];
        },
        search() {
            this.page = 1;
            this.getOperationList();
        },
        //级联选择器选择事件
        handleChange(value) {
            console.log(value);
            this.selectOption = value;
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getOperationList();
        },
        //获取权限分页列表
        getOperationList() {
            operationPage({
                current: this.page,
                size: this.size,
                code: this.query.code,
                name: this.query.name
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                })
        },
        //提交权限编辑事件
        submitUpdate() {
            this.updateShow = false;
            if (this.selectOption.length > 0) {
                this.menuId = this.selectOption[this.selectOption.length - 1];
            }
            operationUpdate({
                id: this.updateForm.id,
                name: this.updateForm.name,
                code: this.updateForm.code,
                url: this.updateForm.url,
                menuId: this.menuId
            })
                .then(() => {
                    this.getOperationList();
                })
        },
        //获取菜单树结构
        getTreeList() {
            menuTreeList(null)
                .then(response => {
                    this.menuList = this.cleanEmptyMenu(response);
                })
        },
        //递归解决数组为空导致的级联错误
        cleanEmptyMenu(data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].childList == null || data[i].childList.length < 1) {
                    data[i].childList = undefined;
                } else {
                    this.cleanEmptyMenu(data[i].childList);
                }
            }
            return data;
        },
        //编辑权限事件
        updateClick(row) {
            this.selectOption = [];
            this.updateShow = true;
            this.updateForm.id = row.id;
            this.updateForm.name = row.name;
            this.updateForm.code = row.code;
            this.updateForm.url = row.url;
            //循环获取上级
            this.getSelectOption(row.menuId, this.menuList);
        },
        //循环获取上级
        getSelectOption(parentId, menuList) {
            for (let i = 0; i < menuList.length; i++) {
                let menu = menuList[i];
                if (menu.id == parentId) {
                    this.selectOption.unshift(menu.id);
                    if (menu.parentId != 0) {
                        //循环获取上级
                        this.getSelectOption(menu.parentId, this.menuList);
                    }
                }
                if (
                    menu.childList != null &&
                    menu.childList != undefined &&
                    menu.childList.length > 0
                ) {
                    this.getSelectOption(parentId, menu.childList);
                }
            }
        },
        //删除权限事件
        deleteById(id) {
            this.$confirm("确定要删除此权限?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.deleteOperation(id);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                        center: true
                    });
                });
        },
        //删除权限接口
        deleteOperation(id) {
            let params = new URLSearchParams()
            params.append("idList", [id])
            operationDelete({ params })
                .then(() => {
                    window.location.reload(); //刷新页面
                    this.getOperationList(); //删除成功，重新获取数据
                })
        },
        //新增权限
        addOperation() {
            if (this.selectOption.length > 0) {
                this.menuId = this.selectOption[this.selectOption.length - 1];
            } else {
                this.$message.error("请选择菜单")
                return
            }
            operationSave({
                name: this.addForm.name,
                code: this.addForm.code,
                url: this.addForm.url,
                menuId: this.menuId
            })
                .then(() => {
                    this.getOperationList(); //保存成功，重新获取数据
                })
            this.addShow = false;
        }
    },
    mounted() {
        this.getTreeList();
        this.getOperationList();
    }
};
</script>
