<template>
  <div>
    <div :class="$style.container">
      <!-- IMUIKIT 相关内容 -->
      <div :class="$style.header">
        <div :class="$style.search" ref="search" />
        <div :class="$style.add" ref="add" />
      </div>
      <div :class="$style.content">
        <div :class="$style.left">
          <div :class="$style['avatar-icon']" ref="avatar" />
          <div :class="{ [$style['chat-icon']]: true, [$style.active]: model === 'chat' }" @click="() => model = 'chat'">
            <i :class="{ [$style['iconfont']]: true, 'iconfont': true, 'icon-message_fill_light': true }" />
            <div :class="$style['icon-label']">会话</div>
          </div>
          <div :class="{ [$style['contact-icon']]: true, [$style.active]: model === 'contact' }"
            @click="() => model = 'contact'">
            <i :class="{ [$style['iconfont']]: true, 'iconfont': true, 'icon-tongxunlu1': true }" />
            <div :class="$style['icon-label']">通讯录</div>
          </div>
          <div :class="$style['logout-icon']">
            <i :class="{ [$style['iconfont']]: true, 'iconfont': true, 'icon-logout': true }" />
            <div :class="$style['icon-label']">退出</div>
          </div>
        </div>
        <div :class="$style.right" v-show="model === 'chat'">
          <div :class="$style['right-list']" ref="conversation" />
          <div :class="$style['right-content']" ref="chat" />
        </div>
        <div :class="$style.right" v-show="model === 'contact'">
          <div :class="$style['right-list']" ref="contactList" />
          <div :class="$style['right-content']" ref="contactInfo" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  IMUIKit,
  ConversationContainer, // 会话列表组件
  ChatContainer, // 聊天（会话消息）组件
  AddContainer, // 搜索——添加按钮组件
  SearchContainer, // 搜索——搜索组件
  ContactListContainer, // 通讯录——通讯录导航组件
  ContactInfoContainer, // 通讯录——通讯录详情组件，包含好友列表、群组列表以及黑名单列表
  MyAvatarContainer, // 用户资料组件
} from "@xkit-yx/im-kit-ui/lib/index.js";
import "@xkit-yx/im-kit-ui/es/style/css";
// @/utils/
// @xkit-yx/
import "./iconfont.css";
import voiceAudio from '@/assets/audio/msgVoice.mp3'

export default {
  name: "IMApp",
  data: function () {
    return {
      audio: undefined,
      model: "chat",
      $uikit: ''
    };
  },
  methods: {
    initAudio() {
      this.audio = new Audio(voiceAudio)
    },
    onMsg(msg) {
      console.log(msg,'msg 321')
      if (!this.audio) {
        this.initAudio()
      }

      // 播放新消息提示音频
      this.audio.play()
    }
  },
  mounted() {
    let user = JSON.parse(localStorage.getItem("user"))
    const appkey = process.env.NODE_ENV == 'development' ? '609513b6f2bc26d9ccd3dcebc6a995c5' : '5bd42a7ade879d8b92261b9bb7069eb3'
    const initOptions = {
      appkey,
      account: user.userNumber, // 请填写你的account
      token: user.yunToken, // 请填写你的token,
      debugLevel: 'debug'
    };
    const localOptions = {
      // 添加好友模式，默认需要验证
      addFriendNeedVerify: false,
      // 群组被邀请模式，默认不需要验证
      teamBeInviteMode: 'noVerify',
      // 单聊消息是否显示已读未读 默认 false
      p2pMsgReceiptVisible: true,
      // 群聊消息是否显示已读未读 默认 false
      teamMsgReceiptVisible: true,
      // 是否需要@消息 默认 true
      needMention: true,
      // 是否显示在线离线状态 默认 true
      loginStateVisible: true,
      // 是否允许转让群主
      allowTransferTeamOwner: true,
    }
    this.$uikit = new IMUIKit({
      initOptions,
      singleton: true,
      sdkVersion: 1,
      localOptions,
      funcOptions: {
        onmsg: this.onMsg
      }
    });
    this.$uikit.render(
      SearchContainer,
      {
        onClickChat: () => {
          // this.model = "chat";
          console.log('123123')
        },
      },
      this.$refs.search
    );
    this.$uikit.render(
      AddContainer,
      {
        onClickChat: () => {
          this.model = "chat";
        },
      },
      this.$refs.add
    );
    this.$uikit.render(MyAvatarContainer, null, this.$refs.avatar);
    this.$uikit.render(ConversationContainer, null, this.$refs.conversation);
    this.$uikit.render(
      ChatContainer,
      {
        // 以下是自定义渲染，用 compile 函数包裹 html 就可以了，注意 class 要写成 className
        // 安装并引入： import { compile } from "jsx-web-compiler";
        // renderHeader: () => compile(`<div className="my-header">123</div>`),
        // renderEmpty: () => compile("<div>This is empty</div>"),
      },
      this.$refs.chat
    );
    this.$uikit.render(ContactListContainer, null, this.$refs.contactList);
    this.$uikit.render(
      ContactInfoContainer,
      {
        afterSendMsgClick: () => {
          this.model = "chat";
        },
        onGroupItemClick: () => {
          this.model = "chat";
        },
      },
      this.$refs.contactInfo
    );

    
    // const { store, nim } = window.__xkit_store__
    // 监听消息接收
    /* nim.on('msg', (msg) => {
      console.log(msg,'onmsg')
      if (!this.audio) {
        this.initAudio()
      }

      // 播放新消息提示音频
      this.audio.play()
    }) */

    // console.log(store.msgStore,'store.msgStore')
  },
  destroyed() {
    // const { nim } = window.__xkit_store__
    // nim.off('msg')
  }
};
</script>
  
<style module>
body {
  background: #d8dee5;
}

.container {
  width: 1070px;
  height: 670px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
}

.header {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e8e8e8;
}

.search {
  width: 50%;
}

.add {
  margin-left: 20px;
}

.content {
  width: 100%;
  height: 610px;
  display: flex;
}

.left {
  width: 60px;
  border-right: 1px solid #e8e8e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.avatar-icon {
  margin: 20px 0 25px 0;
  border-radius: 50%;
  border: 1px solid #e8e8e8;
}

.iconfont {
  font-size: 24px;
}

.chat-icon,
.contact-icon {
  margin: 0 0 25px 0;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.6);
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.active {
  color: #2a6bf2;
}

.logout-icon {
  position: absolute;
  bottom: 10px;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.icon-label {
  font-size: 12px;
  text-align: center;
}

.right {
  flex: 1;
  display: flex;
}

.right-list {
  width: 200px;
  border-right: 1px solid #e8e8e8;
}

.right-content {
  flex: 1;
}
</style>



<style>
  .ant-image-preview-img-wrapper .ant-image-preview-img {
    height: auto;
  }
</style>
  