<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
                <el-form-item label="分享者ID">
                    <el-input v-model="query.shareUserNumber" placeholder="分享者ID" style="width: 120px" @keydown.enter.native="search"></el-input>
                </el-form-item>
				<el-form-item label="分享者昵称">
					<el-input v-model="query.shareNickName" placeholder="分享者昵称" style="width: 160px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="分享页面">
					<el-select v-model="query.type" placeholder="分享页面" style="width: 120px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="label" :value="value" v-for="(label, value) in typeAry" :key="value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="下级注册时间">
					<el-date-picker v-model="searchDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
                    <el-button type="warning" icon="el-icon-document" @click="exportData">导出excel</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="shareUserNumber" label="分享者ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/userNumber/' + scope.row.shareUserNumber" target="_blank">{{ scope.row.shareUserNumber }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="shareNickName" label="分享者昵称" min-width="150" align="center"></el-table-column>
			<el-table-column prop="type" label="分享页面" min-width="80" align="center">
				<template slot-scope="scope">
					{{ typeAry[scope.row.type] }}
				</template>
			</el-table-column>
            <el-table-column prop="businessId" label="页面ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="['/user/', '/article/'][scope.row.type] + scope.row.businessId" target="_blank" v-if="[0,1].includes(scope.row.type)">{{ scope.row.businessId }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="downUserNumber" label="下级ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/userNumber/' + scope.row.downUserNumber" target="_blank">{{ scope.row.downUserNumber }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="downNickName" label="下级昵称" min-width="100" align="center"></el-table-column>
			<el-table-column prop="downRegisterTime" label="下级注册时间" min-width="100" align="center"></el-table-column>
			<!-- <el-table-column fixed="right" label="操作" width="60" align="center">
				<template slot-scope="scope">
					<el-link type="primary" @click="toDetail(scope.row.id)">详情</el-link>
				</template>
			</el-table-column> -->
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

		
    </div>
</template>

<script>
import {
    userRelationPage
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				shareNickName: '',
				shareUserNumber: '',
				type: '',
            },
			typeAry: ['主页','动态','app'],

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

			searchDate: [],
        }
    },
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getUserRelationList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.searchDate = []

			this.page = 1
			this.getUserRelationList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getUserRelationList()
		},
		changeClick(page) {
			this.page = page
			this.getUserRelationList()
		},
		getSearchParame() {
			return {
				"current": this.page,
				"size": this.size,
				"shareNickName": this.query.shareNickName,
				"shareUserNumber": this.query.shareUserNumber,
				"type": this.query.type,
				"startTime": this.searchDate != null ? this.searchDate[0] : null,
				"endTime": this.searchDate != null ? this.searchDate[1] : null,
			}
		},
		//获取用户关系列表接口
		getUserRelationList() {
            const params = this.getSearchParame()

			userRelationPage(params)
				.then((response) => {
					this.total = response.total
					this.tableData = response.records
				})
		},
        exportData() {
            
        }
	},
	created() {
		//获取用户关系列表
		this.getUserRelationList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style>
</style>