<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="靓号">
					<el-input v-model="query.cornet" placeholder="靓号" style="width: 155px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="标签">
					<el-input v-model="query.label" placeholder="标签" style="width: 110px" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="状态">
					<el-select v-model="query.status" placeholder="状态" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option label="空闲" value="0"></el-option>
						<el-option label="已占用" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="warning" icon="el-icon-circle-plus-outline" @click="addRow">添加</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="cornet" label="优质靓号" min-width="150" align="center"></el-table-column>
			<el-table-column prop="label" label="靓号标签" min-width="150" align="center"></el-table-column>
			<el-table-column prop="status" label="状态" min-width="150" align="center">
                <template slot-scope="scope">
                    {{ scope.row.status == 1 ? '已占用' : '空闲' }}
                </template>
            </el-table-column>
			<el-table-column prop="sort" label="推荐排序" min-width="150" align="center"></el-table-column>
			<el-table-column fixed="right" label="操作" width="180" align="center">
				<template slot-scope="scope">
					<el-button type="warning" size="mini" @click="editRow(scope.row)">修改</el-button>
					<el-button type="danger" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

        <el-dialog width="600px" title="" :visible.sync="formVisible" :close-on-click-modal="false">
            <el-form ref="form" :model="form" label-width="80px" :rules="formRules">
                <el-form-item prop="cornet" label="优质靓号">
                    <el-input v-model="form.cornet" placeholder="请输入优质靓号" maxlength="4" @input="form.sort = form.sort.replace(/\D|^0/g, '')"></el-input>
                </el-form-item>
                <el-form-item prop="label" label="靓号标签">
                    <el-input v-model="form.label" placeholder="不超过5个字（选填）" maxlength="5"></el-input>
                </el-form-item>
                <el-form-item prop="sort" label="推荐排序">
                    <el-input v-model="form.sort" placeholder="1-9999" maxlength="4" @input="form.sort = form.sort.replace(/\D|^0/g, '')"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
				<el-button @click="formVisible = false">取 消</el-button>
				<el-button type="primary" :loading="formLoading" @click="formConfirm">确 定</el-button>
			</div>
        </el-dialog>
    </div>
</template>

<script>
import {
    highCornetPage,
	highCornetPost,
	highCornetUpdate,
    highCornetDelete,
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				cornet: '',
				label: '',
				status: '',
            },

            formVisible: false,
            formLoading: false,
            form: {},
            formRules: {
                cornet: [
                    { required: true, message: '请输入优质靓号' }
                ],
                sort: [
                    { required: true, message: '请输入排序数值' }
                ]
            }
        }
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getHighCornetList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.lastPayTime = []
			this.expireTime = []

			this.page = 1
			this.getHighCornetList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getHighCornetList()
		},
		changeClick(page) {
			this.page = page
			this.getHighCornetList()
		},
		//获取用户列表接口
		getHighCornetList() {
			highCornetPage({
				"current": this.page,
				"size": this.size,
				"cornet": this.query.cornet,
				"label": this.query.label,
				"status": this.query.status,
			})
				.then((response) => {
					this.total = response.total
					this.tableData = response.records
				})
				.catch((error) => {
					console.log(error)
				});
		},
        formConfirm() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    const formData = {...this.form}

                    const send = formData.id ? highCornetUpdate : highCornetPost

                    send(formData).then(res => {
                        this.formVisible = false
                        this.getHighCornetList()
                    })
                }
            })
        },
        addRow() {
            this.form = {
                cornet: '',
                label: '',
                sort: ''
            }
            this.formVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        editRow(row) {
            this.form = {
                id: row.id,
                cornet: row.cornet,
                label: row.label,
                sort: row.sort
            }
            this.formVisible = true
            this.$nextTick(() => {
                this.$refs.form.clearValidate()
            })
        },
        deleteRow(id) {
            this.$confirm('确定要删除此靓号?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = new URLSearchParams()
                params.append("idList", [id])
                highCornetDelete({ params })
                    .then(() => {
                        this.getHighCornetList()
                    })
			})
        }
	},
	created() {
		//获取靓号列表
		this.getHighCornetList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
</style>