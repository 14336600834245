<template>
    <div ref="scrollList" class="contact-list" @scroll="scrollHandler">
        <div id="unread-row" class="unread-row" @click="toUnreadItem">
            <div class="label">未读消息</div>
            <div class="badge" :class="[allUnReadNumber ? 'on' : '']">{{allUnReadNumber}}</div>
        </div>

        <item :item="session" v-for="session in orderedSessions" :key="session.account" />

        <div class="to-top" @click="toTop" v-show="scrollTop > 50">回到顶部</div>
        <div class="blank"></div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import item from './item.vue'
export default {
    components: {
        item
    },
    data() {
        return {
            scrollTop: 0,
            unreadIndex: null,
            pageTitle: ''
        }
    },
    computed: {
        ...mapGetters('nim', ['nim', 'sessionMap', 'orderedSessions']),
        allUnReadNumber() {
            const sessionList = Object.values(this.sessionMap)
            const number = sessionList.reduce((number, item) => {
                if (item.unread) number += item.unread
                return number
            }, 0)

            document.title = number ? `您有${number}条新消息` : this.pageTitle
            
            return number
        }
    },
    methods: {
        ...mapMutations('nim', ['addHook']),
        ...mapActions('nim', ['getSessions']),
        /* getContactList() {
            this.nim.getServerSessions({
                done: (err, data) => {
                    console.log(err, data,'getServerSessions done')
                }
            })
        } */

        scrollHandler(e) {
            this.scrollTop = e.target.scrollTop
        },
        toTop() {
            const scrollDom = this.$refs.scrollList
            scrollDom.scrollTop = 0
        },
        toUnreadItem() {
            const sessionList = Object.values(this.orderedSessions)
            const indexArr = []
            sessionList.forEach((item,index) => {
                if (item.unread) indexArr.push(index)
            })
            console.log(sessionList, indexArr,'indexArr')
            
            if (!indexArr.length) return;
            
            if (this.unreadIndex === null || indexArr.length === 1 || this.unreadIndex == indexArr.length - 1) {
                this.unreadIndex = 0
            } else {
                this.unreadIndex++
            }

            const unreadItem = sessionList[indexArr[this.unreadIndex]]

            // console.log(unreadItem.id,'unreadId')
            const dom = document.getElementById(unreadItem.id)
            const offsetDom = document.getElementById('unread-row')
            const offsetTop = dom.offsetTop - offsetDom.scrollHeight
            // console.log(offsetTop,'offsetTop')
            this.$refs.scrollList.scrollTop = offsetTop

        }
    },
    created() {
        this.addHook({
            hookName: 'sessions',
            funcName: 'getContact',
            func: () => {
                this.getSessions()
            }
        })

        this.pageTitle = document.title
    }
}
</script>

<style scoped>
.contact-list {
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow-y: auto;
    position: relative;
    z-index: 1;
}

.contact-list .unread-row {
    line-height: 30px;
    background-color: #fff;
    padding: 5px 20px;
    border-bottom: 1px solid #efefef;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    z-index: 2;
    top: 0;
    cursor: pointer;
    user-select: none;
}

.contact-list .unread-row .badge {
    height: 18px;
    line-height: 18px;
    font-size: 12px;
    background-color: #DCDFE6;
    padding: 0 5px;
    border-radius: 20px;
}

.contact-list .unread-row .badge.on {
    color: #fff;
    background-color: #f56c6c;
}

.contact-list .to-top {
    height: 30px;
    line-height: 30px;
    color: #fff;
    background-color: #537ff4;
    border: 1px solid #537ff4;
    border-radius: 20px;
    margin-right: 20px;
    margin-left: 20px;
    position: sticky;
    z-index: 2;
    bottom: 10px;
    cursor: pointer;
    user-select: none;
}

.contact-list .blank {
    height: 10px;
}
</style>