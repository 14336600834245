<template>
    <div class="chat-user-card" v-show="visible">
        <div class="mask" @click="close"></div>
        <div class="card-box">
            <div class="avatar">
                <avatar :src="userInfo.avatar" />
            </div>
            <div class="form">
                <div class="item-row">
                    <div class="label">ID</div>
                    <div class="value">
                        <a :href="'/userNumber/' + userInfo.account" target="_blank" >{{ userInfo.account }}</a>
                    </div>
                </div>
                <div class="item-row">
                    <div class="label">手机</div>
                    <div class="value">
                        {{ userPhone }}
                    </div>
                </div>
                <div class="item-row">
                    <div class="label">昵称</div>
                    <div class="value">
                        {{ userInfo.nick }}
                    </div>
                </div>
                <div class="item-row">
                    <div class="label">性别</div>
                    <div class="value">
                        {{ userInfo.gender ? userInfo.gender == 'male' ? '男' : '女' : '' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { privacyText } from '@/utils/'
import avatar from './avatar'
export default {
    components: {
        avatar
    },
    props: {
        userInfo: {
            type: Object
        }
    },
    data() {
        return {
            visible: false
        }
    },
    computed: {
        userPhone() {
            return privacyText(this.userInfo.tel, 3, 4)
        }
    },
    methods: {
        open () {
            this.visible = true
        },
        close () {
            this.visible = false
        }
    }
}
</script>

<style scoped>
.chat-user-card {
    position: fixed;
    z-index: 200;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.mask {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.card-box {
    width: 260px;
    height: fit-content;
    background-color: #fff;
    border-radius: 6px;
    margin: auto;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0 2px 8px rgba(0,0,0,.3);
    box-sizing: border-box;
}

.card-box .avatar {
    padding: 20px 20px 0;
}

.card-box .form {
    padding: 0 20px 20px;
}

.card-box .form .item-row {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.card-box .form .item-row .label {
    font-size: 14px;
    color: #666;
    margin-right: 16px;
    white-space: nowrap;
    flex-shrink: 0;
}

.card-box .form .item-row .value {
    width: 100%;
    min-width: 0;
    font-size: 14px;
    color: #333;
}
</style>