<template>
    <div class="page">
        <div class="page-title">基础信息</div>
        <div class="info-box">
            <div class="item">征友信息ID：{{detailData.id}}</div>
            <div class="item">发布者：<a :href="'/user/' + detailData.userId" target="_blank">{{detailData.userNumber}}</a></div>
            <div class="item">发布时间：{{detailData.createTime}}</div>
            <div class="item">到期时间：{{detailData.expireTime}}</div>
            <div class="item">座位数：{{detailData.number}}</div>
            <div class="item">置顶：{{detailData.isTop == 1 ? '是' : '否'}}</div>
            <div class="item">
                状态：<span :class="[statusColor[detailData.status]]">{{ statusAry[detailData.status] }}</span>
                <span>&nbsp;</span>
                <el-button size="mini" type="warning" @click="rejectClick" v-if="detailData.status == 0" >驳回</el-button>
                <el-button size="mini" type="danger" @click="deleteClick" v-if="detailData.status == 0" >删除</el-button>
            </div>
            <div class="item">支付k币：{{detailData.totalCoinNumber}}</div>
        </div>

        <div class="page-title">内容信息</div>
        <div class="detail-box">
            <div class="item">
                <div class="title">我期望</div>
                <div class="content">{{ detailData.hope }}</div>
            </div>
            <div class="item">
                <div class="title">我拒绝</div>
                <div class="content">{{ detailData.refuse }}</div>
            </div>
            <div class="item">
                <div class="title">我的优势</div>
                <div class="content">{{ detailData.advantage }}</div>
            </div>
            <div class="item">
                <div class="title">图片</div>
                <div class="content">
                    <div class="imgs">
                        <!-- <el-image style="width: 60px;height: 60px;padding: 1px 3px;" :src="img" v-for="(img,index) in detailData.images" :key="index" /> -->
                        <template v-for="(img,index) in detailData.images">
                            <el-popover
                                placement="left"
                                trigger="hover"
                                popper-class="pointer-events-none"
                                :open-delay="0"
                                :close-delay="0"
                                :key="index"
                            >
                                <el-image style="height: 60px; width: 60px; padding: 2px;" :src="img" :preview-src-list="detailData.images" slot="reference" ></el-image>
                                <el-image style="height: auto; max-height: 90vh; max-width: 400px;" :src="img" ></el-image>
                            </el-popover>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-title">申请记录</div>
        <el-table max-height="400" :data="detailData.applyList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }" :row-class-name="tableRowClassName">
            <el-table-column prop="nickName" label="用户昵称" min-width="150" align="center">
                <template slot-scope="scope">
                    <!-- <i class="el-icon-chat-dot-round" style="margin-right: 20px;" v-if="[0,1].includes(scope.row.status)"></i> -->
                    <img :src="fileDomain + '/mp-images/icon-seat.png'" width="20" alt="" v-if="[0,1].includes(scope.row.status)">
                    {{ scope.row.nickName }}
                </template>
            </el-table-column>
            <el-table-column prop="userNumber" label="用户ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/userNumber/' + scope.row.userNumber" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="申请时间" min-width="100" align="center"></el-table-column>
            <el-table-column prop="status" label="交友状态" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="[applyStatusColor[scope.row.status]]">{{ applyStatusAry[scope.row.status] }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=false layout="total" :total="detailData?.applyList?.length || 0">
			</el-pagination>
		</div>

        <delete-dialog ref="deleteDialog" :title="deleteType == 4 ? '驳回' : '删除'" :type="deleteType" @confirm="deleteConfirm"></delete-dialog>
    </div>
</template>

<script>
import {
    getSeekFriendById,
	seekFriendReject,
	seekFriendDelete
} from "@/api/api";
import deleteDialog from '@/components/deleteDialog'
export default {
	components: {
        deleteDialog
    },
    data() {
        return {
            detailData: {},
            statusAry: ['征友中','已驳回','已删除','已过期','已完成'],
            statusColor: ['color-warning', 'color-danger', 'color-danger', 'color-danger','color-success',''],
            applyStatusAry: ['沟通中','成功','失败'],
            applyStatusColor: ['color-warning','color-success','color-danger'],
            fileDomain: 'https://file.oiki.cc/',

            deleteType: 4,
        }
    },
    methods: {
        getPublishDetail() {
            getSeekFriendById(this.id).then(res => {
                const data = res
                if (data.image) {
                    const imgAry = data.image.split(',')
                    const images = imgAry.map(img => this.fileDomain + img)
                    data.images = images
                }

                // 数组排序 成功和沟通中放上面
                data.applyList.sort((a, b) => {
                    let sort = 0;
                    if ([a.status, b.status].indexOf(1) > -1) {
                        sort = a.status === 1 ? -2 : 2
                    } else if ([a.status, b.status].indexOf(0) > -1) {
                        sort = a.status === 0 ? -1 : 1
                    }

                    return sort
                })
                
                this.detailData = data
            })
        },
        tableRowClassName({row, rowIndex}) {
            if ([0,1].includes(row.status)) {
                return 'on-row'
            }
            return ''
        },

        rejectClick() {
			this.deleteType = 4
            this.$refs.deleteDialog.showDialog()
        },
        deleteClick() {
			this.deleteType = 5
            this.$refs.deleteDialog.showDialog()
        },
        deleteConfirm(data) {
			const fn = this.deleteType == 4 ? seekFriendReject : seekFriendDelete
			const formData = { id: this.id, remark: data.content }
            this.deleteLoading = true
			fn(formData).then(() => {
                this.getPublishDetail();

                this.deleteLoading = false
                this.deleteFormVisible = false
                this.$nextTick(() => {
                    this.deleteForm.reason = ''
                    this.$refs.deleteForm.resetFields()
                })
            })
        },
    },
    created() {
        this.id = this.$route.params.id
        if (this.id) {
            this.getPublishDetail()
        }
    }
}
</script>

<style scoped>
.page {
    text-align: left;
}

.page-title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
}

.info-box {
    display: grid;
    grid-template-columns: 20% 30% 30% 20%;
    margin-bottom: 20px;
}

.info-box .item {
    font-size: 16px;
    padding: 5px 10px;
    vertical-align: middle;
}

.detail-box {
    display: grid;
    grid-template-columns: repeat(4, 23%);
    grid-gap: 2%;
}

.detail-box .item {
    padding: 5px 10px;
}

.detail-box .item .title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
}

.detail-box .item .content {
    
}

.detail-box .item .imgs {

}

.el-table >>> .on-row {
    background-color: #eee;
}
</style>