<template>
    <div>
        <div style="margin-bottom: 20px;">
            <div class="title-row">
                <div class="flex-box">
                    <div class="table-title">自动续费</div>
                    <span class="title-tips">注：ios自动续费价格修改需要开发者申请内购成功后进行，请勿随意操作；安卓自动续费的价格可随时修改，但修改时需要注意同时修改按月支付类型的各价格</span>
                </div>
            </div>
            <el-table :data="renewList" border :cell-style="{ padding: '0' }"
                :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
                :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
                <el-table-column prop="type" label="支付类型" min-width="80" align="center">
                    <template slot-scope="scope">
                        {{ paymentType[scope.row.type] }}
                    </template>
                </el-table-column>
                <el-table-column prop="code" label="产品编号" min-width="80" align="center"></el-table-column>
                <el-table-column prop="name" label="产品名称" min-width="80" align="center"></el-table-column>
                <el-table-column prop="price" label="支付价格" min-width="80" align="center"></el-table-column>
                <el-table-column prop="originalPrice" label="原价" min-width="80" align="center"></el-table-column>
                <el-table-column prop="platform" label="支付平台" min-width="80" align="center"></el-table-column>
                <el-table-column prop="" label="" min-width="80" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="openRenew(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div>
            <div class="title-row">
                <div class="table-title">按月支付</div>
                <div class="btns">
                    <el-button type="primary" size="mini" @click="openPayment()">新增选项</el-button>
                </div>
            </div>
            <el-table :data="paymentList" border :cell-style="{ padding: '0' }"
                :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
                :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
                <el-table-column prop="type" label="支付类型" min-width="80" align="center">
                    <template slot-scope="scope">
                        {{ paymentType[scope.row.type] }}
                    </template>
                </el-table-column>
                <el-table-column prop="period" label="有效期(月)" min-width="80" align="center"></el-table-column>
                <el-table-column prop="code" label="产品编号" min-width="80" align="center"></el-table-column>
                <el-table-column prop="name" label="产品名称" min-width="80" align="center"></el-table-column>
                <el-table-column prop="price" label="支付价格" min-width="80" align="center"></el-table-column>
                <el-table-column prop="content" label="促销内容" min-width="80" align="center"></el-table-column>
                <el-table-column prop="platform" label="支付平台" min-width="80" align="center"></el-table-column>
                <el-table-column prop="" label="" min-width="80" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="openPayment(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <el-dialog width="600px" title="自动续费" :visible.sync="renewFormVisible" :close-on-click-modal="false">
            <el-form ref="renewForm" :model="renewForm" label-width="80px" :rules="renewFormRules">
                <el-form-item prop="name" label="产品名称">
                    <el-input v-model="renewForm.name" placeholder="请输入产品名称"></el-input>
                </el-form-item>
                <el-form-item prop="price" label="支付价格">
                    <el-input v-model="renewForm.price" type="number" placeholder="请输入支付价格"></el-input>
                </el-form-item>
                <el-form-item prop="originalPrice" label="原价">
                    <el-input v-model="renewForm.originalPrice" type="number" placeholder="请输入原价"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
				<el-button @click="renewFormVisible = false">取 消</el-button>
				<el-button type="primary" :loading="renewFormLoading" @click="renewConfirm">确 定</el-button>
			</div>
        </el-dialog>

        <el-dialog width="600px" title="按月支付" :visible.sync="paymentFormVisible" :close-on-click-modal="false">
            <el-form ref="paymentForm" :model="paymentForm" label-width="100px" :rules="paymentFormRules" style="text-align: left;">
                <el-form-item label="支付类型">
                    {{ paymentType[paymentForm.type] }}
                </el-form-item>
                <el-form-item prop="code" label="产品编号">
                    <el-input v-model="paymentForm.code" placeholder="请输入产品编号"></el-input>
                </el-form-item>
                <el-form-item prop="period" label="有效期(月)">
                    <el-input v-model="paymentForm.period" type="number" placeholder="请输入有效期"></el-input>
                </el-form-item>
                <el-form-item prop="price" label="支付价格">
                    <el-input v-model="paymentForm.price" type="number" placeholder="请输入支付价格"></el-input>
                </el-form-item>
                <el-form-item prop="content" label="促销内容">
                    <el-input v-model="paymentForm.content" placeholder="请输入促销内容"></el-input>
                </el-form-item>
                <el-form-item prop="platform" label="支付平台">
                    <el-select v-model="paymentForm.platform">
                        <el-option label="ios" value="ios" ></el-option>
                        <el-option label="android" value="android" ></el-option>
                        <el-option label="h5" value="h5" ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="tips" style="color:red;">注：ios支付选项提交需先确认开发者是否有进行内购申请</div>
            <div slot="footer" class="dialog-footer">
				<el-button @click="paymentFormVisible = false">取 消</el-button>
				<el-button type="primary" :loading="paymentFormLoading" @click="paymentConfirm">确 定</el-button>
			</div>
        </el-dialog>
    </div>
</template>

<script>
import {
    vipPricePage,
	vipPricePost,
	vipPriceUpdate,
    vipPriceDelete
} from "@/api/api";
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            renewList: [],
            paymentList: [],
            paymentType: ['按月支付','自动续费'],

            renewForm: {},
            renewFormRules: {
                name: [
                    { required: true, message: '请输入产品名称' }
                ],
                price: [
                    { required: true, message: '请输入支付价格' }
                ],
                originalPrice: [
                    { required: true, message: '请输入原价' }
                ],
            },
            renewFormVisible: false,
            renewFormLoading: false,

            paymentForm: {},
            paymentFormRules: {
                code: [
                    { required: true, message: '请输入产品编号' }
                ],
                period: [
                    { required: true, message: '请输入有效期' }
                ],
                price: [
                    { required: true, message: '请输入支付价格' }
                ],
                content: [
                    { required: true, message: '请输入促销内容' }
                ],
                platform: [
                    { required: true, message: '请选择平台' }
                ],
            },
            paymentFormVisible: false,
            paymentFormLoading: false,
        }
    },
    methods: {
        openRenew(item) {
            const formFields = ['id','name','price','originalPrice']
            for (let field of formFields) {
                const value = item ? item[field] : ''
                this.$set(this.renewForm, field, value)
            }
            this.renewFormVisible = true
            this.$nextTick(() => {
                this.$refs.renewForm.clearValidate()
            })
        },
        async renewConfirm() {
            await new Promise((resolve) => {
                this.$refs.renewForm.validate(valid => valid && resolve())
            })

            const formData = {...this.renewForm}
            this.renewFormLoading = true
            const send = formData.id ? vipPriceUpdate : vipPricePost
            send(formData).then(response => {
                this.renewFormVisible = false
                this.renewFormLoading = false
                this.getPriceList()
            }).catch(() => {
                this.renewFormLoading = false
            })
        },
        openPayment(item) {
            const formFields = ['id','type','code', 'period', 'price','platform','content']
            for (let field of formFields) {
                const value = item ? item[field] : field == 'type' ? 0 : ''
                this.$set(this.paymentForm, field, value)
            }
            this.paymentFormVisible = true
            this.$nextTick(() => {
                this.$refs.paymentForm.clearValidate()
            })
        },
        async paymentConfirm() {
            await new Promise((resolve) => {
                this.$refs.paymentForm.validate(valid => valid && resolve())
            })

            const formData = {...this.paymentForm}
            this.paymentFormLoading = true
            const send = formData.id ? vipPriceUpdate : vipPricePost
            send(formData).then(response => {
                this.paymentFormVisible = false
                this.paymentFormLoading = false
                this.getPriceList()
            }).catch(() => {
                this.paymentFormLoading = false
            })
        },
        getPriceList() {
            vipPricePage({
				current: this.page,
				size: this.size,
			}).then((response) => {
                const renewList = []
                const paymentList = []
                for (let item of response) {
                    if (item.type == 1) {
                        renewList.push(item)
                    } else {
                        paymentList.push(item)
                    }
                }
                this.renewList = renewList
                this.paymentList = paymentList
            })
        }
    },
    created() {
        this.getPriceList()
    }
}
</script>

<style scoped>
    .title-row {
        display: flex;
        margin-bottom: 5px;
    }

    .title-row .btns {
        margin-left: 10px;
    }

    .table-title {
        font-weight: bold;
        font-size: 20px;
        text-align: left;
    }

    .title-tips {
        color:#999;margin-left: 10px;align-self: flex-end;
    }
</style>