<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
                <el-form-item label="标签内容">
					<el-input v-model="query.content" placeholder="标签内容" style="width: 155px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="标签类型">
					<el-select v-model="query.type" placeholder="标签类型" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="tag" :value="value" v-for="(tag, value) in tagType" :key="value"></el-option>
					</el-select>
				</el-form-item>
                
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-plus" @click="openTag()">新增</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="type" label="标签类型" min-width="100" align="center">
				<template slot-scope="scope">
					{{ tagType[scope.row.type] }}
				</template>
			</el-table-column>
			<el-table-column prop="content" label="标签内容" min-width="100" align="center"></el-table-column>
			<el-table-column prop="createTime" label="创建时间" min-width="100" align="center"></el-table-column>
			<el-table-column prop="number" label="征友标签使用次数" min-width="80" align="center"></el-table-column>
			<el-table-column fixed="right" label="操作" min-width="100" align="center">
				<template slot-scope="scope">
					<el-button size="mini" type="danger" @click="deleteClick(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=false layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>

        <el-dialog width="600px" title="" :visible.sync="tagFormVisible" :close-on-click-modal="false" style="text-align: left;">
            <el-form ref="tagForm" :model="tagForm" label-width="80px" :rules="tagFormRules">
                <el-form-item prop="content" label="标签内容">
                    <el-input v-model="tagForm.content" maxlength="8" placeholder="请输入标签内容(不超过8个字)"></el-input>
                </el-form-item>
                <el-form-item prop="type" label="标签类型">
                    <el-select v-model="tagForm.type" placeholder="标签类型">
						<el-option label="全部" value=""></el-option>
						<el-option :label="tag" :value="value" v-for="(tag, value) in tagType" :key="value"></el-option>
					</el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
				<el-button @click="tagFormVisible = false">取 消</el-button>
				<el-button type="primary" :loading="tagFormLoading" @click="tagConfirm">确 定</el-button>
			</div>
        </el-dialog>
    </div>
</template>

<script>
import {
    seekLabelPage,
	seekLabelPost,
	seekLabelDelete
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				content: '',
				type: ''
            },

			tagType: ['我期望','我拒绝','我的优势'],

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			powerList: [],
			lastPayTime: [],
			expireTime: [],


            tagForm: {},
            tagFormRules: {
                content: [
                    { required: true, message: '请输入标签内容' }
                ],
                type: [
                    { required: true, message: '请选择标签类型' }
                ],
            },
            tagFormVisible: false,
            tagFormLoading: false,
        }
    },
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getTagList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.lastPayTime = []
			this.expireTime = []

			this.page = 1
			this.getTagList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getTagList()
		},
		changeClick(page) {
			this.page = page
			this.getTagList()
		},
		//获取征友标签列表接口
		getTagList() {
			seekLabelPage({
				"current": this.page,
				"size": this.size,
				"content": this.query.content,
				"type": this.query.type
			}).then((response) => {
				this.total = response.total
				this.tableData = response.records
			})
		},
        openTag(item) {
            const formFields = ['id','content','type']
            for (let field of formFields) {
                const value = item ? item[field] : ''
                this.$set(this.tagForm, field, value)
            }
            this.tagFormVisible = true
            this.$nextTick(() => {
                this.$refs.tagForm.clearValidate()
            })
        },
        async tagConfirm() {
            await new Promise((resolve) => {
                this.$refs.tagForm.validate(valid => valid && resolve())
            })

            const formData = {...this.tagForm}
            this.tagFormLoading = true
            // const send = formData.id ? seekLabelUpdate : seekLabelPost
            const send = seekLabelPost
            send(formData).then(response => {
                this.tagFormVisible = false
                this.tagFormLoading = false
                this.getTagList()
            }).catch(() => {
                this.tagFormLoading = false
            })
        },
		deleteClick(id) {
			this.$confirm('确定要删除此标签?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
                closeOnClickModal: false
			}).then(() => {
				const params = new URLSearchParams()
			    params.append("idList", [id])
				seekLabelDelete({params}).then(() => {
					this.getTagList()
				})
			})
		}
	},
	created() {
		//获取征友标签列表
		this.getTagList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>

</style>