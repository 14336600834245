<template>
    <div>
        <div style="height: 100%; text-align:left; vertical-align:middle;">
			<el-form :inline="true" :model="query" class="demo-form-inline">
				<el-form-item label="">
					<el-date-picker v-model="selectDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" :value-format="'yyyy-MM-ddTHH:mm:ss'" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="platform" label="平台" min-width="150" align="center"></el-table-column>
            <el-table-column prop="channel" label="渠道" min-width="150" align="center"></el-table-column>
            <el-table-column prop="wenNumber" label="访问" min-width="150" align="center"></el-table-column>
            <el-table-column prop="downNumber" label="下载" min-width="150" align="center"></el-table-column>
            <el-table-column prop="registerNumber" label="注册" min-width="150" align="center"></el-table-column>
        </el-table>
    </div>
</template>

<script>
import {
    channelList
} from "@/api/api";
export default {
    data() {
        return {
            selectDate: '',
            dataList: [],
            formLabelWidth: "80px",

            pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
        };
    },
    methods: {
        search() {
            this.getChannelList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.getChannelList();
        },
        //获取产品分页列表
        getChannelList() {
            channelList({
                "startDate": this.selectDate != null ? this.selectDate[0] : null,
				"endDate": this.selectDate != null ? this.selectDate[1] : null
            })
            .then(response => {
                this.dataList = response;
            })
        },
    },
    mounted() {
        this.getChannelList();
    }
};
</script>