<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="活动">
                    <el-select v-model="query.activityCode" placeholder="请选择活动">
                        <el-option :label="item.name" :value="item.value" v-for="item in activityList" :key="item.code"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="query.type" placeholder="请选择类型">
                        <el-option :label="txt" :value="index" v-for="(txt,index) in typeAry" :key="index"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="query.status" placeholder="请选择状态">
                        <el-option label="正常" value="0"></el-option>
						<el-option label="禁用" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
                    <el-button type="success" @click="addClick">添加</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="activityCode" label="活动编号" min-width="80" align="center"></el-table-column>
            <el-table-column prop="type" label="类型" min-width="80" align="center">
                <template slot-scope="scope">{{ typeAry[scope.row.type] }}</template>
            </el-table-column>
            <el-table-column prop="category" label="分类" min-width="80" align="center"></el-table-column>
            <el-table-column prop="standard" label="审核标准" min-width="120" align="center"></el-table-column>
            <el-table-column prop="money" label="奖励金额" min-width="80" align="center"></el-table-column>
            <el-table-column prop="remark" label="审核说明" min-width="120" align="center"></el-table-column>
            
            <el-table-column prop="status" label="状态" min-width="80" align="center">
				<template slot-scope="scope">
                    <span v-if="scope.row.status=== 0" style="color: #67C23A">正常</span>
					<span v-else-if="scope.row.status=== 1" style="color: #F56C6C">禁用</span>
				</template>
			</el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="140" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" min-width="180" align="center">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">编辑</el-button>
                    <el-button type="warning" size="mini" icon="el-icon-edit" @click="disabledClick(scope.row)" v-if="scope.row.status == 0">禁用</el-button>
                    <el-button type="success" size="mini" icon="el-icon-edit" @click="disabledClick(scope.row)" v-else-if="scope.row.status == 1">启用</el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="flex-shrink" style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog :title="ruleForm.id ? '编辑' : '添加'" :visible.sync="dialogVisible" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form :model="ruleForm" ref="ruleForm">
				<el-form-item label="活动" :label-width="formLabelWidth">
					<el-select v-model="ruleForm.activityCode" placeholder="请选择活动">
                        <el-option :label="item.name" :value="item.value" v-for="item in activityList" :key="item.code"></el-option>
                    </el-select>
				</el-form-item>
                <el-form-item label="类型" :label-width="formLabelWidth">
                    <el-radio-group v-model="ruleForm.type">
                        <el-radio :label="index" v-for="(txt,index) in typeAry" :key="index">{{txt}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="分类" :label-width="formLabelWidth" v-if="ruleForm.type == 0">
                    <el-checkbox-group v-model="ruleForm.category" @change="changeCategory">
                        <el-checkbox label="文字"></el-checkbox>
                        <el-checkbox label="视频"></el-checkbox>
                        <el-checkbox label="图片"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="审核标准" :label-width="formLabelWidth">
                    <el-input v-model="ruleForm.standard" placeholder="请输入审核标准 建议15字以内"></el-input>
                </el-form-item>
                <el-form-item label="奖励金额" :label-width="formLabelWidth">
                    <el-input v-model="ruleForm.money" type="number"  placeholder="请填写奖励金 不奖励的填写0">
                        <template slot="append">元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="审核说明" :label-width="formLabelWidth">
                    <el-input v-model="ruleForm.remark" type="textarea" :rows="6" placeholder="请输入审核说明"></el-input>
                </el-form-item>
                <el-form-item label="状态" :label-width="formLabelWidth">
					<el-select v-model="ruleForm.status" placeholder="请选择状态">
						<el-option label="正常" :value="0"></el-option>
						<el-option label="禁用" :value="1"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="submitUpdate">确 定</el-button>
			</span>
		</el-dialog>
    </div>
</template>

<script>
import {
    dict,
    activityRulePage,
    activityRuleSave,
    activityRuleUpdate,
    activityRuleDelete,
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                status: ''
            },
            activityList: [],
            typeAry: ['动态','评论','回复'],
            formLabelWidth: "80px",
            formUpdateWidth: "150px",

            dialogVisible: false,
            ruleForm: {
                category: []
            },
        }
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1
            this.getActivityRuleList()
        },
        refresh() {
            this.page = 1
            this.query = {}
            this.getActivityRuleList()
        },
         //点击分页事件
         changeClick(page) {
            this.page = page;
            this.getActivityRuleList();
        },
        //分页获取列表
        getActivityRuleList() {
            const query = {
                current: this.page,
                size: this.size,
                ...this.query
            }
            activityRulePage(query)
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                })
        },
        // 获取活动列表
        getActivityList() {
            dict({parentCode: 'ACTIVITY_CODE'}).then(res => {
                this.activityList = res.records || []
            })
        },
        clearForm() {
            delete this.ruleForm.id
            Object.keys(this.ruleForm).forEach(key => {
                if (key == 'category') {
                    this.ruleForm[key] = []
                } else {
                    this.ruleForm[key] = ''
                }
            })
        },
		addClick() {
			this.dialogVisible = true
            if (this.ruleForm.id) {
                this.clearForm()
            }
            this.$nextTick(() => {
                this.$refs.ruleForm.resetFields()
            })
		},
        updateClick(row) {
            this.clearForm()
            this.dialogVisible = true
            this.$nextTick(() => {
                Object.keys(row).forEach(key => {
                    if (key == 'category') {
                        this.$set(this.ruleForm, key, row[key].split('+'))
                    } else {
                        this.$set(this.ruleForm, key, row[key])
                    }
                })
            })
        },
        disabledClick(row) {
            const status = row.status == 0 ? 1 : 0
            activityRuleUpdate({
                id: row.id,
                status
            }).then(() => {
                this.getActivityRuleList();
            })
        },
        deleteClick(id) {
            this.$confirm('确定要删除此奖励标准?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
                const params = new URLSearchParams()
			    params.append("idList", [id])
				activityRuleDelete({ params }).then(() => {
                    this.getActivityRuleList();
                })
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
        },
        changeCategory(value) {
            const ary = ['视频','图片']     // 视频和图片不能同时勾选
            if (ary.every(v => value.includes(v))) {
                const index = value.findIndex(v => ary.includes(v))
                value.splice(index, 1)
            }

            const sortAry = ['文字','图片','视频']  // 设置排序数组
            value.sort((a,b) => {
                const aIndex = sortAry.findIndex(sortTxt => sortTxt == a)
                const bIndex = sortAry.findIndex(sortTxt => sortTxt == b)
                return aIndex - bIndex
            })
            console.log(value,'change')
        },
        //编辑提交
        submitUpdate() {
            const formData = {}
            Object.keys(this.ruleForm).forEach(key => {
                if (key == 'category') {
                    if (this.ruleForm.type == 1) {  // 类型为评论时，不需要传输分类内容
                        formData[key] = ''
                    } else {
                        formData[key] = this.ruleForm[key].join('+')
                    }
                } else {
                    formData[key] = this.ruleForm[key]
                }
            })
            console.log(formData, this.ruleForm,'formData')
            // return;
            const fn = formData.id ? activityRuleUpdate : activityRuleSave
            fn(formData).then(() => {
                this.dialogVisible = false
                this.getActivityRuleList();
            })
        },
    },
    mounted() {
        this.getActivityList();
        this.getActivityRuleList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
}
</script>

<style>
</style>