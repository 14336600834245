<template>
    <div>

        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="nickName" label="发布者" min-width="80" align="center"></el-table-column>
            <el-table-column prop="content" label="回复内容" min-width="150" align="center"></el-table-column>
            <el-table-column prop="commentContent" label="评论内容" min-width="150" align="center"></el-table-column>
            <el-table-column prop="articleContent" label="动态内容" min-width="200" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="80" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" min-width="100" align="center">
                <template slot-scope="scope">
                    <el-button type="success" size="mini" icon="el-icon-edit" @click="agreeClick(scope.row.id)">同意
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import {
    replyPage,
    replyAgree,
    replyDelete
} from "@/api/api";
export default {
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            dataList: [],
            formLabelWidth: "80px",
        };
    },
    methods: {
        search() {
            this.getReplyList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getReplyList();
        },
        //获取待审核评论分页列表
        getReplyList() {
            replyPage({
                current: this.page,
                size: this.size,
            })
            .then(response => {
                this.dataList = response.records;
            })
        },
        //同意
        agreeClick(id) {
            replyAgree(id)
            .then(response => {
                this.getReplyList();
            })
        },
        //删除评论事件
        deleteClick(id) {
            this.$confirm('确定要删除此回复?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteComment(id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
        },
        deleteComment(id) {
            let params = new URLSearchParams()
			params.append("idList", [id])
			replyDelete({params})
				.then(() => {
					window.location.reload() //刷新页面
				})
        }
    },
    mounted() {
        this.getReplyList();
    }
};
</script>