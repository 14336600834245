<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<div class="title-row">
                <div class="table-title">所有用户默认比例：</div>
            </div>
			<div style="display: flex;">
				<el-form :model="query" class="demo-form-inline" size="mini" label-width="200px" label-position="left">
					<el-form-item :label="item.label" v-for="item in commonData" :key="commonData.field">
						<div style="display: flex;">
							<el-input :value="item.value" readonly style="width: 80px">
								<template>
									<span slot="suffix" v-if="item.suffix">{{item.suffix}}</span>
								</template>
							</el-input>
		
							<span class="edit-icon" @click="editPrompt(item.field)" v-if="commonId">
								<i class="el-icon-edit"></i>
							</span>
						</div>
					</el-form-item>
				</el-form>
				<div style="color: red;margin-left: 40px;">
					注：1.被邀请者首次开通贵族，邀请者可同时获得支付反佣及现金奖励。2.用户获得的打赏可进行现金提现，故k币充值反佣比例设置不得高于50%
				</div>
			</div>
            <div class="title-row">
                <div class="table-title">指定ID配置：</div>
                <div class="btns">
                    <el-button type="primary" size="mini" @click="openForm()">新增</el-button>
                </div>
            </div>
		</div>
        <div>
            <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border :cell-style="{ padding: '0' }"
                :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
                :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
                <el-table-column prop="type" label="用户ID" min-width="80" align="center">
                    <template slot-scope="scope">
						<a :href="'/user/' + scope.row.userId" target="_blank"  >{{ scope.row.userNumber }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="remark" label="备注" min-width="80" align="center"></el-table-column>
                <el-table-column prop="coinRatio" label="充值K币(%)" min-width="80" align="center"></el-table-column>
                <el-table-column prop="vipRatio" label="开通贵族(%)" min-width="80" align="center"></el-table-column>
                <el-table-column prop="vipFirst" label="首次开通贵族(元)" min-width="80" align="center"></el-table-column>
                <el-table-column prop="" label="操作" min-width="80" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="openForm(scope.row)">修改</el-button>
                        <el-button type="danger" size="mini" @click="deleteItem(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align:right;">
                <el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
                    :page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
                </el-pagination>
            </div>
        </div>

		<el-dialog width="600px" :title="rewardForm.id ? '修改' : '新增'" :visible.sync="rewardFormVisible" :close-on-click-modal="false">
            <el-form ref="rewardForm" :model="rewardForm" label-width="180px" :rules="rewardFormRules">
                <el-form-item prop="userNumber" label="用户ID">
                    <el-input v-model="rewardForm.userNumber" type="number" placeholder="请输入用户ID" :disabled="!!rewardForm.id"></el-input>
                </el-form-item>
                <el-form-item prop="coinRatio" label="充值K币 分成比例">
                    <el-input v-model="rewardForm.coinRatio" type="number" placeholder="请输入充值K币 分成比例">
						<template>
							<span slot="suffix">%</span>
						</template>
					</el-input>
                </el-form-item>
                <el-form-item prop="vipRatio" label="开通贵族 分成比例">
                    <el-input v-model="rewardForm.vipRatio" type="number" placeholder="请输入开通贵族 分成比例">
						<template>
							<span slot="suffix">%</span>
						</template>
					</el-input>
                </el-form-item>
                <el-form-item prop="vipFirst" label="首充开通贵族 提成金额">
                    <el-input v-model="rewardForm.vipFirst" type="number" placeholder="请输入首充开通贵族 提成金额">
						<template>
							<span slot="suffix">元</span>
						</template>
					</el-input>
                </el-form-item>
                <el-form-item prop="remark" label="备注">
                    <el-input v-model="rewardForm.remark" placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
				<el-button @click="rewardFormVisible = false">取 消</el-button>
				<el-button type="primary" :loading="rewardForm.id ? rewardFormLoading : searchUserLoading" @click="checkUserInfo">确 定</el-button>
			</div>
        </el-dialog>
    </div>
</template>

<script>
import {
	shareConfigPage,
	shareConfigPost,
	shareConfigUpdate,
	shareConfigDelete,
	shareConfigCommon,
	shareConfigCommonUpdate,
	userInfoFindByNumber
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				shareNickName: '',
				shareUserNumber: '',
				type: '',
            },
			
			/* coinRatio: undefined,
			vipFirst: undefined,
			vipRatio: undefined, */

			commonId: undefined,
			commonData: [
				{ field: 'coinRatio', value: undefined, label: '充值K币 分成比例', suffix: '%' },
				{ field: 'vipRatio', value: undefined, label: '开通或续费贵族 分成比例', suffix: '%' },
				{ field: 'vipFirst', value: undefined, label: '首次开通贵族 奖励金额', suffix: '元' },
			],

			rewardForm: {},
            rewardFormRules: {
				userNumber: [
					{ required: true, message: '请输入用户ID' }
				],
                coinRatio: [
                    { required: true, message: '请输入充值K币 分成比例' }
                ],
                vipRatio: [
                    { required: true,  message: '请输入开通贵族 分成比例' }
                ],
                vipFirst: [
                    { required: true, message: '请输入首充开通贵族 提成金额' }
                ],
            },
            rewardFormVisible: false,
            rewardFormLoading: false,
			searchUserLoading: false,

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

			searchDate: [],
        }
    },
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getRewardRatioList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.searchDate = []

			this.page = 1
			this.getRewardRatioList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getRewardRatioList()
		},
		changeClick(page) {
			this.page = page
			this.getRewardRatioList()
		},
		
		getRewardRatioList() {
            const params = {
				"current": this.page,
				"size": this.size,
			}

			shareConfigPage(params)
				.then((response) => {
					// this.total = response.total
					// this.tableData = response.records
					this.tableData = response
				})
		},

		getCommonData() {
			console.log('getCommonData')
			shareConfigCommon().then((response) => {
				console.log(response,'response')

				this.commonId = response.id
				this.commonData.forEach(item => {
					item.value = response[item.field]
				})
				/* this.coinRatio = response.coinRatio
				this.vipFirst = response.vipFirst
				this.vipRatio = response.vipRatio */
			})
		},

		editPrompt(field) {
			
			const item = this.commonData.find(item => item.field == field)
			this.$prompt(item.label, '修改默认配置', {
				inputValue: item.value,
				closeOnClickModal: false,
				beforeClose: async (action, instance, done) => {
					if (action === 'confirm') {
						console.log(instance,'instance')
						const value = instance.inputValue
						let number = value != null ? parseInt(value) : undefined
						if (isNaN(number)) {
							this.$message.error('请输入整型数值！')
							return;
						}
						
						const formData = {id: this.commonId}
						formData[field] = number
						console.log(formData,'formData')

						instance.confirmButtonLoading = true;
						try {
							await shareConfigUpdate(formData)
							this.getCommonData()
							done()
						} catch {}

						setTimeout(() => {
							instance.confirmButtonLoading = false;
						}, 400)
					} else {
						done()
					}
				}
			})
		},

		openForm(item) {
			const formFields = ['id', 'userNumber','coinRatio','vipRatio', 'vipFirst', 'remark']
            for (let field of formFields) {
                const value = item ? item[field] : ''
                this.$set(this.rewardForm, field, value)
            }
            this.rewardFormVisible = true
            this.$nextTick(() => {
                this.$refs.rewardForm.clearValidate()
            })
		},

		async checkUserInfo() {
			await new Promise((resolve) => {
                this.$refs.rewardForm.validate(valid => valid && resolve())
            })

			// 添加的时候进行一次用户信息确认
			if (!this.rewardForm.id) {
				this.searchUserLoading = true
				console.log(1)
				try {
					const res = await userInfoFindByNumber({userNumber: this.rewardForm.userNumber})
					const h = this.$createElement
					this.$msgbox({
						title: '请确认用户信息是否正确',
						message: h('div', null, [
							h('div', null, ['用户昵称: ', h('span', {style: 'color: #409eff;'}, res.nickName)]),
							h('div', null, ['用户ID: ', h('span', {style: 'color: #409eff;'}, res.userNumber)]),
						]),
						showCancelButton: true,
						closeOnClickModal: false,
						beforeClose: async (action, instance, done) => {
							if (action === 'confirm') {
								instance.confirmButtonLoading = true;
								// 提交数据
								await this.sendData()
								done()
								setTimeout(() => {
									instance.confirmButtonLoading = false;
								}, 400)
							} else {
								done()
							}
						}
					})
				} catch {}
				this.searchUserLoading = false
			} else {
				// 编辑时不进行用户信息确认直接提交数据
				console.log(2)
				this.sendData()
			}
		},

		async sendData() {
			const formData = {...this.rewardForm}
			if (formData.id) delete formData.userNumber
            this.rewardFormLoading = true
            const send = formData.id ? shareConfigUpdate : shareConfigPost

			return new Promise(async (resolve, reject) => {
				try {
					await send(formData)
					this.rewardFormVisible = false
					this.rewardFormLoading = false
					this.getRewardRatioList()
					resolve()
				} catch {
					reject()
				}
			})
		},

		async deleteItem(item) {
			await new Promise((resolve) => {
				this.$confirm(`备注: ${item.remark}; 确认要删除该用户配置吗？`,'提示', {
					type: 'warning'
				}).then(() => {
					resolve()
				})
			})

			const params = new URLSearchParams()
			params.append("idList", [item.id])
			shareConfigDelete({ params }).then(() => {
				this.getRewardRatioList()
			})
		}
	},
	created() {
		this.getRewardRatioList()
		this.getCommonData()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
	.edit-icon {
		font-size: 18px;
		margin-left: 10px;
		vertical-align: middle;
		cursor: pointer;
	}

	.title-row {
        display: flex;
        margin-bottom: 5px;
    }

    .title-row .btns {
        margin-left: 10px;
    }

    .table-title {
        font-weight: bold;
        font-size: 20px;
        text-align: left;
    }

    .title-tips {
        color:#999;margin-left: 10px;align-self: flex-end;
    }
</style>