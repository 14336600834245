<template>
    <div class="chat-wrap" v-if="sessionUser">
        <div class="chat-header">
            <div class="avatar" @click="showUserCard">
                <avatar :src="userInfo.avatar" />
            </div>
            <div class="title">{{userInfo.nick}}</div>
            <!-- <div class="subtitle">(离线)</div> -->
        </div>
        <div id="chat-content" class="chat-content" @scroll="scrollHandler">
            <div class="chat-message-tip" v-if="msgComplete">没有更多消息了</div>
            <div class="chat-message-loading" v-if="msgFetching">
                <i class="el-icon-loading"></i>
            </div>
            <div class="chat-message-content">
                <template v-for="msgItem in msgList">
                    <div class="chat-message-item-recall" v-if="(msgItem && msgItem.type && msgItem.type == 'system') && msgItem.status == 'recall'">
                        {{ (msgItem.from !== myUserInfo.account ? msgItem.fromNick + ' ' : '你') + '撤回了一条消息' }}
                        <span class="chat-message-item-reedit" @click="writeInput(msgItem)" v-if="msgItem.from === myUserInfo.account && msgItem.text">重新编辑</span>
                    </div>
                    <message :item="msgItem" v-else />
                </template>
            </div>
            <div class="chat-message-tobottom" @click="toBottom" v-if="receiveMsgBtnVisible">
                <span>您收到了新消息</span>
                <i class="el-icon-bottom"></i>
            </div>
        </div>
        <message-input />

        <user-card ref="userCard" :userInfo="userInfo" />
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import avatar from '../common/avatar'
import userCard from '../common/userCard'
import message from './message'
import messageInput from './input'
export default {
    components: {
        avatar,
        userCard,
        message,
        messageInput
    },
    data() {
        return {
            lastScrollTop: undefined
        }
    },
    computed: {
        ...mapGetters('nim', ['nim', 'receiveMsgBtnVisible', 'myUserInfo', 'userInfos', 'sessionUser', 'sessionMsgs']),
        userInfo() {
            return this.userInfos[this.sessionUser.to]
        },
        sessionId() {
            return `p2p-${this.sessionUser.to}`
        },
        msgs() {
            return this.sessionMsgs[this.sessionId]
        },
        msgComplete() {
            return this.msgs ? this.msgs.complete : false
        },
        msgFetching() {
            return this.msgs ? this.msgs.fetching : false
        },
        msgList() {
            return this.msgs ? this.msgs.msgList : []
        }
    },
    methods: {
        ...mapMutations('nim', ['addHook', 'interMessageItem', 'setReceiveMsgBtnVisible']),
        ...mapActions('nim', ['runHook', 'loadMoreMsgOfSession', 'sendMsgReceipt']),

        scrollToBottom(active = false) {        // active：固定执行贴底
            this.$nextTick(() => {
                const chatBox = document.body.getElementsByClassName('chat-content')[0]
                if (!chatBox) return;

                // console.log(chatBox.scrollTop, chatBox.scrollHeight, chatBox.clientHeight,'chatBox')

                // 判断当前滚动条位置，是否执行贴底
                if (!active && chatBox.scrollHeight - chatBox.scrollTop > chatBox.clientHeight + 200) {
                    this.setReceiveMsgBtnVisible(true)
                    return;
                }

                setTimeout(() => {
                    this.setReceiveMsgBtnVisible(false)
                    chatBox.scrollTop = chatBox.scrollHeight
                }, 200)
            })
        },
        
        scrollHandler(e) {
            const scrollTop = e.target.scrollTop
            const scrollHeight = e.target.scrollHeight
            const clientHeight = e.target.clientHeight

            // 判断向上滚动并且即将到顶时执行获取历史消息
            if ( this.lastScrollTop && this.lastScrollTop > scrollTop && scrollTop <= 0 && !this.msgComplete) {
                const lastMsg = this.msgList[0]
                // console.log(scrollTop, this.lastScrollTop, 'scroll loadMoreMsgOfSession')
                this.loadMoreMsgOfSession(this.sessionUser.to).then(() => {
                    if (lastMsg) {
                        // 插入消息数据之后调整滚动条位置
                        document.getElementById(lastMsg.idClient)?.scrollIntoView()
                    }
                })
            } else if (scrollTop + clientHeight >= scrollHeight - 50) {
                this.setReceiveMsgBtnVisible(false)
            }

            this.lastScrollTop = scrollTop
        },

        observerMessage(msgList = []) {
            const msgs = msgList.length ? msgList : this.msgList
            // console.log(msgs,'msgs')
            const notMyMsgs = msgs
            .filter(item => item.from !== this.myUserInfo.account)
            .filter(item => !!item.idServer)
            .filter(item => ['notification', 'tip', 'robot', 'g2'].every((j) => j !== item.type))

            // const messages = document.getElementsByClassName('chat-message-item')
            // console.log(messages,'messages')
            if (!notMyMsgs.length) return;

            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entrie => {
                    if (document.visibilityState == 'visible' && entrie.intersectionRatio > 0) {
                        // console.log('visible', item.target)
                        const msg = notMyMsgs.find(item => item.idClient === entrie.target.id)
                        this.sendMsgReceipt(msg).then(() => observer.unobserve(entrie.target))
                    }
                })
                // console.log(entries,'entries')
            }, { root: document.getElementById('chat-content') })
            
            for (let item of notMyMsgs) {
                const target = document.getElementById(item.idClient)
                // console.log(target,'target')
                if (target) observer.observe(target)
            }

        },

        toBottom() {
            this.scrollToBottom(true)
        },

        // 写入输入框(重新编辑)
        writeInput(msg) {
            console.log(msg,'writeInput')
            this.runHook({hookName: 'writeInput', params: msg.text})
        },

        showUserCard() {
            this.$refs.userCard.open()
        }
    },
    created() {
        this.addHook({
            hookName: 'changeSession',
            funcName: 'initMessage',
            func: () => {
                this.$nextTick(async () => {
                    if (!this.msgs || (this.msgList.length < 10 && !this.msgComplete && !this.msgFetching)) {
                        // console.log(this.sessionUser,'this.sessionUser')
                        await this.loadMoreMsgOfSession(this.sessionUser.to)
                    }

                    this.scrollToBottom(true)

                    this.observerMessage()
                })
            }
        })

        this.addHook({
            hookName: 'sendMsg',
            funcName: 'interMessage',
            func: (e) => {
                this.scrollToBottom(true)
            }
        })

        this.addHook({
            hookName: 'msg',
            funcName: 'receiveMsg',
            func: (e) => {
                this.$nextTick(() => {
                    if (this.sessionUser && e.from == this.sessionUser.to) {
                        this.scrollToBottom()

                        this.observerMessage([e])
                    }
                })
            }
        })

    }
}
</script>

<style scoped>
.chat-wrap {
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.chat-header {
    height: 60px;
    font-size: 16px;
    padding: 0 16px;
    border-bottom: 1px solid #dbe0e8;
    display: flex;
    align-items: center;
}

.chat-header .avatar {
    margin-right: 12px;
    cursor: pointer;
}

.chat-header .title {
    max-width: 70%;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-header .subtitle {
    color: #999;
}

.chat-content {
    padding: 16px;
    flex-grow: 1;
    overflow-y: auto;
    position: static;
}

.chat-message-tip {
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-message-loading {
    font-size: 20px;
    color: #999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-message-tobottom {
    width: fit-content;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 18px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 90px;
    left: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
}

.chat-message-tobottom:hover {
    color: #fff;
    background-color: #d6e5f6;
}

.chat-message-tobottom span {
    margin-right: 5px;
}

.chat-message-tobottom i {
    margin-right: 5px;
}

.chat-message-item-recall {
    line-height: 16px;
    font-size: 14px;
    color: #999;
    padding: 10px 0;
}

.chat-message-item-reedit {
    color: #1890ff;
    margin-left: 6px;
    cursor: pointer;
    user-select: none;
}

</style>