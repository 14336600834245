<template>
    <div class="search-dialog">
        <el-dialog :visible.sync="visible" width="630px" :show-close="false">
            <div class="search-dialog-header" slot="title">
                <el-input
                    ref="input"
                    placeholder="搜索用户"
                    prefix-icon="el-icon-search"
                    v-model="search"
                    @input="searchUser"
                    >
                </el-input>
                <div class="close" @click="closeDialog">
                    <i class="el-icon-close"></i>
                </div>
            </div>
            <div class="search-dialog-body">
                <div class="content-title">用户</div>
                <div class="empty" v-if="searchData === null">未搜索到用户</div>
                <template v-if="searchData">
                    <div class="content-item" @click="tapItem">
                        <div class="avatar">
                            <avatar :src="searchData.avatar || getDefaultAvatar(searchData.gender)" />
                        </div>
                        <div class="name">{{ searchData.nick }}</div>
                    </div>
                </template>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import avatar from './avatar.vue'
import { getDefaultAvatar } from '@/utils'
export default {
    components: {
        avatar
    },
    data() {
        return {
            visible: false,
            search: '',
            searchTimer: undefined,
            searchData: undefined
        }
    },
    computed: {
        ...mapGetters('nim', ['nim'])
    },
    watch: {
        visible(val) {
            if (val) {
                this.search = ''
                this.$nextTick(() => {
                    this.$refs.input.focus()
                })
            }
        }
    },
    methods: {
        ...mapActions('nim', ['setCurrentUser', 'addSession']),
        searchUser() {
            clearTimeout(this.searchTimer)
            this.searchData = undefined
            if (!this.search) return;
            this.searchTimer = setTimeout(() => {
                this.nimSearch()
            }, 500)
        },
        nimSearch() {
            const account = this.search.trim()
            this.nim.getUser({
                account,
                sync: true,
                done: (err, data) => {
                    this.searchData = data
                    console.log(data,'data')
                }
            })
        },
        async tapItem() {
            /* const isMyFriend = await new Promise((resolve,reject) => {
                this.nim.isMyFriend({
                    account: this.searchData.account,
                    done: ((err, data) => {
                        console.log(err,data,'isMyFriend')
                        resolve(data)
                    })
                })
            })

            if (!isMyFriend) {
                await new Promise((resolve) => {
                    this.nim.addFriend({
                        account: this.searchData.account,
                        done: () => {
                            resolve()
                        }
                    })
                })
            } */

            this.addSession(this.searchData)
            this.setCurrentUser(this.searchData.account)

            this.closeDialog()
        },
        openDialog() {
            this.visible = true
        },
        closeDialog() {
            this.visible = false
            this.searchData = undefined
        },
        getDefaultAvatar(gender) {
            return getDefaultAvatar(gender)
        }
    }
}
</script>

<style scoped>
.search-dialog >>> .el-dialog__header, .search-dialog >>> .el-dialog__body {
    padding: 0;
}

.search-dialog-header {
    padding: 16px 46px 16px 16px;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    z-index: 1;
}

.search-dialog-header .close {
    width: 46px;
    font-size: 26px;
    color: #999;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.search-dialog-body {
    padding: 24px;
    text-align: left;
}

.search-dialog-body .content-title {
    color: #dbe0e8;
    border-bottom: 1px solid #dbe0e8;
    margin-bottom: 8px;
}

.search-dialog-body .content-item {
    padding: 8px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.search-dialog-body .content-item:hover {
    background-color: #ebf3fc;
}

.search-dialog-body .content-item .avatar {
    margin-right: 12px;
}

.search-dialog-body .content-item .name {
    font-size: 14px;
}
</style>