<template>
    <div>
        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
                <el-form-item label="举报人昵称">
                    <el-input v-model="query.nickName" placeholder="请输入举报人昵称" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="举报人ID">
                    <el-input v-model="query.userNumber" placeholder="请输入举报人ID" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="被举报人昵称">
                    <el-input v-model="query.reportNickName" placeholder="请输入被举报人昵称" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="被举报人ID">
                    <el-input v-model="query.reportUserNumber" placeholder="请输入被举报人ID" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="处理状态">
                    <el-select v-model="query.status" style="width: 120px">
                        <el-option label="全部" value=""></el-option>
                        <el-option :label="label" :value="value" v-for="(label, value) in status" :key="value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column label="举报人" min-width="110" align="center">
                <template slot-scope="scope">
                    <a :href="`/user/${scope.row.userId}`" target="_blank">{{ scope.row.userNumber }}</a><br/>
                    {{ scope.row.nickName }}
                </template>
            </el-table-column>
            <el-table-column label="被举报人" min-width="110" align="center">
                <template slot-scope="scope">
                    <a :href="`/user/${scope.row.reportUserId}`" target="_blank">{{ scope.row.reportUserNumber }}</a><br/>
                    {{ scope.row.reportNickName }}
                </template>
            </el-table-column>
            <el-table-column prop="" label="举报原因" min-width="200" align="center"></el-table-column>
            <el-table-column prop="content" label="举报内容" min-width="200" align="center"></el-table-column>
            <el-table-column prop="images" label="举报图片" min-width="240" align="center">
                <template slot-scope="scope">
                    <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.images" :key="index" :src="item"
                    :preview-src-list="scope.row.images" ></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="reportNumber" label="被举报次数" min-width="130" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="150" align="center"></el-table-column>
            <el-table-column fixed="right" prop="status" label="状态" min-width="80" align="center">
                <template slot-scope="scope">
                    <el-link :type="statusColor[scope.row.status]" :underline="false">{{status[scope.row.status]}}</el-link>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="160" align="center">
                <template slot-scope="scope">
                    <template v-if="scope.row.status == 0">
                        <el-popover placement="left">
                            <el-button type="warning" size="mini" @click="banFunciton(scope.row)" >封功能</el-button>
                            <el-button type="warning" size="mini" style="margin-left: 10px;" @click="banUser(scope.row)">封禁账号</el-button>
                            <el-button type="success" size="mini" slot="reference" >处理</el-button>
                        </el-popover>
                        <el-button style="margin-left: 10px;" type="danger" size="mini" @click="updateStatus(scope.row.id, 2)">忽略</el-button>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog title="确认封禁快速匹配功能？" :visible.sync="banDialogVisible" :close-on-click-modal="false">
            <el-form ref="banFormRef" :model="banForm" label-width="180px" style="text-align: left" v-if="actionRow">
                <el-form-item label="被举报人">
                    {{ actionRow.reportNickName }}({{ actionRow.reportUserNumber }})
                </el-form-item>
                <el-form-item label="历史被封禁次数">
                    {{ banForm.banNumber }}
                </el-form-item>
                <el-form-item label="填写本次封禁的小时数" prop="timeNumber" :rules="[ { required: true, message: '请输入封禁的小时数！' } ]">
                    <el-input type="number" v-model="banForm.timeNumber" placeholder="请输入整数" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
				<el-button @click="closeBanDialog">取 消</el-button>
				<el-button type="primary"  @click="banFunctionConfirm">确 定</el-button>
			</div>
        </el-dialog>

        <delete-dialog ref="deleteDialog" title="封禁用户" :type="0" :showRemark="true" @confirm="banUserConfirm"></delete-dialog>
    </div>
</template>

<script>
import {
    reportPage,
    reportDelete,
    reportUpdate,
    findMatchBanInfo,
    matchBan,
    userUpdate
} from "@/api/api";
import deleteDialog from '@/components/deleteDialog'
export default {
    components: {
        deleteDialog
    },
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            dataList: [],
            query: {
                nickName: "",
                userNumber: "",
                reportNickName: "",
                reportUserNumber: "",
                status: 0
            },
            status: ['待处理','已处理','已忽略'],
            statusColor: ['warning','success','danger'],
            formLabelWidth: "80px",

            actionRow: undefined,

            infoLoading: false,
            banDialogVisible: false,
            banForm: {
                banNumber: null,
                timeNumber: null
            }
        };
    },
    methods: {
        search() {
            this.page = 1;
            this.getReportList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getReportList();
        },
        //分页获取举报列表
        getReportList() {
            reportPage({
                current: this.page,
                size: this.size,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
                reportNickName: this.query.reportNickName,
                reportUserNumber: this.query.reportUserNumber,
                status: this.query.status,
                type: 2
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                    for (let i = 0; i < this.dataList.length; i++) {
                        let report = this.dataList[i];
                        if (report.image != null && report.image.length > 0) {
                            report.images = report.image.split(",")
                            for(let j = 0; j < report.images.length; j++) {
                                report.images[j] = "https://file.oiki.cc/" + report.images[j]
                            }
                        }
                        if (report.articleImage != null && report.articleImage.length > 0) {
                            report.articleImages = report.articleImage.split(",")
                            for(let j = 0; j < report.articleImages.length; j++) {
                                report.articleImages[j] = "https://file.oiki.cc/" + report.articleImages[j]
                            }
                        }
                    }
                })
        },
        banFunciton(row) {
            this.actionRow = row
            findMatchBanInfo({userId: row.reportUserId}).then(number => {
                this.banForm.banNumber = number
                this.banDialogVisible = true
            })
        },
        banFunctionConfirm() {
            this.$refs.banFormRef.validate(valid => {
                if (valid) {
                    matchBan({
                        userId: this.actionRow.reportUserId,
                        hours: this.banForm.timeNumber,
                    }).then(() => {
                        this.updateStatus(this.actionRow.id, 1)
                        this.closeBanDialog()
                    })
                }
            })
        },
        closeBanDialog() {
            this.banDialogVisible = false
            this.banForm.banNumber = null
            this.banForm.timeNumber = null
            this.actionRow = undefined
        },
        banUser(row) {
            this.actionRow = row
            this.$refs.deleteDialog.showDialog()
        },
        banUserConfirm(data) {
            const formData = {
                id: this.actionRow.reportUserId,
                status: 2,
                remark: data.content,
                customerRemark: data.remark
            }
            
            userUpdate(formData).then(() => {
                this.updateStatus(this.actionRow.id, 1)
            })
        },
        updateStatus(id, status) {
            reportUpdate({id, status}).then(() => {
                this.getReportList()
                this.actionRow = undefined
            })
        }
    },
    mounted() {
        this.getReportList();
    }
};
</script>

<style scoped>

</style>