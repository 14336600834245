<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="用户编号">
                    <el-input v-model="query.userNumber" placeholder="请输入用户编号"></el-input>
                </el-form-item>
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="query.status" placeholder="请选择状态">
                        <el-option label="待审核" value="0"></el-option>
						<el-option label="审核通过" value="1"></el-option>
                        <el-option label="审核拒绝" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="userNumber" label="用户编号" min-width="80" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="nickName" label="用户昵称" min-width="120" align="center"></el-table-column>
            <el-table-column prop="age" label="年龄" min-width="80" align="center"></el-table-column>
            <el-table-column prop="income" label="年收入" min-width="80" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.income">{{scope.row.income}}万元</span>
				</template>
            </el-table-column>
            <el-table-column prop="expenditure" label="月开支" min-width="80" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.expenditure">{{scope.row.expenditure/10000}}万元</span>
				</template>
            </el-table-column>
            <el-table-column prop="address" label="常住地址" min-width="180" align="center"></el-table-column>
            <el-table-column prop="hope" label="期望" min-width="300" align="center"></el-table-column>
            <el-table-column prop="image" label="实力证明图片" min-width="220" align="center">
                <template slot-scope="scope">
                    <el-image style="height: 60px; width: 60px; padding: 2px;" v-for="(item, index) in scope.row.images"
                        :key="index" :src="item" :preview-src-list="scope.row.images">
                    </el-image>
                    <el-button v-if="scope.row.images && scope.row.proveStatus=== 0" type="danger" plain size="mini" @click="checkClick(scope.row.id, 1, '实力证明')">审核</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="video" label="实力证明视频" min-width="121" align="center">
                <template slot-scope="scope">
                    <video v-if="scope.row.video" muted="muted" class="video" :src="scope.row.video" type="video/mp4" autoplay="autoplay" style="width: 100px; height: 100px; padding-top: 10px;" controls="controls" loop="-1">
                        <p>你的浏览器不支持video标签.</p>
                    </video>
                    <el-button v-if="scope.row.video && scope.row.proveStatus=== 0" type="danger" plain size="mini" @click="checkClick(scope.row.id, 1, '实力证明')">审核</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="customerStatus" label="客服微信" min-width="100" align="center">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.customerStatus=== 0 || scope.row.customerStatus=== 2" type="danger" plain size="mini" @click="checkClick(scope.row.id, 2, '客服微信')">未添加</el-button>
                    <span style="color:#67C23A" v-else>已添加</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" min-width="80" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.status=== 0" style="color: #E6A23C">待审核</span>
                    <span v-else-if="scope.row.status=== 1" style="color: #67C23A">通过</span>
					<span v-else-if="scope.row.status=== 2" style="color: #F56C6C">拒绝</span>
				</template>
			</el-table-column>
            <el-table-column prop="customerRemark" label="客服备注" min-width="150" align="center"></el-table-column>
            <el-table-column prop="proveReason" label="拒绝理由" min-width="150" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="140" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" min-width="180" align="center">
                <template slot-scope="scope">
                    <!-- <el-button v-if="scope.row.status === 0 || scope.row.status === 2" type="success" size="mini" @click="agreeClick(scope.row.id)">同意
                    </el-button>
                    <el-button v-if="scope.row.status === 0 || scope.row.status === 1" type="warning" size="mini" @click="refuseClick(scope.row.id)">拒绝
                    </el-button> -->
                    <el-button type="primary" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">编辑
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog title="编辑男士" :visible.sync="updateShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form :model="updateForm">
                <el-form-item label="交友城市（上限3个）" :label-width="formUpdateWidth">
                    <el-cascader placeholder="请选择交友城市" v-model="selectOption" :options="cityTreeList"
						:props="defaultParams" @change="handleCategoryChange" filterable :show-all-levels="false">
                    </el-cascader>
				</el-form-item>
				<el-form-item label="年收入（万元）" :label-width="formUpdateWidth">
					<el-input v-model="updateForm.income" placeholder="请输男士收入" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="交友期望" :label-width="formUpdateWidth">
					<el-input type="textarea" :rows="5" v-model="updateForm.hope" placeholder="请输入男士期望" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="客服备注" :label-width="formUpdateWidth">
					<el-input type="textarea" :rows="3" v-model="updateForm.customerRemark" placeholder="请输入客服备注，客服专用，不对外展示" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="updateShow = false">取 消</el-button>
				<el-button type="primary" @click="submitUpdate">确 定</el-button>
			</span>
		</el-dialog>
    </div>
</template>

<script>
import {
    goldMasterPage,
    goldMasterUpdate,
    goldMasterVerify,
    goldMasterDelete,
    cityTree
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                userNumber: "",
                nickName: "",
                status: ""
            },
            formLabelWidth: "80px",
            formUpdateWidth: "150px",

            updateShow: false,
            updateForm: {
                id: "",
                userId: "",
                income: "",
                hope: "",
                address: "",
                customerRemark: ""
            },

            //级联选择器信息
            selectOption: [],
            cityTreeList: [],
            defaultParams: {
                label: "areaName",
                value: "areaCode",
                children: "childList",
                checkStrictly: false,
                multiple: true
            },
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getGoldMasterList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getGoldMasterList();
        },
        //分页获取列表
        getGoldMasterList() {
            goldMasterPage({
                current: this.page,
                size: this.size,
                status: this.query.status,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                    for (let i = 0; i < this.dataList.length; i++) {
                        let goldMaster = this.dataList[i];
                        if (goldMaster.image != null && goldMaster.image.length > 0) {
                            goldMaster.images = goldMaster.image.split(",")
                            for (let j = 0; j < goldMaster.images.length; j++) {
                                goldMaster.images[j] = "https://file.oiki.cc/" + goldMaster.images[j]
                            }
                        }
                        if (goldMaster.video != null && goldMaster.video.length > 0) {
                            goldMaster.video = "https://file.oiki.cc/" + goldMaster.video
                        }
                    }
                })
        },
        //编辑事件
        updateClick(row) {
            this.updateShow = true
            this.updateForm.id = row.id
            this.updateForm.userId = row.userId
            this.updateForm.income = row.income
            this.updateForm.hope = row.hope
            this.selectOption = []
            this.updateForm.address = ""
            this.updateForm.customerRemark = row.customerRemark
        },
        //编辑提交
        submitUpdate() {
            for (let i = 0; i < this.selectOption.length; i++) {
                let areaCodeList = this.selectOption[i]
                if (i == 0) {
                    this.updateForm.address = areaCodeList[1]
                } else {
                    this.updateForm.address = this.updateForm.address + "," + areaCodeList[1]
                }
            }
            goldMasterUpdate({
                id: this.updateForm.id,
                userId: this.updateForm.userId,
                income: this.updateForm.income,
                hope: this.updateForm.hope,
                address: this.updateForm.address,
                customerRemark: this.updateForm.customerRemark
            }).then(() => {
                this.getGoldMasterList();
            })
            this.updateShow = false
        },
        //单条审核
        checkClick(id, type, checkName) {
            if (type == 2) {
                this.$confirm(checkName + '审核', {
                    confirmButtonText: '拒绝',
                    cancelButtonText: '同意',
                    center: true,
                    distinguishCancelAndClose: true
                }).then(() => {
                    //点击拒绝按钮
                    this.verifyRequest(id, 2, type, null)
                }).catch((action) => {
                    //点击同意按钮
                    if (action === 'cancel') {
                        this.verifyRequest(id, 1, type, null)
                    }
                });
            } else {
                this.$prompt(checkName+'审核', {
                    confirmButtonText: '拒绝',
                    cancelButtonText: '同意',
                    inputPlaceholder: '请输入拒绝理由，拒绝时必填',
                    center: true,
                    distinguishCancelAndClose: true,
                    closeOnClickModal: false
                }).then(({ value }) => {
                    if (value == null) {
                        this.$message.error("请输入拒绝理由");
                    } else {
                        //点击拒绝按钮
                        this.verifyRequest(id, 2, type, value)
                    }
                }).catch((action) => {
                    //点击同意按钮
                    if (action === 'cancel') {
                        this.verifyRequest(id, 1, type, null)
                    }
                });
            }
        },
        // //审核同意事件
        // agreeClick(id) {
        //     this.verifyRequest(id, 1, null);
        // },
        // //审核拒绝事件
        // refuseClick(id) {
        //     this.$prompt('请输入拒绝理由', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //     }).then(({ value }) => {
        //         if (value == null) {
        //             this.$message.error("请输入拒绝理由");
        //         } else {
        //             this.verifyRequest(id, 2, value);
        //         }
        //     }).catch(() => {});
        // },

        //审核请求
        verifyRequest(id, status, type, remark) {
            goldMasterVerify({
                id: id,
                status: status,
                type: type,
                remark: remark
            }).then(response => {
                this.getGoldMasterList();
            })
        },
        //删除事件
        deleteClick(id) {
            this.$confirm('确定要删除此男士?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deleteGoldMaster(id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除',
                    center: true
                });
            });
        },
        //删除请求
        deleteGoldMaster(id) {
            goldMasterDelete(id, null)
                .then(() => {
                    window.location.reload() //刷新页面
                })
        },
        //获取地区
        getCityTree() {
            this.selectOption = []
            cityTree()
            .then(response => {
                this.cityTreeList = response
            })
        },
        handleCategoryChange(value) {
            this.selectOption = value;
        }
    },
    mounted() {
        this.getCityTree();
        this.getGoldMasterList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>