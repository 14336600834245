<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
            <el-table-column prop="nickName" label="申请人昵称" min-width="120" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="申请人编号" min-width="120" align="center">
                <template slot-scope="scope">
                    <a :href="'/userNumber/' + scope.row.userNumber" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="applyNickName" label="被申请人昵称" min-width="150" align="center"></el-table-column>
            <el-table-column prop="applyUserNumber" label="被申请人编号" min-width="150" align="center">
                <template slot-scope="scope">
                    <a :href="'/userNumber/' + scope.row.applyUserNumber" target="_blank">{{ scope.row.applyUserNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="140" align="center"></el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    customSuccessPage
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                nickName: ""
            },
            formLabelWidth: "80px",
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getCustomSuccessList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getCustomSuccessList();
        },
        //分页获取列表
        getCustomSuccessList() {
            customSuccessPage({
                current: this.page,
                size: this.size,
                nickName: this.query.nickName
            })
                .then(response => {
                    this.total = response.total
                    this.dataList = response.records
                })
        },
        //删除事件
        deleteClick(id) {
            this.$confirm('确定要删除此交友申请?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false
            }).then(() => {
                this.deleteCustomApply(id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除',
                    center: true
                });
            });
        }
    },
    mounted() {
        this.getCustomSuccessList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>