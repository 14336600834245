<template>
    <div>
        <div>
            <div class="flex-shrink">
                <div class="title-row">
                    <div class="table-title">首页公告弹窗</div>
                    <div class="btns">
                        <el-button type="primary" size="mini" @click="openNotice()">新增</el-button>
                    </div>
                </div>
            </div>
            <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border :cell-style="{ padding: '0' }"
                :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
                :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }"
                :row-class-name="setRowClassName"
                >
                <el-table-column prop="title" label="公告标题" min-width="80" align="center"></el-table-column>
                <el-table-column prop="content" label="公告内容" min-width="200" align="center"></el-table-column>
                <el-table-column prop="period" label="公告触达时间" min-width="80" align="center">
                    <template slot-scope="scope">
                        <div>{{ scope.row.startTime }}</div>
                        至
                        <div>{{ scope.row.endTime }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="公告状态" min-width="50" align="center">
                    <template slot-scope="scope">
                        <span :style="{color: ['#67C23A','#F56C6C'][scope.row.status]}">{{ statusAry[scope.row.status] }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="80" align="center"></el-table-column>
                <el-table-column prop="" label="操作" min-width="80" align="center">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="openNotice(scope.row)">修改</el-button>
                        <el-button type="danger" size="mini" @click="deleteNotice(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align:right;">
                <el-pagination style="margin-top: 10px;" background  layout="total, prev, pager, next, sizes"
                    :page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
                </el-pagination>
            </div>
        </div>

        <el-dialog width="600px" :title="noticeForm.id ? '修改公告' : '新增公告'" :visible.sync="noticeFormVisible" :close-on-click-modal="false">
            <el-form ref="noticeForm" :model="noticeForm" label-width="140px" :rules="noticeFormRules" style="text-align: left;">
                <el-form-item label="公告状态">
                    <el-switch
                        v-model="noticeForm.status"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        :active-value="0"
                        :inactive-value="1"
                        >
                    </el-switch>
                </el-form-item>
                <el-form-item prop="title" label="公告标题">
                    <el-input v-model="noticeForm.title" maxlength="20" placeholder="公告标题最多20字"></el-input>
                </el-form-item>
                <el-form-item prop="content" label="公告内容">
                    <el-input v-model="noticeForm.content" type="textarea" :rows="8" placeholder="请输入公告内容" resize="none"></el-input>
                </el-form-item>
                <el-form-item prop="time" label="公告触达时间段">
                    <el-date-picker v-model="noticeForm.time" type="datetimerange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" _default-time="['00:00:00', '23:59:59']">
					</el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
				<el-button @click="noticeFormVisible = false">取 消</el-button>
				<el-button type="primary" :loading="noticeFormLoading" @click="paymentConfirm">确 定</el-button>
			</div>
        </el-dialog>
    </div>
</template>

<script>
import {
    sysBulletinPage,
	sysBulletinPost,
	sysBulletinUpdate,
    sysBulletinDelete
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            tableData: [],
			tableHeight: null,
            statusAry: ['启用','禁用'],

            noticeForm: {},
            noticeFormRules: {
                title: [
                    { required: true, message: '请输入标题' }
                ],
                content: [
                    { required: true, message: '请输入内容' }
                ],
                time: [
                    { required: true, validator: this.timeValidate, message: '请选择时间段' }
                ],
            },
            noticeFormVisible: false,
            noticeFormLoading: false,
        }
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getNoticeList();
		},
        handleSizeChange(size) {
			this.size = size
			this.getNoticeList()
		},
		changeClick(page) {
			this.page = page
			this.getNoticeList()
		},
        timeValidate(rule, value, callback) {
            // console.log(rule, value,'rule')
            if (!value || !value[0] || !value[1]) {
                callback(new Error(rule.message))
            } else {
                callback()
            }
        },
        openNotice(item) {
            const formFields = ['id','title','content', 'status']
            for (let field of formFields) {
                const value = item ? item[field] : field == 'status' ? 0 : ''
                this.$set(this.noticeForm, field, value)
            }
            const time = [item ? item.startTime : '', item ? item.endTime : '']
            this.$set(this.noticeForm, 'time', time)

            this.noticeFormVisible = true
            this.$nextTick(() => {
                this.$refs.noticeForm.clearValidate()
            })
        },
        async paymentConfirm() {
            await new Promise((resolve) => {
                this.$refs.noticeForm.validate(valid => valid && resolve())
            })

            const formData = {...this.noticeForm}
            formData.startTime = formData.time[0]
            formData.endTime = formData.time[1]
            delete formData.time

            // console.log(formData,'formData')
            // return;

            this.noticeFormLoading = true
            const send = formData.id ? sysBulletinUpdate : sysBulletinPost

            try {
                await send(formData)
                this.noticeFormVisible = false
                this.getNoticeList()
            } catch {}

            this.noticeFormLoading = false
        },
        getNoticeList() {
            sysBulletinPage({
				current: this.page,
				size: this.size,
			}).then((response) => {
                this.total = response.total
                this.tableData = response.records
            })
        },
        deleteNotice(item) {
            this.$confirm(`正在删除${item.title}`, '是否确认删除该公告', {
                type: 'warning',
            }).then(() => {
                let params = new URLSearchParams()
                params.append("idList", [item.id])
                sysBulletinDelete({params}).then(() => {
                    this.getNoticeList()
                })
            })
        },
        setRowClassName({row, rowIndex}) {
            // console.log(row,'row')
            if (row.endTime) {
                const endTime = new Date(row.endTime)
                const nowTime = new Date()
                return nowTime >= endTime ? 'expire-row' : ''
            }
            return ''
        }
    },
    created() {
        this.getNoticeList()

        window.onresize = () => {
            this.setTableHeight()
        }
    }
}
</script>

<style scoped>
    .title-row {
        display: flex;
        margin-bottom: 5px;
    }

    .title-row .btns {
        margin-left: 10px;
    }

    .table-title {
        font-weight: bold;
        font-size: 20px;
        text-align: left;
    }

    .title-tips {
        color:#999;margin-left: 10px;align-self: flex-end;
    }

    #mainTable >>> .expire-row td {
        color: #d0d0d0;
    }
</style>