<template>
    <div class="main-content">
        <div class="chat-window">
            <div class="chat-header">
                <div class="search-input" @click="showSearch"><i class="el-icon-search"></i>&nbsp;搜索用户</div>
                <!-- <div class="search-add">
                    <i class="el-icon-plus"></i>
                </div> -->
                <search-dialog ref="searchDialog" />
            </div>
            <div class="chat-main">
                <!-- <div class="chat-side">
                    <div class="my-avatar"></div>
                </div> -->
                <div class="chat-contact">
                    <contact />
                </div>
                <div class="chat-right-content">
                    <chat />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { NIM } from '@yxim/nim-web-sdk'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import contact from './components/contact/list'
import chat from './components/chat/chat'
import searchDialog from './components/common/searchDialog'
import voiceAudio from '@/assets/audio/msgVoice.mp3'
export default {
    components: {
        contact,
        chat,
        searchDialog
    },
    data() {
        return {
            nim: undefined,
            myUserInfo: undefined,
            audio: undefined,
        }
    },
    methods: {
        ...mapMutations('nim', ['setNim', 'setMyUserInfo', 'updateMsgReceiptTime']),
        ...mapActions('nim', ['runHook', 'receiveMsg', 'receiveRecallMsg', 'receiveOfflineMsgs', 'getEmojiDatas']),
        initAudio() {
            this.audio = new Audio(voiceAudio)
        },
        playVoice() {
            if (!this.audio) {
                this.initAudio()
            }
            
            console.log('play voice')
            // 播放新消息提示音频
            this.audio.play()
        },
        initNIM() {
            if (this.nim !== undefined) return;

            const user = JSON.parse(localStorage.getItem("user"))
            const devKey = '609513b6f2bc26d9ccd3dcebc6a995c5'   // 本地开发(试用版)
            const proKey = '5bd42a7ade879d8b92261b9bb7069eb3'   // 线上生产(专业版)
            const appkey = process.env.NODE_ENV == 'development' ? devKey : proKey
            const options = {
                appKey: appkey,
                account: user.userNumber, // 请填写你的account
                token: user.yunToken, // 请填写你的token,
                // debug: true,
                onconnect: this.connectSuccess,
                ondisconnect: this.connectFail,
                onwillreconnect: this.onwillreconnect,
                onsessions: this.onsessions,
                onupdatesessions: this.onupdatesessions,
                onmsg: this.onmsg,
                onroamingmsgs: this.onroamingmsgs,
                onofflinemsgs: this.onofflinemsgs,
                onsysmsg: this.onsysmsg,
                onsysmsgunread: this.onsysmsgunread,
                onmyinfo: this.onmyinfo
            }
            // console.log(options,'options')
            // return;
            const nim = NIM.getInstance(options)
            this.nim = nim
            this.setNim(nim)
        },
        connectSuccess(e) { // 连接成功的回调
            console.log(e,'connect success')
            this.getEmojiDatas()
            // this.getUserInfo()
            this.runHook('connect')
        },
        connectFail(e) {    // 连接失败的回调
            console.log(e,'fail')
            if (e.code !== 'logout') {
                this.$message.error(e.message)
            }
        },
        onwillreconnect(e) {    // 即将重连的回调
            console.log(e,'onwillreconnect')
        },
        onsessions(e) {
            this.runHook({hookName: 'sessions', params: e})
        },
        onupdatesessions(e) {
            console.log(e,'onupdatesessions')

            if (Array.isArray(e)) {
                e.forEach(item => this.updateMsgReceiptTime(item))
            } else {
                this.updateMsgReceiptTime(e)
            }
        },
        onmsg(e) {  // 接收消息的回调
            console.log(e,'onmsg')

            this.receiveMsg(e)
            this.runHook({hookName:'msg', params: e})

            if (e.from !== this.myUserInfo.account) {
                this.playVoice()
            }
        },
        /* onroamingmsgs(e) {  // 接收漫游消息的回调
            console.log(e,'onroamingmsgs')
        }, */
        onofflinemsgs(e) {  // 接收离线消息的回调
            console.log(e,'onofflinemsgs')
            this.receiveOfflineMsgs(e)
        },
        onsysmsg(e) {  // 接收系统消息回调
            console.log(e,'onsysmsg')
            if (e.type == 'deleteMsg') {
                this.receiveRecallMsg(e)
            }
        },
        onsysmsgunread(e) {  // 接收系统通知未读数的回调
            // console.log(e,'onsysmsgunread')
        },
        onmyinfo(e) {   // 获取自己用户信息的回调
            this.myUserInfo = e
            this.setMyUserInfo(e)
        },

        showSearch() {
            this.$refs.searchDialog.openDialog()
        }
    },
    created() {
        this.initNIM()
        this.initAudio()
    }
}
</script>

<style scoped>
.main-content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-window {
    width: 100%;
    max-width: 1070px;
    min-width: 600px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.chat-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e8e8e8;
}

.chat-header .search-input {
    width: 50%;
    line-height: 22px;
    font-size: 14px;
    color: #b3b7bc;
    background-color: #f1f5f8;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.chat-header .search-add {
    width: 32px;
    height: 32px;
    font-size: 18px;
    color: #c1c8d1;
    background-color: #f1f5f8;
    border-radius: 4px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.chat-main {
    height: 60vh;
    display: flex;
    flex-grow: 1;
}

.chat-side {
    width: 60px;
    border-right: 1px solid #e8e8e8;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.chat-side .my-avatar {
    width: 36px;
    height: 36px;
    margin: 20px 0 25px;
    border: 1px solid #e8e8e8;
    border-radius: 50%;
}

.chat-contact {
    width: 200px;
    border-right: 1px solid #e8e8e8;
}

.chat-right-content {
    flex-grow: 1;
}
</style>