<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="发起者昵称">
                    <el-input v-model="query.nickName" placeholder="发起者昵称" style="width: 130px;" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="发起者ID">
                    <el-input v-model="query.userNumber" placeholder="发起者ID" style="width: 100px;" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="类型">
                    <el-select v-model="query.type" style="width: 120px">
                        <el-option label="全部" value=""></el-option>
                        <el-option :label="label" :value="value" v-for="(label, value) in typeAry" :key="value"></el-option>
                    </el-select>
                </el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="nickName" label="发起者昵称" min-width="100" align="center"></el-table-column>
			<el-table-column prop="userNumber" label="发起者ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank" >{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="createTime" label="发起时间" min-width="100" align="center"></el-table-column>
			<el-table-column prop="productType" label="道具" min-width="100" align="center">
                <template slot-scope="scope">
                    <span _class="typeColor[scope.row.type]">{{ productTypeAry[scope.row.productType] }}</span>
                </template>
            </el-table-column>
			<el-table-column prop="type" label="类型" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="typeColor[scope.row.type]">{{ typeAry[scope.row.type] }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="useDesc" label="用途" min-width="100" align="center"></el-table-column>
            <el-table-column prop="totalNumber" label="剩余总数" min-width="100" align="center"></el-table-column>
			<!-- <el-table-column fixed="right" label="操作" min-width="100" align="center">
				<template slot-scope="scope">
					<el-button type="danger" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
				</template>
			</el-table-column> -->
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=true layout="total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
			</el-pagination>
		</div>
    </div>
</template>

<script>
import {
    matchProductRecordPage
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				nickName: '',
                userNumber: '',
                type: '',
            },

            typeAry: ['购买', '使用','退还'],
            typeColor: ['color-primary', 'color-success', 'color-danger'],

            productTypeAry: ['匹配卡','同城卡'],
        }
    },
    watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getRecordList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.selectDate = []

			this.page = 1
			this.getRecordList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getRecordList()
		},
		changeClick(page) {
			this.page = page
			this.getRecordList()
		},
		//获取记录列表接口
		getRecordList() {
			matchProductRecordPage({
				"current": this.page,
				"size": this.size,
				'nickName': this.query.nickName,
                'userNumber': this.query.userNumber,
                'type': this.query.type,
			})
				.then((response) => {
					this.total = response.total
					this.tableData = response.records
				})
				.catch((error) => {
					console.log(error)
				});
		},
	},
	created() {
		//获取记录列表
		this.getRecordList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
</style>