<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="礼物名称">
                    <el-input v-model="query.giftName" placeholder="请输入礼物名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="success" @click="addClick">添加</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="name" label="礼物名称" min-width="150" align="center"></el-table-column>
            <el-table-column prop="icon" label="礼物图标" min-width="150" align="center">
                <template slot-scope="scope">
                    <el-image
                    style="width: 100px; height: 100px; padding-top: 5px;"
                    :src="'https://file.oiki.cc/' + scope.row.icon" 
                    >
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="price" label="礼物价格" min-width="150" align="center"></el-table-column>
            <el-table-column prop="sort" label="排序" min-width="150" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="150" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="180" align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">编辑
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="添加礼物" :visible.sync="addShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form :model="addForm">
				<el-form-item label="礼物名称" :label-width="formLabelWidth">
					<el-input v-model="addForm.name" placeholder="请输入礼物名称" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="礼物价格" :label-width="formLabelWidth">
					<el-input v-model="addForm.price" placeholder="请输入礼物价格" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="礼物icon" :label-width="formLabelWidth">
					<el-input v-model="addForm.icon" placeholder="请输入礼物icon" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="礼物svga" :label-width="formLabelWidth">
					<el-input v-model="addForm.svgaImage" placeholder="请输入礼物svga" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="排序" :label-width="formLabelWidth">
					<el-input v-model="addForm.sort" placeholder="排序，可不填" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addShow = false">取 消</el-button>
				<el-button type="primary" @click="addGift">确 定</el-button>
			</div>
		</el-dialog>

        <el-dialog title="编辑产品" :visible.sync="updateShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form :model="updateForm">
				<el-form-item label="礼物名称" :label-width="formLabelWidth">
					<el-input v-model="updateForm.name" placeholder="请输入礼物名称" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="礼物价格" :label-width="formLabelWidth">
					<el-input v-model="updateForm.price" placeholder="请输入礼物价格" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="礼物icon" :label-width="formLabelWidth">
					<el-input v-model="updateForm.icon" placeholder="请输入礼物icon" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="礼物svga" :label-width="formLabelWidth">
					<el-input v-model="updateForm.svgaImage" placeholder="请输入礼物svga" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="排序" :label-width="formLabelWidth">
					<el-input v-model="updateForm.sort" placeholder="排序，可不填" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="updateShow = false">取 消</el-button>
				<el-button type="primary" @click="submitUpdate">确 定</el-button>
			</span>
		</el-dialog>

    </div>
</template>

<script>
import {
    giftList,
    giftSave,
    giftUpdate,
    giftDelete
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                giftName: ""
            },
            formLabelWidth: "80px",

            addShow: false,
            addForm: {
				name: '',
				price: '',
				icon: '',
				svgaImage: '',
                sort: 0
			},

            updateShow: false,
            updateForm: {
                id: '',
				name: '',
				price: '',
				icon: '',
				svgaImage: '',
                sort: 0
			},
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.getGiftList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getGiftList();
        },
        //获取待审核动态分页列表
        getGiftList() {
            giftList({
                name: this.query.giftName
            })
                .then(response => {
                    this.dataList = response;
                })
        },
        
        //添加礼物事件
		addClick() {
			this.addShow = true
		},
        //新增礼物
		addGift() {
			giftSave({
				name: this.addForm.name,
				price: this.addForm.price,
				icon: this.addForm.icon,
				svgaImage: this.addForm.svgaImage,
                sort: this.addForm.sort
			})
				.then(() => {
					this.getGiftList() //保存成功，重新获取数据
				})
			this.addShow = false
		},

        //编辑礼物事件
		updateClick(row) {
			this.updateShow = true
			this.updateForm.id = row.id
			this.updateForm.name = row.name
			this.updateForm.price = row.price
			this.updateForm.icon = row.icon
			this.updateForm.svgaImage = row.svgaImage
            this.updateForm.sort = row.sort
		},
        //提交礼物编辑事件
		submitUpdate() {
			giftUpdate({
                id: this.updateForm.id,
                name: this.updateForm.name,
                price: this.updateForm.price,
                icon: this.updateForm.icon,
                svgaImage: this.updateForm.svgaImage,
                sort: this.updateForm.sort
            }).then(() => {
                this.getGiftList();
            })
            this.updateShow = false
		},

        //删除礼物事件
        deleteClick(id) {
            this.$confirm('确定要删除此礼物?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteGift(id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
        },
        deleteGift(id) {
            let params = new URLSearchParams()
			params.append("idList", [id])
			giftDelete({ params })
				.then(() => {
					window.location.reload() //刷新页面
				})
        }
    },
    mounted() {
        this.getGiftList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>