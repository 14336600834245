<template>
    <div>
        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-button type="primary" @click="addClick" size="mini" icon="el-icon-plus" style="margin-bottom: 10px">添加
            </el-button>
        </div>
        <el-table :data="dataList" row-key="id" border style="width: 100%" :tree-props="{ children: 'childList' }"
            :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
            <el-table-column prop="menuName" label="菜单名称" min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="menuCode" label="菜单编码" min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="menuUrl" label="菜单链接" min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="menuIcon" label="菜单图标" min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="sort" label="排序" min-width="50" align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="120" align="center">
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="120" align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">编辑
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteById(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="添加菜单" :visible.sync="addShow" :close-on-click-modal="false" width="25%" center>
            <el-form :model="addForm">
                <el-form-item label="菜单名称" :label-width="formLabelWidth">
                    <el-input v-model="addForm.menuName" placeholder="请输入菜单名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="菜单编码" :label-width="formLabelWidth">
                    <el-input v-model="addForm.menuCode" placeholder="请输入菜单编码" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="菜单链接" :label-width="formLabelWidth">
                    <el-input v-model="addForm.menuUrl" placeholder="请输入菜单链接" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="菜单图标" :label-width="formLabelWidth">
                    <el-input v-model="addForm.menuIcon" placeholder="请输入菜单图标" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="上级菜单" :label-width="formLabelWidth">
                    <el-cascader placeholder="不选默认一级菜单" v-model="selectOption" :options="dataList" :props="defaultParams"
                        @change="handleChange" filterable :show-all-levels="false"></el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addShow = false">取 消</el-button>
                <el-button type="primary" @click="addMenu">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑菜单" :visible.sync="updateShow" :close-on-click-modal="false" width="25%" center>
            <el-form :model="updateForm">
                <el-form-item label="菜单名称" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.menuName" placeholder="请输入菜单名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="菜单编码" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.menuCode" placeholder="请输入菜单编码" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="菜单链接" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.menuUrl" placeholder="请输入菜单链接" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="菜单图标" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.menuIcon" placeholder="请输入菜单图标" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="上级菜单" :label-width="formLabelWidth">
                    <el-cascader placeholder="不选默认一级菜单" v-model="selectOption" :options="dataList" :props="defaultParams"
                        @change="handleChange" filterable :show-all-levels="false"></el-cascader>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="updateShow = false">取 消</el-button>
                <el-button type="primary" @click="submitUpdate">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    menuSave,
    menuDelete,
    menuUpdate,
    menuTreeList
} from "@/api/api";
export default {
    data() {
        return {
            dataList: [],
            addShow: false,
            updateShow: false,
            parentId: 0,
            addForm: {
                menuName: "",
                menuCode: "",
                menuUrl: "",
                menuIcon: ""
            },
            updateForm: {
                id: "",
                menuName: "",
                menuCode: "",
                menuUrl: "",
                menuIcon: ""
            },
            formLabelWidth: "80px",

            //级联选择器信息
            selectOption: [],
            defaultParams: {
                label: "menuName",
                value: "id",
                children: "childList",
                checkStrictly: true
            },
            temp: []
        };
    },
    methods: {
        //添加菜单事件
        addClick() {
            this.parentId = 0;
            this.selectOption = [];
            this.addShow = true;
        },
        search() {
            this.getTreeList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //级联选择器选择事件
        handleChange(value) {
            console.log(value);
            this.selectOption = value;
        },
        //获取菜单树结构
        getTreeList() {
            menuTreeList(null)
                .then(response => {
                    this.dataList = this.cleanEmptyMenu(response);
                })
        },
        //递归解决数组为空导致的级联错误
        cleanEmptyMenu(data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].childList == null || data[i].childList.length < 1) {
                    data[i].childList = undefined;
                } else {
                    this.cleanEmptyMenu(data[i].childList);
                }
            }
            return data;
        },
        //提交菜单编辑事件
        submitUpdate() {
            this.updateShow = false;
            if (this.selectOption.length > 0) {
                this.parentId = this.selectOption[this.selectOption.length - 1];
            }
            console.log(this.selectOption);
            menuUpdate({
                id: this.updateForm.id,
                menuName: this.updateForm.menuName,
                menuCode: this.updateForm.menuCode,
                menuUrl: this.updateForm.menuUrl,
                menuIcon: this.updateForm.menuIcon,
                parentId: this.parentId
            })
                .then(() => {
                    this.getTreeList();
                })
        },
        //编辑菜单事件
        updateClick(row) {
            this.selectOption = [];
            this.updateShow = true;
            this.updateForm.id = row.id;
            this.updateForm.menuName = row.menuName;
            this.updateForm.menuCode = row.menuCode;
            this.updateForm.menuUrl = row.menuUrl;
            this.updateForm.menuIcon = row.menuIcon;
            if (row.parentId != 0) {
                //循环获取上级
                this.getSelectOption(row.parentId, this.dataList);
            }
        },
        //循环获取上级
        getSelectOption(parentId, menuList) {
            for (let i = 0; i < menuList.length; i++) {
                let menu = menuList[i];
                if (menu.id == parentId) {
                    this.selectOption.unshift(menu.id);
                    if (menu.parentId != 0) {
                        //循环获取上级
                        this.getSelectOption(menu.parentId, this.dataList);
                    }
                }
                if (
                    menu.childList != null &&
                    menu.childList != undefined &&
                    menu.childList.length > 0
                ) {
                    this.getSelectOption(parentId, menu.childList);
                }
            }
        },

        //删除菜单事件
        deleteById(id) {
            this.$confirm("确定要删除此菜单?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    this.deleteMenu(id);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                        center: true
                    });
                });
        },
        //删除菜单接口
        deleteMenu(id) {
            let params = new URLSearchParams()
            params.append("idList", [id])
            menuDelete({ params })
                .then(() => {
                    window.location.reload(); //刷新页面
                    this.getTreeList(); //删除成功，重新获取数据
                    this.$message({
                        type: "success",
                        message: "删除成功!",
                        center: true
                    });
                })
        },
        //新增菜单
        addMenu() {
            if (this.selectOption.length > 0) {
                this.parentId = this.selectOption[this.selectOption.length - 1];
            }
            menuSave({
                menuName: this.addForm.menuName,
                menuCode: this.addForm.menuCode,
                menuUrl: this.addForm.menuUrl,
                menuIcon: this.addForm.menuIcon,
                parentId: this.parentId
            })
                .then(() => {
                    this.getTreeList(); //保存成功，重新获取数据
                })
            this.addShow = false;
        }
    },
    mounted() {
        this.getTreeList();
    }
};
</script>
