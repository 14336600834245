<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="申请者昵称">
					<el-input v-model="query.nickName" placeholder="申请者昵称" style="width: 155px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="申请者ID">
					<el-input v-model="query.userNumber" placeholder="申请者ID" style="width: 110px" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="征友信息ID">
					<el-input v-model="query.seekFriendId" placeholder="征友信息ID" style="width: 110px" @keydown.enter.native="search"></el-input>
				</el-form-item>
                <el-form-item label="发布者昵称">
					<el-input v-model="query.CNickName" placeholder="发布者昵称" style="width: 155px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="发布者ID">
					<el-input v-model="query.CUserNumber" placeholder="发布者ID" style="width: 110px" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="申请时间">
					<el-date-picker v-model="publishTime" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
                <el-form-item label="交友状态">
					<el-select v-model="query.status" placeholder="交友状态" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="label" :value="value" v-for="(label,value) in statusAry" :key="value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="nickName" label="申请者昵称" min-width="150" align="center"></el-table-column>
			<el-table-column prop="userNumber" label="申请者ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/userNumber/' + scope.row.userNumber" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="userNumber" label="征友信息ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/friendsPublishDetail/' + scope.row.seekFriendId" target="_blank">{{ scope.row.seekFriendId }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="cnickName" label="发布者昵称" min-width="150" align="center"></el-table-column>
			<el-table-column prop="cuserNumber" label="发布者ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/userNumber/' + scope.row.cuserNumber" target="_blank">{{ scope.row.cuserNumber }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="createTime" label="申请时间" min-width="100" align="center"></el-table-column>
			<el-table-column prop="coinNumber" label="支付K币" min-width="60" align="center"></el-table-column>
			<el-table-column prop="status" label="交友状态" min-width="60" align="center">
                <template slot-scope="scope">
                    <span :class="[statusColor[scope.row.status]]">{{ statusAry[scope.row.status] }}</span>
                </template>
            </el-table-column>
			<!-- <el-table-column fixed="right" label="操作" min-width="100" align="center">
				<template slot-scope="scope">
					
				</template>
			</el-table-column> -->
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background :hide-on-single-page=false layout="slot, total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
                <span class="page-text">发布者：{{statisticsData.totalUserNumber || 0}}人</span>
				<span class="page-text">征友信息：{{statisticsData.number}}条</span>
				<span class="page-text">K币数量：{{statisticsData.totalCoinNumber}}</span>
			</el-pagination>
		</div>

    </div>
</template>

<script>
import {
    seekFriendApplyPage,
    seekFriendApplyStatistics
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				nickName: '',
				userNumber: '',
				seekFriendId: '',
				CNickName: '',
				CUserNumber: '',
				status: '',
            },

            statisticsData: {},

			statusAry: ['沟通中','成功','失败'],
            statusColor: ['color-warning', 'color-success','color-danger'],

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},

			publishTime: []
        }
    },
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getApplyList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.lastPayTime = []
			this.publishTime = []

			this.page = 1
			this.getApplyList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getApplyList()
		},
		changeClick(page) {
			this.page = page
			this.getApplyList()
		},
		//获取新征友申请列表接口
		getApplyList() {

            const params = {
                "nickName": this.query.nickName,
				"userNumber": this.query.userNumber,
				"seekFriendId": this.query.seekFriendId,
				"CNickName": this.query.CNickName,
				"CUserNumber": this.query.CUserNumber,
				"status": this.query.status,
				"startTime": this.publishTime != null ? this.publishTime[0] : null,
				"endTime": this.publishTime != null ? this.publishTime[1] : null
            }
			
			seekFriendApplyPage({
				"current": this.page,
				"size": this.size,
				...params
			}).then((response) => {
				this.total = response.total
				this.tableData = response.records
			})

            seekFriendApplyStatistics(params).then(response => {
				this.statisticsData = response
			})
		},
	},
	created() {
		//获取新征友申请列表
		this.getApplyList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>

</style>