<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="类型">
                    <el-select v-model="query.type" placeholder="请选择类型">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="首页" value=0></el-option>
						<el-option label="搜索" value=1></el-option>
                        <el-option label="动态" value=2></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="query.status" placeholder="请选择状态">
                        <el-option label="全部" value=""></el-option>
                        <el-option label="正常" value=0></el-option>
						<el-option label="禁用" value=1></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                    <el-button type="success" @click="addClick">添加</el-button>
                </el-form-item>
            </el-form>
            <span>轮播图的比例为640*320，请按比例上传轮播图。</span>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="mainImage" label="轮播图" min-width="90" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 100px; height: 100px; padding-top: 5px;" :src="scope.row.mainImage"
                        :preview-src-list="[scope.row.mainImage]">
                    </el-image>
                </template>
            </el-table-column>
            
            <el-table-column prop="contentImage" label="内容图片" min-width="90" align="center">
                <template slot-scope="scope">
                    <el-image v-if="scope.row.contentImage" style="width: 100px; height: 100px; padding-top: 5px;" :src="scope.row.contentImage"
                        :preview-src-list="[scope.row.contentImage]">
                    </el-image>
                </template>
            </el-table-column>

            <el-table-column prop="title" label="名称" min-width="60" align="center"></el-table-column>

            <el-table-column prop="url" label="详情链接" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="scope.row.url" target="_blank" v-if="scope.row.url">{{ scope.row.url }}</a>
                </template>
            </el-table-column>

            <el-table-column prop="type" label="展示位置" min-width="60" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.type === 0">首页</span>
					<span v-else-if="scope.row.type === 1">搜索</span>
                    <span v-else-if="scope.row.type === 2">动态</span>
				</template>
            </el-table-column>

            <el-table-column prop="status" label="状态" min-width="40" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.status === 0" style="color: #67C23A">正常</span>
					<span v-else-if="scope.row.status === 1" style="color: #F56C6C">禁用</span>
				</template>
            </el-table-column>

            <el-table-column prop="createTime" label="创建时间" min-width="80" align="center"></el-table-column>

            <el-table-column prop="platform" label="平台" min-width="40" align="center"></el-table-column>

            <el-table-column prop="gender" label="可见性别" min-width="40" align="center">
                <template slot-scope="scope">
                    {{ genderAry[scope.row.gender] }}
                </template>
            </el-table-column>
            
            <el-table-column prop="time" label="有效时间" min-width="80" align="center">
                <template slot-scope="scope">
                    <div v-if="scope.row.startTime">{{ scope.row.startTime }}<br/>至<br/>{{ scope.row.endTime }}</div>
                </template>
            </el-table-column>

            <el-table-column prop="category" label="分类" min-width="40" align="center">
                <template slot-scope="scope">
                    {{ categoryAry[scope.row.category] }}
                </template>
            </el-table-column>

            <el-table-column fixed="right" label="操作" min-width="140" align="center">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" icon="el-icon-edit" @click="editClick(scope.row)">编辑</el-button>
                    <el-button v-if="scope.row.status === 0" type="warning" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">禁用
                    </el-button>
                    <el-button v-if="scope.row.status === 1" type="success" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">启用
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>

        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog title="添加轮播图" :visible.sync="addShow" width="530px" center zIndex=1999 :close-on-click-modal="false">
			<el-form :model="addForm">
				<el-form-item label="轮播图" :label-width="formLabelWidth">
					<el-upload action="api/file/upload"
                        :headers="headers"
                        :data="uploadData"
                        list-type="picture-card"
						:on-preview="handlePictureCardPreview" :class="{ disabled: uploadDisabled }" :limit="1"
						:file-list="mainImageFile" :on-remove="mainImageRemove" :on-success="mainImageSuccess"
						:on-change="handleChange" :on-error="uploadError">
						<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="内容图" :label-width="formLabelWidth">
					<el-upload action="api/file/upload"
                        :headers="headers"
                        :data="uploadContentData"
                        list-type="picture-card"
						:on-preview="handlePictureCardPreview" :class="{ disabled: uploadDisabled }" :limit="1"
						:file-list="contentImageFile" :on-remove="contentImageRemove" :on-success="contentImageSuccess"
						:on-change="handleChange" :on-error="uploadError">
						<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="名称" :label-width="formLabelWidth">
					<el-input v-model="addForm.title" placeholder="请banner名称"></el-input>
				</el-form-item>
				<el-form-item label="详情链接" :label-width="formLabelWidth">
					<el-input v-model="addForm.url" placeholder="请输入链接 不输入时 点击banner不跳转"></el-input>
				</el-form-item>
                <el-form-item label="展示位置" :label-width="formLabelWidth">
					<el-select v-model="addForm.type" placeholder="请选择类型">
						<el-option label="首页" :value="0"></el-option>
						<el-option label="搜索" :value="1"></el-option>
						<el-option label="动态" :value="2"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="平台" :label-width="formLabelWidth">
                    <el-checkbox-group v-model="addForm.platform" @change="changePlatform">
                        <el-checkbox :label="name" v-for="name in platformAry" :key="name"></el-checkbox>
                    </el-checkbox-group>
                    <div style="line-height: 20px;color:red;">提示：小程序端(h5)无法使用第三方外链，因此第三方外链需要自行截取长图片上传到内容图</div>
                </el-form-item>

                <el-form-item label="可见性别" :label-width="formLabelWidth">
                    <el-radio-group v-model="addForm.gender">
                        <el-radio :label="value" v-for="(txt, value) in genderAry" :key="value">{{txt}}</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="有效时间" :label-width="formLabelWidth">
                    <el-date-picker
                        v-model="addForm.time"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetimerange"
                        range-separator="至"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="分类" :label-width="formLabelWidth">
                    <el-radio-group v-model="addForm.category">
                        <el-radio :label="value" v-for="(txt, value) in categoryAry" :key="value">{{txt}}</el-radio>
                    </el-radio-group>
				</el-form-item>
                <div v-if="addForm.category == 1">
                    
                    <el-form-item label="活动" :label-width="formLabelWidth">
                        <el-select v-model="addForm.activityCode" placeholder="请选择活动">
                            <el-option :label="item.name" :value="item.value" v-for="item in activityList" :key="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <el-form-item label="状态" :label-width="formLabelWidth">
					<el-select v-model="addForm.status" placeholder="请选择状态">
						<el-option label="正常" :value="0"></el-option>
						<el-option label="禁用" :value="1"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addShow = false">取 消</el-button>
				<el-button type="primary" @click="submitBanner">确 定</el-button>
			</div>
		</el-dialog>

        <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>
    </div>
</template>

<script>
import {
    dict,
    bannerPage,
    bannerSave,
    bannerUpdate,
    bannerDelete
} from "@/api/api";

import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                type: "",
                status: ""
            },
            formLabelWidth: "80px",

            addShow: false,
            addForm: {
				mainImage: '',
				contentImage: '',
                url: '',
                gender: 0,
                time: '',
                platform: [],
                category: '',
				type: '',
                activityCode: '',
				status: ''
			},
            genderAry: ['全部','男','女'],
            platformAry: ['ios', 'android', 'h5'],
            categoryAry: ['仅展示','活动'],
            activityList: [],
            uploadDisabled: false,
            dialogImageUrl: '',
            dialogVisible: false,
            headers: {
                Authorization: "Bearer " + localStorage.token
            },
            uploadData: {
                code: "9001",
                index: 0
            },
            uploadContentData: {
                code: "9002",
                index: 0
            },
            mainImgList: [], //banner图列表
            contentImgList: [], //详情图列表
        };
    },
    computed: {
        mainImageFile() {
            const list = []
            if (this.addForm.mainImage) {
                list.push({url: this.addForm.mainImage})
            }
            return list
        },
        contentImageFile() {
            const list = []
            if (this.addForm.contentImage) {
                list.push({url: this.addForm.contentImage})
            }
            return list
        }
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.getBannerList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getBannerList();
        },
        //分页获取实名认证列表
        getBannerList() {
            bannerPage({
                current: this.page,
                size: this.size,
                type: this.query.type,
                status: this.query.status
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                    for (let i = 0; i < this.dataList.length; i++) {
                        let banner = this.dataList[i];
                        if (banner.mainImage != null && banner.mainImage.length > 0) {
                            banner.mainImage = "https://file.oiki.cc/" + banner.mainImage
                        }
                        if (banner.contentImage != null && banner.contentImage.length > 0) {
                            banner.contentImage = "https://file.oiki.cc/" + banner.contentImage
                        }
                    }
                })
        },

        //banner图查看调用
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
        handleChange(file, fileList) {
            if (fileList.length >= 1) {
                this.uploadDisabled = true;
            } else {
                this.uploadDisabled = false;
            }
        },
        //banner删除
        mainImageRemove() {
            this.uploadDisabled = false;
            this.addForm.mainImage = '' // 主图地址设置为空
        },
        // 上传图片出错
        uploadError() {
            this.$message.error("上传出错");
        },
        //banner上传成功回调
        mainImageSuccess(response, file, fileList) {
            if (response.code==0) {
                //上传成功
                this.addForm.mainImage = response.data
                console.log(response.data)
            } else {
                //上传失败，删除图片
                for (let i = 0; i < fileList.length; i++) {
                    if (file == fileList[i]) {
                        fileList.splice(i, 1)
                    }
                }
            }
        },
        //详情图删除
        contentImageRemove() {
            this.uploadDisabled = false;
            this.addForm.contentImage = '' // 主图地址设置为空
        },
        //banner上传成功回调
        contentImageSuccess(response, file, fileList) {
            if (response.code==0) {
                //上传成功
                this.addForm.contentImage = response.data
            } else {
                //上传失败，删除图片
                for (let i = 0; i < fileList.length; i++) {
                    if (file == fileList[i]) {
                        fileList.splice(i, 1)
                    }
                }
            }
        },
        //显示大图
        showImgList(image) {
            let images = [image]
            return images
        },
        changePlatform(value) {
            value.sort((a,b) => {
                const aIndex = this.platformAry.findIndex(sortTxt => sortTxt == a)
                const bIndex = this.platformAry.findIndex(sortTxt => sortTxt == b)
                return aIndex - bIndex
            })

            console.log(value,'change')
        },

        clearForm() {
            delete this.addForm.id
            Object.keys(this.addForm).forEach(key => {
                if (key == 'time') {
                    this.addForm[key] = []
                    delete this.addForm.startTime
                    delete this.addForm.endTime
                } else if (key == 'platform') {
                    this.addForm[key] = []
                } else {
                    this.addForm[key] = ''
                }
            })
        },

        //添加banner事件
		addClick() {
            this.clearForm()
			this.addShow = true
            this.mainImgList = []
            this.contentImgList = []
            this.uploadDisabled = false
		},
        //新增banner
		submitBanner() {
            const formData = {}
            
            Object.keys(this.addForm).forEach(key => {
                if (key == 'time') {
                    formData.startTime = this.addForm[key][0]
                    formData.endTime = this.addForm[key][1]
                    console.log(this.addForm[key][0], this.addForm[key][1], 'time')
                } else if (key == 'platform') {
                    formData[key] = this.addForm[key].join('_')
                } else if (['mainImage','contentImage'].includes(key)) {
                    const img = this.addForm[key].replace(/https:\/\/file\.oiki\.cc\//g,'')
                    formData[key] = img
                } else {
                    console.log(key,'key')
                    formData[key] = this.addForm[key]
                }
            })
            
            // console.log(this.addForm,'addForm')
            // console.log(formData,'formData')
            // return;
            const bannerFn = formData.id ? bannerUpdate : bannerSave
			bannerFn(formData)
				.then(res => {
                    console.log(res,'res')
					this.getBannerList() //保存成功，重新获取数据
                    this.addShow = false
				})
		},
        // 获取活动列表
        getActivityList() {
            dict({parentCode: 'ACTIVITY_CODE'}).then(res => {
                this.activityList = res.records || []
            })
        },

        editClick(row) {
            console.log(row,'row')
            this.clearForm()
            this.$nextTick(() => {
                Object.keys(row).forEach(key => {
                    if (['updateName','updateTime','startTime','endTime','createName','createTime','isDelete','version'].includes(key)) return;

                    console.log(key,'key')
                    if (key == 'platform') {
                        const val = row.platform.split('_')
                        this.$set(this.addForm, key, val)
                    } else {
                        this.$set(this.addForm, key, row[key])
                    }
                })
                this.$set(this.addForm, 'time', [row.startTime, row.endTime])
                // console.log(this.addForm,'this.addForm')
                
                // console.log(this.mainImgList,'this.mainImgList')
                this.addShow = true
            })
        },

        //启用&禁用
		updateClick(row) {
            if (row.status == 0) {
                row.status = 1
            }else {
                row.status = 0
            }
			bannerUpdate({
                id: row.id,
                status: row.status
            })
            .then(() => {
                this.getBannerList() //保存成功，重新获取数据
            })
		},

        //删除轮播事件
        deleteClick(id) {
            this.$confirm('确定要删除此轮播?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteBanner(id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
        },
        deleteBanner(id) {
            let params = new URLSearchParams()
			params.append("idList", [id])
			bannerDelete({ params })
				.then(() => {
					window.location.reload() //刷新页面
				})
        }
    },
    mounted() {
        this.getBannerList();
        this.getActivityList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>