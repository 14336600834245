export default function setTableHeight (tableSelect, occupySelect) {
    const mainDom = document.querySelector('.el-main')
    const mainDomStyles = window.getComputedStyle(mainDom)
    const paddingY = parseInt(mainDomStyles.paddingTop) + parseInt(mainDomStyles.paddingBottom)
    let availableHeight = mainDom.clientHeight - paddingY

    const table = document.querySelector(tableSelect)
    const tableHead = document.querySelector(tableSelect + ' .el-table__header-wrapper')
    const occupy = document.querySelectorAll(occupySelect)
    const pages = document.querySelector('el-pagination')
    
    for (const dom of occupy) {
        availableHeight -= dom.clientHeight
    }
    
    if (tableHead) {
        availableHeight -= tableHead.clientHeight
    }
    
    if (pages) {
        availableHeight -= pages.clientHeight
    }

    // console.log(availableHeight,'xxxxx')

    return availableHeight;
}