<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
                <el-form-item label="分享者ID">
                    <el-input v-model="query.shareUserNumber" placeholder="分享者ID" style="width: 120px" @keydown.enter.native="search"></el-input>
                </el-form-item>
				<el-form-item label="分享者昵称">
					<el-input v-model="query.shareNickName" placeholder="分享者昵称" style="width: 160px;" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="收益来源">
					<el-select v-model="query.type" placeholder="收益来源" style="width: 120px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="label" :value="value" v-for="(label, value) in typeAry" :key="value"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="收益获得时间">
					<el-date-picker v-model="searchDate" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
					<el-button type="warning" icon="el-icon-document" @click="exportData">导出excel</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="shareUserNumber" label="分享者ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/userNumber/' + scope.row.shareUserNumber" target="_blank">{{ scope.row.shareUserNumber }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="shareNickName" label="分享者昵称" min-width="150" align="center"></el-table-column>
            <el-table-column prop="money" label="推广奖励(元)" min-width="80" align="center"></el-table-column>
            <el-table-column prop="type" label="收益来源" min-width="80" align="center">
				<template slot-scope="scope">
					{{ typeAry[scope.row.type] }}
				</template>
			</el-table-column>
			<el-table-column prop="downUserNumber" label="下级ID" min-width="100" align="center">
                <template slot-scope="scope">
                    <a :href="'/userNumber/' + scope.row.downUserNumber" target="_blank">{{ scope.row.downUserNumber }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="downNickName" label="下级昵称" min-width="100" align="center"></el-table-column>
			<el-table-column prop="createTime" label="收益获得时间" min-width="100" align="center"></el-table-column>
			<!-- <el-table-column fixed="right" label="操作" width="60" align="center">
				<template slot-scope="scope">
					<el-link type="primary" @click="toDetail(scope.row.id)">详情</el-link>
				</template>
			</el-table-column> -->
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background layout="slot, total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
				<span class="page-slot">推广奖励: {{ totalMoney }}元</span>
			</el-pagination>
		</div>

		
    </div>
</template>

<script>
import {
    shareMoneyPage,
	shareMoneyStatistics
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				shareNickName: '',
				shareUserNumber: '',
				type: '',
            },
			totalMoney: 0,

			typeAry: ['充值K币','首次开通贵族','开通贵族'],

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			
			searchDate: [],
        }
    },
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			console.log(this.query)
			// return;

			this.page = 1;
			this.getShareMoneyList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.searchDate = []

			this.page = 1
			this.getShareMoneyList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getShareMoneyList()
		},
		changeClick(page) {
			this.page = page
			this.getShareMoneyList()
		},
		//获取用户列表接口
		getShareMoneyList() {
			const params = {
				"shareNickName": this.query.shareNickName,
				"shareUserNumber": this.query.shareUserNumber,
				"type": this.query.type,
				"startTime": this.searchDate != null ? this.searchDate[0] : null,
				"endTime": this.searchDate != null ? this.searchDate[1] : null,
			}

			shareMoneyPage({
				"current": this.page,
				"size": this.size,
				...params
			}).then((response) => {
				this.total = response.total
				this.tableData = response.records
			})

			shareMoneyStatistics(params).then(response => {
				this.totalMoney = response
			})
		},
		exportData() {
            
        }
	},
	created() {
		//获取用户列表
		this.getShareMoneyList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
.page-slot {
	font-weight: lighter;
	margin-right: 20px;
}
</style>