<template>
    <div>
        <div class="title-row">
            <div class="flex-box">
                <div class="table-title">贵族等级配置</div>
            </div>
        </div>
        <el-table :data="powerList" border :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
            <el-table-column type="index" label="等级排序" width="100" align="center"></el-table-column>
            <el-table-column prop="name" label="等级名称" min-width="80" align="center"></el-table-column>
            <el-table-column prop="exp" label="经验值" min-width="80" align="center">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.minExp + (/* scope.row.minExp == 0 ? '≤' : '<' */'≤') + '经验值≤' + scope.row.maxExp }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="chatNumber" label="申请好友(次/月)" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ !scope.row.addFriendNumber ? '无限制': scope.row.addFriendNumber }}
                    <i class="editPower el-icon-edit" @click="editPower(scope.row, 'addFriendNumber')"></i>
                </template>
            </el-table-column>
            <el-table-column prop="chatNumber" label="直接开聊(人次/日)" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ !scope.row.chatNumber ? '无限制' : scope.row.chatNumber }}
                    <i class="editPower el-icon-edit" @click="editPower(scope.row, 'chatNumber')"></i>
                </template>
            </el-table-column>
            <el-table-column prop="matchNumber" label="额外匹配(人次/日)" min-width="80" align="center">
                <template slot-scope="scope">
                    {{ !scope.row.matchNumber ? '无限制' : scope.row.matchNumber }}
                    <i class="editPower el-icon-edit" @click="editPower(scope.row, 'matchNumber')"></i>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog width="300px" :title="powerForm.name" :visible.sync="powerFormVisible" :close-on-click-modal="false">
            <div class="power-form">
                <label class="radio-row flex-box flex-items-center" :class="{on: radioType == 0}" @click="radioType = 0">
                    <i class="icon el-icon-success"></i>
                    <span>数量</span>
                    <el-input ref="formNumber" v-model="formNumber" type="number" size="mini" placeholder="请填写数量" style="width: 120px;"></el-input>
                </label>
                <label class="radio-row flex-box flex-items-center" :class="{on: radioType == 1}" @click="radioType = 1">
                    <i class="icon el-icon-success"></i>
                    <span>不限</span>
                </label>
            </div>
            <div slot="footer" class="dialog-footer">
				<el-button @click="powerFormVisible = false">取 消</el-button>
				<el-button type="primary" :loading="powerFormLoading" @click="powerConfirm">确 定</el-button>
			</div>
        </el-dialog>
    </div>
</template>

<script>
import {
    vipPowerPage,
	vipPowerPost,
	// vipPowerUpdate,
    vipPowerNumberUpdate
} from "@/api/api";
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            powerList: [],

            formNumber: null,
            formField: null,
            radioType: null,
            powerForm: {},
            powerFormVisible: false,
            powerFormLoading: false,
        }
    },
    methods: {
        getPowerList() {
            vipPowerPage({
                current: this.page,
                size: this.size
            }).then(response => {
                this.powerList = response
                console.log(this.powerList,'powerList')
            })
        },
        editPower(item, field) {
            this.$set(this.powerForm, 'id', item.id)
            this.$set(this.powerForm, 'name', item.name)
            this.formField = field
            this.formNumber = item[field] || null
            this.radioType = this.formNumber ? 0 : 1
            this.powerFormVisible = true

            if (this.radioType == 0) {
                this.$nextTick(() => {
                    this.$refs.formNumber.focus()
                    this.$refs.formNumber.select()
                })
            }
        },
        powerConfirm() {
            if (this.radioType == 0 && [null, '', '0'].includes(this.formNumber)) {
                this.$message.error('请输入数量')
                return;
            }

            const fn = this.powerForm.id ? vipPowerNumberUpdate : vipPowerPost
            const formData = {...this.powerForm}
            
            const type = {chatNumber: 0, addFriendNumber: 1, matchNumber: 2}

            formData.type = type[this.formField]
            formData.number = this.radioType == 0 ? this.formNumber : null
            
            console.log(formData,'formData')
            // return;
            fn(formData).then(() => {
                this.powerFormVisible = false
                this.getPowerList()
            })
        }
    },
    created() {
        this.getPowerList()
    }
}
</script>

<style scoped>
    .title-row {
        display: flex;
        margin-bottom: 5px;
    }

    .title-row .btns {
        margin-left: 10px;
    }

    .table-title {
        font-weight: bold;
        font-size: 20px;
        text-align: left;
    }

    .title-tips {
        color:#999;margin-left: 10px;align-self: flex-end;
    }

    .radio-row {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        padding: 0 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        user-select: none;
        cursor: pointer;
    }

    .radio-row.on {
        background-color: #f3f3f3;
    }

    .radio-row.on .icon {
        visibility: visible;
    }

    .radio-row .icon {
        font-size: 22px;
        color: #67C23A;
        visibility: hidden;
    }

    .radio-row span {
        margin: 0 10px;
    }

    .flex-box {
        display: flex;
    }

    .flex-items-center {
        align-items: center;
    }

    .editPower {
        cursor: pointer;
        margin-left: 10px;
    }
</style>