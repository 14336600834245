<template>
    <div class="loginbody">
        <div class="logindata">
            <div class="logintext">
                <h2>Welcome</h2>
            </div>
            <div class="formdata">
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item prop="username">
                        <el-input v-model="form.username" clearable placeholder="请输入账号"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input v-model="form.password" clearable placeholder="请输入密码" show-password></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div class="tool">
                <div>
                    <el-checkbox v-model="checked" @change="remenber">记住密码</el-checkbox>
                </div>
                <div>
                    <span class="shou" @click="forgetpas">忘记密码？</span>
                </div>
            </div>
            <div class="butt">
                <el-button type="primary" @click.native.prevent="login('form')">登录</el-button>
                <el-button class="shou" @click="register">注册</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {
    userLogin,
    userFindInfo
} from '@/api/api';
export default {

    name: "login",
    data() {
        return {
            form: {
                password: "",
                username: "",
            },
            checked: false,
            rules: {
                username: [
                    { required: true, message: "请输入用户名", trigger: "blur" },
                    { max: 11, message: "不能大于11个字符", trigger: "blur" },
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    { max: 20, message: "不能大于20个字符", trigger: "blur" },
                ],
            },
        };
    },
    mounted() {
        if (localStorage.getItem("news")) {
            this.form = JSON.parse(localStorage.getItem("news"))
            this.checked = true
        }
    },
    methods: {
        login(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    userLogin({
                        "grant_type": "password",
                        "client_id": "yiluo",
                        "client_secret": "123456",
                        "username": this.form.username,
                        "password": this.form.password
                    })
                        .then((response) => {
                            if (response.code == 0) {
                                //token获取成功，保存token信息并获取用户详情
                                localStorage.setItem("token", response.data.access_token)
                                localStorage.token = response.data.access_token;
                                this.getUserInfo();
                            }
                        }).catch((error) => {
                            console.log(error)
                        })
                }
            });
        },
        //获取用户详细信息
        getUserInfo() {
            userFindInfo()
                .then((response) => {
                    //将用户数据存储到本地，并存储成json字符串
                    localStorage.setItem("user", JSON.stringify(response))
                    // 使用 vue-router 路由到指定页面，该方式称之为编程式导航
                    if (this.$route.query.redirect) {
                        //看下是否存在重定向地址，如果存在，就跳到之前的地址
                        this.$router.push(this.$route.query.redirect)
                    } else {
                        //如果不存在，就跳到用户列表界面
                        this.$router.push("/home");
                    }
                }).catch((error) => {
                    console.log(error)
                })
        },
        remenber(data) {
            this.checked = data
            if (this.checked) {
                localStorage.setItem("news", JSON.stringify(this.form))
            } else {
                localStorage.removeItem("news")
            }
        },
        forgetpas() {
            this.$message({
                type: "info",
                message: "功能尚未开发额😥",
                showClose: true
            })
        },
        register() { },
    },
};
</script>

<style scoped>
.loginbody {
    width: 100%;
    height: 100%;
    min-width: 1000px;
    background-image: url("../assets/login2.jpg");
    background-size: 100% 100%;
    background-position: center center;
    overflow: auto;
    background-repeat: no-repeat;
    position: fixed;
    line-height: 100%;
    padding-top: 150px;
}

.logintext {
    margin-bottom: 20px;
    line-height: 50px;
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
    color: white;
    text-shadow: 2px 2px 4px #000000;
}

.logindata {
    width: 400px;
    height: 300px;
    transform: translate(-50%);
    margin-left: 50%;
}

.tool {
    display: flex;
    justify-content: space-between;
    color: #606266;
}

.butt {
    margin-top: 10px;
    text-align: center;
}

.shou {
    cursor: pointer;
    color: #606266;
}

/*ui*/
/* /deep/ .el-form-item__label {
  font-weight: bolder;
  font-size: 15px;
  text-align: left;
}

/deep/ .el-button {
  width: 100%;
  margin-bottom: 10px;

} */
</style>