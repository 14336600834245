<template>
    <div>
        <div class="tabs-row">
            <div class="tab" :class="{on: tabIndex == index}" v-for="(tab, index) in tabs" :key="tab" @click="tabIndex = index">{{tab}}</div>
        </div>

        <div class="tabs-content">
            <div class="tabs-content-item" v-show="tabIndex == 0">
                <div class="module-box">
                    <div class="row-title">默认消息内容</div>
                    <div class="content-row flex flex-items-end">
                        <div :style="{width: '400px'}">
                            <el-input v-model="newContent.content" ref="content" type="textarea" :rows="6" :disabled="!isEditing('content')"></el-input>
                        </div>
                        <el-button type="success" :disabled="!checkFieldContent('content')" @click="submitField('content')" v-if="isEditing('content')">保存</el-button>
                        <el-button @click="cancelTap('content')" v-if="isEditing('content')">取消</el-button>
                        <el-button type="primary" @click="setEdit('content')" v-if="!isEditing('content')">修改</el-button>
                    </div>
                </div>
                <div class="module-box">
                    <div class="row-title">自定义消息内容</div>
                    <el-form class="form" label-width="180px">
                        <el-form-item label="第一条消息内容">
                            <div class="content-row flex flex-items-end">
                                <div :style="{width: '400px'}">
                                    <el-input v-model="newContent.oneContent" ref="oneContent" type="textarea" :rows="6" :disabled="!isEditing('oneContent')"></el-input>
                                </div>
                                <el-button type="success" :disabled="!checkFieldContent('oneContent')" @click="submitField('oneContent')" v-if="isEditing('oneContent')">保存</el-button>
                                <el-button @click="cancelTap('oneContent')" v-if="isEditing('oneContent')">取消</el-button>
                                <el-button type="primary" @click="setEdit('oneContent')" v-if="!isEditing('oneContent')">修改</el-button>
                            </div>
                        </el-form-item>
                        <el-form-item label="第二条消息内容">
                            <div class="content-row flex flex-items-end">
                                <div class="img-box" @click="imgTap">
                                    <el-image v-if="newContent.twoContent" style="width: 120px; height: 120px;" :src="newContent.twoContent"
                                        :preview-src-list="[newContent.twoContent]">
                                    </el-image>
                                    <i class="el-icon-plus" size="40" v-else></i>
                                </div>
                                <div v-if="newContent.twoContent">
                                    <el-button type="danger" @click="deleteImg">删除</el-button>
                                    <el-button type="primary" @click="chooseImg">更换</el-button>
                                </div>
        
                                <!-- el-upload仅作为上传图片的控件，不进行组件内容展示 -->
                                <el-upload
                                    ref="upload"
                                    action="api/file/upload"
                                    accept=".jpg,.jpeg,.png,.pneg,.gif,.Jpg,.Jpeg,.Png,.Pneg,.Gif"
                                    :limit="1"
                                    :headers="headers"
                                    :data="uploadData"
                                    :show-file-list="false"
                                    :on-success="uploadSuccess"></el-upload>
                            </div>
                        </el-form-item>
                        <el-form-item label="自定义消息的生效时间">
                            <div class="time-box flex flex-items-end">
                                <div>
                                    <div class="time-row">
                                        <el-radio v-model="newContent.msgTime" label="time" :disabled="!isEditing('msgTime')">&nbsp;</el-radio>
                                        单日时间段：
                                        每日
                                        <el-time-picker
                                            :disabled="!isEditing('msgTime')"
                                            v-model="newContent.startTime"
                                            value-format="HH:mm:ss"
                                            placeholder="选择时间范围">
                                        </el-time-picker>
                                            - 
                                        次日
                                        <el-time-picker
                                            :disabled="!isEditing('msgTime')"
                                            v-model="newContent.endTime"
                                            value-format="HH:mm:ss"
                                            placeholder="选择时间范围">
                                        </el-time-picker>
                                    </div>
                                    <div class="time-row">
                                        <el-radio v-model="newContent.msgTime" label="date" :disabled="!isEditing('msgTime')">&nbsp;</el-radio>
                                        连续时间段：
                                        <el-date-picker
                                            :disabled="!isEditing('msgTime')"
                                            v-model="date"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                            type="datetimerange"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            @change="dateChange"
                                            >
                                        </el-date-picker>
                                    </div>
                                </div>
                                <div class="time-btns">
                                    <el-button type="success" :disabled="!timeCanSubmit" @click="submitField('msgTime')" v-if="isEditing('msgTime')">保存</el-button>
                                    <el-button @click="cancelTap('msgTime')" v-if="isEditing('msgTime')">取消</el-button>
                                    <el-button type="primary" @click="setEdit('msgTime')" v-if="!isEditing('msgTime')">修改</el-button>
                                </div>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>

            </div>
            <div class="tabs-content-item text-left" v-show="tabIndex == 1" v-loading="formLoading">
                <div class="date-row">
                    <span class="t">设置时间段：</span>
                    <el-input v-model="sendDay" type="number" style="width: 100px;" size="mini"></el-input> 天
                    &nbsp;&nbsp;（ios/Android通用条件）
                </div>
                <messageForm ref="timeForm" :formData.sync="timeForm" />

                <el-button type="primary" @click="submitTimeForm">保存</el-button>
            </div>
            <div class="tabs-content-item text-left" v-show="tabIndex == 2" v-loading="formLoading">
                <messageForm ref="renewForm" :formData.sync="renewForm" />

                <el-button type="primary" @click="submitRenewForm">保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import messageForm from './components/messageForm'
import {
    getCustomerReply,
    putCustomerReply,
    postCustomerReply,
    fileUpload
} from "@/api/api";
export default {
    components: {
        messageForm
    },
    data() {
        return {
            id: undefined,
            editingField: [],
            newContent: {
                content: '',
                oneContent: '',
                twoContent: '',
                msgTime: '',
                startTime: '',
                endTime: '',
                startDate: '',
                endDate: '',
            },
            oldContent: {},
            date: [],

            tabIndex: 0,
            tabs: ['首条消息', '时间段内首充用户消息', '贵族开通时'],

            headers: {
                Authorization: "Bearer " + localStorage.token
            },
            uploadData: {
                code: 5001,
                index: 0
            },

            formLoading: false,
            sendDay: '',
            timeForm: {},
            renewForm: {}
        }
    },
    computed: {
        // 生效时间是否可提交
        timeCanSubmit() {
            if (
                this.newContent.msgTime && 
                (this.newContent.msgTime == 'time' ? this.newContent.startTime && this.newContent.endTime : this.newContent.startDate && this.newContent.endDate) && 
                this.checkFieldContent('msgTime')
            ) {
                return true
            }
            return false
        },
    },
    methods: {
        // 字段是否在编辑状态
        isEditing(field) {
            return this.editingField.includes(field)
        },
        // 字段进入编辑模式
        setEdit(field) {
            this.editingField.push(field)
            this.$nextTick(() => {
                this.$refs[field]?.focus()
            })
        },
        // 字段关闭编辑模式
        closeEdit(field) {
            const i = this.editingField.indexOf(field)
            this.editingField.splice(i, 1)
        },
        // 取消编辑
        async cancelTap(field) {
            // 判断内容是否有改动
            if (this.checkFieldContent(field)) {
                await new Promise((resolve) => {
                    this.$confirm('内容有修改调整，是否取消修改？','提示').then(() => {
                        resolve()
                    }).catch()
                })
            }

            // 确认取消修改时，将旧数据赋值到新数据
            if (field == 'msgTime') {
                const field = ['msgTime','startTime','endTime','startDate','endDate']
                for (const key of field) {
                    this.newContent[key] = this.oldContent[key] || ''
                    console.log(this.oldContent[key],key,'cancel')
                }
                // 日期要单独处理
                this.date = [this.newContent.startDate || '', this.newContent.endDate || '']
            } else {
                this.newContent[field] = this.oldContent[field]
            }
            // 关闭编辑模式
            this.closeEdit(field)
        },
        // 验证新旧内容是否相同
        checkFieldContent(field) {
            if (field == 'msgTime') {
                const field = ['msgTime','startTime','endTime','startDate','endDate']
                return field.some(key => {
                    const oldContent = this.oldContent[key] || ''
                    const newContent = this.newContent[key] || ''
                    return newContent.trim() != oldContent
                })
            } else {
                const oldContent = this.oldContent[field] || ''
                const newContent = this.newContent[field] || ''
                if (newContent.trim() !== oldContent) {
                    return true
                }
            }
            return false
        },
        // 提交修改数据
        submitField(field) {
            const fields = Object.keys(this.newContent)
            const formData = {}
            for (const key of fields) {
                if (['startTime','endTime','startDate','endDate'].includes(key)) continue;
                // 仅当前修改的字段使用新数据，其他内容从旧数据中获取
                if (key == field) {
                    if (key == 'msgTime') {
                        if (this.newContent.msgTime == 'time') {
                            const startString = parseInt(this.newContent.startTime.replace(/:/g, ''))
                            const endString = parseInt(this.newContent.endTime.replace(/:/g, ''))
                            // console.log(startString, endString, startString < endString, 'timeString')
                            if (startString <= endString) {
                                this.$message.error('设置单日时间段不能超过二十四小时')
                                return;
                            }

                            formData.startTime = this.newContent.startTime
                            formData.endTime = this.newContent.endTime
                        } else {
                            /* const startDate = new Date(this.newContent.startDate).getTime()
                            const endDate = new Date(this.newContent.endDate).getTime()
                            if (startDate == endDate) {
                                this.$message.error('开始时间不能等于结束时间')
                                return;
                            } */

                            formData.startDate = this.newContent.startDate
                            formData.endDate = this.newContent.endDate
                        }
                    } else {
                        formData[key] = this.newContent[key].trim()
                    }
                } else {
                    if (key == 'msgTime') {
                        if (this.oldContent.msgTime == 'time') {
                            formData.startTime = this.oldContent.startTime
                            formData.endTime = this.oldContent.endTime
                        } else {
                            formData.startDate = this.oldContent.startDate
                            formData.endDate = this.oldContent.endDate
                        }
                    } else {
                        formData[key] = this.oldContent[key]
                    }
                }
            }
            if (this.id) formData.id = this.id
            
            console.log(formData,'formData')
            // return;
            const sendFn = this.id ? putCustomerReply : postCustomerReply

            sendFn(formData).then(res => {
                // 数据提交后，将当前提交字段的新数据赋值到旧数据
                if (field == 'msgTime') {
                    if (this.newContent.msgTime == 'time') {
                        this.oldContent.startTime = this.newContent.startTime
                        this.oldContent.endTime = this.newContent.endTime
                    } else {
                        this.oldContent.startDate = this.newContent.startDate
                        this.oldContent.endDate = this.newContent.endDate
                    }
                } else {
                    this.oldContent[field] = this.newContent[field].trim()
                }
                // 提交成功后关闭编辑模式
                this.closeEdit(field)
            }).catch()
        },
        imgTap() {
            if (this.newContent.twoContent) return;
            this.chooseImg()
        },
        // 选择文件
        chooseImg() {
            const uploadDom = this.$refs.upload
            const inputDom = this.$refs.upload.$refs['upload-inner'].$refs.input
            inputDom.click()
        },
        // 文件上传成功
        uploadSuccess(response, file) {
            const img = response.data
            this.newContent.twoContent = img
            this.oldContent.twoContent = img
            this.submitField('twoContent')
            // 上传成功等待渲染变化之后清除组件中记录的文件列表
            this.$nextTick(() => {
                this.$refs.upload.clearFiles()
            })
        },
        deleteImg() {
            this.$confirm('是否要删除该图片？','删除图片').then(() => {
                this.newContent.twoContent = ''
                this.oldContent.twoContent = ''
                this.submitField('twoContent')
            }).catch()
        },
        dateChange(e) {
            this.newContent.startDate = e[0]
            this.newContent.endDate = e[1]
        },

        async getData(type) {
            if (type == 0) {
                // 首条消息
                getCustomerReply({
                    type: 0
                }).then(res => {
                    this.id = res.id
                    Object.keys(this.newContent).forEach(key => {
                        if (key == 'msgTime') return;

                        this.newContent[key] = res[key]
                        this.oldContent[key] = res[key]
                    })
                    const msgTime = (res.startDate || res.startTime) ? res.startTime ? 'time' : 'date' : undefined
                    this.newContent.msgTime = msgTime
                    this.oldContent.msgTime = msgTime
                    if (msgTime == 'date') {
                        this.date = [res.startDate, res.endDate]
                    }
                })
            } else {
                let formName = ['', 'timeForm', 'renewForm']
                const formData = {}
                for (let platform of ['android', 'ios']) {
                    formData[platform] = {}
                    const res = await getCustomerReply({ type, platform })
                    if (res) {
                        formData[platform] = res

                        if (type == 1 && res.sendDay) {
                            this.sendDay = res.sendDay
                        }
                    }
                }
                this.$refs[formName[type]].setForm(formData)
            }
        },

        getDatas() {

            this.getData(0)
            this.$nextTick(() => {
                this.getData(1)
                this.getData(2)
            })
        },

        async submitTimeForm() {
            console.log(this.timeForm,'timeForm')
            if (!this.$refs.timeForm.formValidate()) return;
            
            if (this.sendDay <= 0) {
                this.$message.error('时间段不能小于0')
                return;
            }

            this.formLoading = true
            
            const androidSend = this.timeForm.android.id ? putCustomerReply : postCustomerReply
            const androidRes = androidSend({ type: 1, platform: 'android', sendDay: this.sendDay, ...this.timeForm.android})

            await new Promise((resolve) => setTimeout(() => resolve(), 1500))

            const iosSend = this.timeForm.ios.id ? putCustomerReply : postCustomerReply
            const iosRes = iosSend({ type: 1, platform: 'ios', sendDay: this.sendDay, ...this.timeForm.ios})

            Promise.all([androidRes, iosRes]).then(res => {
                console.log(res,'submit timeform res')
                this.formLoading = false
                this.getData(1)
            }).catch(() => {
                this.formLoading = false
            })
        },

        async submitRenewForm() {
            if (!this.$refs.renewForm.formValidate()) return;
            
            if (this.sendDay <= 0) {
                this.$message.error('时间段不能小于0')
                return;
            }

            this.formLoading = true
            
            const androidSend = this.renewForm.android.id ? putCustomerReply : postCustomerReply
            const androidRes = androidSend({ type: 2, platform: 'android', ...this.renewForm.android})

            await new Promise((resolve) => setTimeout(() => resolve(), 1500))

            const iosSend = this.renewForm.ios.id ? putCustomerReply : postCustomerReply
            const iosRes = iosSend({ type: 2, platform: 'ios', ...this.renewForm.ios})

            Promise.all([androidRes, iosRes]).then(res => {
                console.log(res,'submit renewform res')
                this.formLoading = false
                this.getData(2)
            }).catch(() => {
                this.formLoading = false
            })
        }
    },
    created() {
        this.getDatas()
    }
}
</script>

<style scoped>
.flex {
    display: flex;
}

.flex-items-start {
    align-items: start;
}

.flex-items-center {
    align-items: center;
}

.flex-items-end {
    align-items: end;
}

.text-left {
    text-align: left;
}

.tabs-row {
    width: 60%;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
}

.tabs-row .tab {
    font-size: 18px;
    cursor: pointer;
}

.tabs-row .tab.on {
    font-weight: bold;
    color: #284564;
    border-bottom: 2px solid #284564;
}

.module-box {
    margin-bottom: 40px;
    text-align: left;
}

.module-box .form {
    margin-left: 20px;
}
.content-row .el-button{
    margin-left: 10px;
}

.img-box {
    width: 120px;
    height: 120px;
    font-size: 40px;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
}

.uploadFileInput {
    display: none;
}

.time-box {
    width: fit-content;
    padding: 10px;
    border: 1px solid #ddd;
}

.time-box .time-row:not(:first-child) {
    margin: 10px 0 0;
}

.time-box .time-btns {
    margin-left: 10px;
}

.date-row {
    margin-bottom: 20px;
}
</style>