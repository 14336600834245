<template>
    <div>
        <div class="flex-shrink" style="text-align:left;">
			<el-form :inline="true" :model="query" class="demo-form-inline" size="mini">
				<el-form-item label="用户编号">
					<el-input v-model="query.userNumber" placeholder="用户编号" style="width: 110px" @keydown.enter.native="search"></el-input>
				</el-form-item>
				<el-form-item label="登录来源">
                    <el-select v-model="query.source" placeholder="登录来源" style="width: 100px;">
						<el-option label="全部" value=""></el-option>
						<el-option :label="item.label" :value="item.name" v-for="(item, index) in loginSourceAry" :key="index"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="设备识别码">
                    <el-input v-model="query.uniqueCode" placeholder="设备识别码" style="width: 110px" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="ip">
                    <el-input v-model="query.ip" placeholder="ip" style="width: 110px" @keydown.enter.native="search"></el-input>
                </el-form-item>
				<el-form-item label="登录时间">
					<el-date-picker v-model="loginTime" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="用户注册时间">
					<el-date-picker v-model="registerTime" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']" style="width: 300px;">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
					<el-button type="primary" icon="el-icon-refresh" @click="refresh">重置</el-button>
                    <el-button type="warning" icon="el-icon-document" @click="exportData">导出excel</el-button>
				</el-form-item>
			</el-form>
		</div>
        <el-table id="mainTable" :max-height="tableHeight" :data="tableData" border style="width: 100%" :cell-style="{ padding: '0' }"
			:header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
			:row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
			<el-table-column prop="userNumber" label="用户编号" min-width="70" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
			<el-table-column prop="nickName" label="昵称" min-width="120" align="center"></el-table-column>
            <el-table-column prop="ip" label="客户端ip" min-width="80" align="center"></el-table-column>
            <el-table-column prop="brand" label="设备品牌" min-width="80" align="center"></el-table-column>
			<el-table-column prop="model" label="设备型号" min-width="90" align="center"></el-table-column>
			<el-table-column prop="uniqueCode" label="设备识别码" min-width="120" align="center"></el-table-column>
			<el-table-column prop="deviceVersion" label="终端系统版本" min-width="70" align="center"></el-table-column>
			<el-table-column prop="appVersion" label="oiki版本" min-width="60" align="center"></el-table-column>
			<el-table-column prop="source" label="登录来源" min-width="60" align="center"></el-table-column>
			<el-table-column prop="loginTime" label="登录时间" min-width="100" align="center"></el-table-column>
			<el-table-column prop="registerTime" label="用户注册时间" min-width="100" align="center"></el-table-column>
			<!-- <el-table-column fixed="right" label="操作" width="80" align="center">
				<template slot-scope="scope">
					<el-button type="danger" size="mini" @click="deleteRow(scope.row.id)">删除</el-button>
				</template>
			</el-table-column> -->
		</el-table>
		<div style="text-align:right;">
			<el-pagination style="margin-top: 10px;" background layout="slot, total, prev, pager, next, sizes"
				:page-size="size" :total="total" :page-sizes="[10, 20, 50, 100]" :current-page="page" @size-change="handleSizeChange" @current-change="changeClick">
				<span class="page-text">共{{ userTotal }}人</span>
			</el-pagination>
		</div>
    </div>
</template>

<script>
import {
    loginRecordPage,
	loginRecordDelete,
	loginRecordStatistics
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
			page: 1,
			size: 10,
			total: 0,
			tableData: [],
			tableHeight: null,
            query: {
				nickName: '',
				source: '',
				userNumber: '',
				uniqueCode: '',
				ip: '',
            },

			loginSourceAry: [
				{ name: 'android', label: '安卓' },
				{ name: 'ios', label: '苹果' },
				// { name: 'h5_oiki', label: 'h5' },
				// { name: 'mp_douyin', label: '抖音小程序' },
				// { name: 'mp_weixin', label: '微信小程序' }
			],

			userTotal: 0,

			pickerOptions: {
				timezone: 'GMT+8',
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			loginTime: [],
			registerTime: [],

            requestSignal: null
        }
    },
	watch: {
        tableData: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
		search() {
			this.page = 1;
			this.getLogList();
		},
		refresh() {
			Object.keys(this.query).forEach(key => this.query[key] = '')
			this.loginTime = []
			this.registerTime = []

			this.page = 1
			this.getLogList();
		},
		handleSizeChange(size) {
			this.size = size
			this.getLogList()
		},
		changeClick(page) {
			this.page = page
			this.getLogList()
		},
		getParams() {
			return {
				nickName: this.query.nickName,
				userNumber: this.query.userNumber,
				source: this.query.source,
				uniqueCode: this.query.uniqueCode,
				ip: this.query.ip,
				loginStartTime: this.loginTime != null ? this.loginTime[0] : null,
				loginEndTime: this.loginTime != null ? this.loginTime[1] : null,
				registerStartTime: this.registerTime != null ? this.registerTime[0] : null,
				registerEndTime: this.registerTime != null ? this.registerTime[1] : null
			}
		},
		//获取日志列表接口
		getLogList() {
            if (this.requestSignal) this.requestSignal.abort()
            this.requestSignal = new AbortController()

			const params = this.getParams()
			loginRecordPage({
				current: this.page,
				size: this.size,
				...params
			}, this.requestSignal.signal).then((response) => {
                this.total = response.total
                this.tableData = response.records
            })
            .catch((error) => {
                console.log(error)
            });

			this.getStatistics()
		},


		getStatistics() {
			const params = this.getParams()

			loginRecordStatistics(params).then(response => {
				this.userTotal = response
			})
		},

        // 导出列表数据
		exportData() {
			this.$prompt('请输入导出数据条数(默认100)','导出数据', {
				closeOnClickModal: false
			}).then(({value}) => {
				let number = value != null ? parseInt(value) : 100
				if (isNaN(number)) {
					this.$message.error('请输入整型数值！')
					return;
				} else if (number > 2147483647) {
					this.$message.info('数值超过int最大长度，已变更为2147483647！')
					number = 2147483647
				}

				const exportUrl = '/api/loginRecord/export'

				const params = this.getParams()

				params.current = 1
				params.size = number
				
				let urlParams = '?'
				Object.keys(params).forEach(key => {
					const value = params[key]
					if (value) {
						urlParams += '&' + key + '=' + encodeURIComponent(value)
					}
				})

				console.log(urlParams,'urlParams')
				const fileName = 'app登录日志.xlsx'
				const xhr = new XMLHttpRequest()
				xhr.open('GET', exportUrl + urlParams, true)
				xhr.responseType = 'blob'
				xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.token)
				xhr.onload = () => {
					if (xhr.status == 200) {
						const type = xhr.getResponseHeader('Content-Type')
						const blob = new Blob([xhr.response], {type})
						if (typeof window.navigator.msSaveBlob !== 'undefined') {
							window.navigator.msSaveBlob(blob, fileName)
						} else {
							const URL = window.URL || window.webkitURL
							const blobUrl = URL.createObjectURL(blob)

							const a = document.createElement('a')
							if (typeof a.download === 'undefined') {
								window.open(blobUrl)
							} else {
								a.href = blobUrl
								a.download = fileName
								document.body.appendChild(a)
								a.click()
								a.remove()
							}
						}
					} else {
						this.$message.error('导出失败')
					}
				}
				xhr.send()
			})
			
		},

		deleteRow(id) {
			this.$confirm('确定要删除此日志?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let params = new URLSearchParams()
                params.append("idList", [id])
                loginRecordDelete({ params })
                    .then(() => {
                        this.getLogList()
                    })
			})
		}
	},
	created() {
		//获取日志列表
		this.getLogList()

		window.onresize = () => {
            this.setTableHeight()
        }
	}
}
</script>

<style scoped>
.page-text {
	font-weight: lighter;
	margin-right: 20px;
}
</style>