<template>
    <div>

        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item>
                    <el-button type="success" @click="addShow = true">添加</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="number" label="版本号" min-width="100" align="center"></el-table-column>
            <el-table-column prop="platform" label="平台" min-width="100" align="center"></el-table-column>
            <el-table-column prop="url" label="下载链接" min-width="200" align="center"></el-table-column>
            <el-table-column prop="remark" label="版本描述" min-width="300" align="center"></el-table-column>
            <el-table-column prop="isMustUpdate" label="是否强制更新" min-width="100" align="center">
                <template slot-scope="scope">
                    <span :class="'color-' + ['warning','success'][scope.row.isMustUpdate]">{{ ['否','是'][scope.row.isMustUpdate] }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="150" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="180" align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">编辑
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog title="添加版本" :visible.sync="addShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form :model="addForm">
				<el-form-item label="版本号" :label-width="formLabelWidth">
					<el-input v-model="addForm.number" placeholder="请输入版本号" autocomplete="off"></el-input>
				</el-form-item>
                <!-- <el-form-item label="平台" :label-width="formLabelWidth">
					<el-select v-model="addForm.platform" placeholder="请选择平台">
						<el-option label="苹果" value="ios"></el-option>
						<el-option label="安卓" value="android"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="下载地址" :label-width="formLabelWidth">
					<el-input v-model="addForm.url" placeholder="请输入下载地址" autocomplete="off"></el-input>
				</el-form-item> -->
                <el-form-item label="版本描述" :label-width="formLabelWidth">
					<el-input type="textarea" :rows="3" v-model="addForm.remark" placeholder="请输入版本描述" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="是否强制更新" :label-width="formLabelWidth">
					<el-switch v-model="addForm.isMustUpdate"></el-switch>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addShow = false">取 消</el-button>
				<el-button type="primary" @click="addVersion">确 定</el-button>
			</div>
		</el-dialog>

        <el-dialog title="编辑版本" :visible.sync="updateShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form :model="updateForm">
				<el-form-item label="版本号" :label-width="formLabelWidth">
					<el-input v-model="updateForm.number" placeholder="请输入版本号" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="平台" :label-width="formLabelWidth">
					<el-select v-model="updateForm.platform" placeholder="请选择平台">
						<el-option label="苹果" value="ios"></el-option>
						<el-option label="安卓" value="android"></el-option>
					</el-select>
				</el-form-item>
                <el-form-item label="下载地址" :label-width="formLabelWidth">
					<el-input v-model="updateForm.url" placeholder="请输入下载地址" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="版本描述" :label-width="formLabelWidth">
					<el-input type="textarea" :rows="3" v-model="updateForm.remark" placeholder="请输入版本描述" autocomplete="off"></el-input>
				</el-form-item>
                <el-form-item label="是否强制更新" :label-width="formLabelWidth">
					<el-switch v-model="updateForm.isMustUpdate"></el-switch>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="updateShow = false">取 消</el-button>
				<el-button type="primary" @click="submitUpdate">确 定</el-button>
			</div>
		</el-dialog>

    </div>
</template>

<script>
import {
    versionPage,
    versionSave,
    versionUpdate,
    versionDelete
} from "@/api/api";
export default {
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            dataList: [],
            query: {
                giftName: ""
            },
            formLabelWidth: "120px",

            addShow: false,
            addForm: {
				number: '',
                url: '',
                platform: '',
                remark: '',
                isMustUpdate: false,
			},

            updateShow: false,
            updateForm: {
                id: '',
				number: '',
                url: '',
                platform: '',
                remark: '',
                isMustUpdate: false,
			},
        };
    },
    methods: {
        search() {
            this.page = 1;
            this.getVersionList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getVersionList();
        },
        //获取待审核动态分页列表
        getVersionList() {
            versionPage({
                current: this.page,
                size: this.size
            })
                .then(response => {
                    this.dataList = response.records
                })
        },

        //新增版本
		addVersion() {
			versionSave({
				number: this.addForm.number,
                // url: this.addForm.url,
                // platform: this.addForm.platform,
                url: 'android/oiki.apk',
                platform: 'android',
                remark: this.addForm.remark,
                isMustUpdate: this.addForm.isMustUpdate ? 1 : 0,
			})
				.then(() => {
					this.getVersionList() //保存成功，重新获取数据
				})
			this.addShow = false

            // 添加完成后清空表单数据
            this.$nextTick(() => {
                this.addForm.number = ''
                this.addForm.url = ''
                this.addForm.platform = ''
                this.addForm.remark = ''
                this.addForm.isMustUpdate = false
            })
		},

        //编辑版本
		updateClick(row) {
			this.updateShow = true
			this.updateForm.id = row.id
			this.updateForm.number = row.number
            this.updateForm.url = row.url
			this.updateForm.platform = row.platform
			this.updateForm.remark = row.remark
			this.updateForm.isMustUpdate = !!row.isMustUpdate
		},
        //提交编辑事件
		submitUpdate() {
			versionUpdate({
                id: this.updateForm.id,
                number: this.updateForm.number,
                url: this.updateForm.url,
                platform: this.updateForm.platform,
                remark: this.updateForm.remark,
                isMustUpdate: this.updateForm.isMustUpdate ? 1 : 0
            }).then(() => {
                this.getVersionList();
            })
            this.updateShow = false
		},

        //删除礼物事件
        deleteClick(id) {
            this.$confirm('确定要删除此版本?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.deleteVersion(id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
					center: true
				});
			});
        },
        deleteVersion(id) {
            let params = new URLSearchParams()
			params.append("idList", [id])
			versionDelete({ params })
				.then(() => {
					this.getVersionList();
				})
        }
    },
    mounted() {
        this.getVersionList();
    }
};
</script>