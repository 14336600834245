<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="用户ID">
                    <el-input v-model="query.userNumber" placeholder="请输入用户ID" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称" @keydown.enter.native="search"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="type" label="保存方式" min-width="150" align="center">
                <template slot-scope="scope">
					{{ ['截图','二维码'][scope.row.type] }}
				</template>
            </el-table-column>
            <el-table-column prop="userNumber" label="用户ID" min-width="150" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="nickName" label="用户昵称" min-width="150" align="center"></el-table-column>
            <el-table-column prop="gender" label="性别" min-width="100" align="center">
                <template slot-scope="scope">
					{{ {1: '男', 2: '女'}[scope.row.gender] }}
				</template>
            </el-table-column>
            <el-table-column prop="headImg" label="头像" min-width="80" align="center">
                <template slot-scope="scope">
					<div class="img-content">
						<el-image v-if="scope.row.headImg" style="width: 40px; height: 40px;"
							:src="'https://file.oiki.cc/' + scope.row.headImg" :preview-src-list="['https://file.oiki.cc/' + scope.row.headImg]">
						</el-image>
					</div>
				</template>
            </el-table-column>
            <el-table-column prop="createTime" label="操作时间" min-width="150" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="60" align="center">
                <template slot-scope="scope">
                    <el-link type="primary" @click="toDetail(scope.row.userId)">详情</el-link>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    customIntentionPage
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                nickName: "",
                userNumber: "",
            },
        }
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getList();
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getList();
        },
        getList() {
            customIntentionPage({
                current: this.page,
                size: this.size,
                nickName: this.query.nickName,
                userNumber: this.query.userNumber,
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                })
        },
        toDetail(id) {
			//this.$router.push(`/user/${id}`)
			//改变需求，打开新窗口
			let route = this.$router.resolve({path: `/user/${id}`})
			window.open(route.href)
		}
    },
    mounted() {
        this.getList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
}
</script>