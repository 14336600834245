<template>
    <div>
        <div class="flex-shrink" style="height: 100%; text-align:left; vertical-align:middle;">
            <el-form :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="用户昵称">
                    <el-input v-model="query.nickName" placeholder="请输入用户昵称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table  id="mainTable" :max-height="tableHeight" :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
            <el-table-column prop="nickName" label="申请人昵称" min-width="120" align="center"></el-table-column>
            <el-table-column prop="userNumber" label="申请人编号" min-width="120" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.userId" target="_blank">{{ scope.row.userNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="applyNickName" label="被申请人昵称" min-width="150" align="center"></el-table-column>
            <el-table-column prop="applyUserNumber" label="被申请人编号" min-width="150" align="center">
                <template slot-scope="scope">
                    <a :href="'/user/' + scope.row.applyUserId" target="_blank">{{ scope.row.applyUserNumber }}</a>
                </template>
            </el-table-column>
            <el-table-column prop="isLike" label="是否互相喜欢" min-width="150" align="center">
                <template slot-scope="scope">
					<span v-if="scope.row.isLike=== 0" style="color: #E6A23C">否</span>
                    <span v-else-if="scope.row.isLike=== 1" style="color: #67C23A">是</span>
				</template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="140" align="center"></el-table-column>
            <el-table-column prop="remark" label="备注" min-width="250" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">备注</el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteClick(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>

        <el-dialog title="客服备注" :visible.sync="updateShow" :close-on-click-modal="false" width="25%" center zIndex=1999>
			<el-form :model="updateForm">
				<el-form-item label="客服备注" :label-width="formLabelWidth">
					<el-input type="textarea" :rows="3" v-model="updateForm.remark" placeholder="请输入客服备注" autocomplete="off"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="updateShow = false">取 消</el-button>
				<el-button type="primary" @click="submitUpdate">确 定</el-button>
			</span>
		</el-dialog>
    </div>
</template>

<script>
import {
    customApplyPage,
    customApplyUpdate,
    customApplyDelete
} from "@/api/api";
import setTableHeight from '@/utils/setTableHeight'
export default {
    data() {
        return {
            page: 1,
            size: 10,
            total: 0,
            dataList: [],
            tableHeight: null,
            query: {
                nickName: ""
            },
            formLabelWidth: "80px",

            updateShow: false,
            updateForm: {
                id: "",
                remark: ""
            }
        };
    },
    watch: {
        dataList: {
            handler() {
                this.setTableHeight()
            }
        }
    },
    methods: {
        setTableHeight() {
            this.$nextTick(() => {
                this.tableHeight = setTableHeight('#mainTable','.flex-shrink')
            })
        },
        search() {
            this.page = 1;
            this.getCustomApplyList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getCustomApplyList();
        },
        //分页获取列表
        getCustomApplyList() {
            customApplyPage({
                current: this.page,
                size: this.size,
                nickName: this.query.nickName
            })
                .then(response => {
                    this.total = response.total
                    this.dataList = response.records
                })
        },
        //编辑事件
        updateClick(row) {
            this.updateShow = true,
            this.updateForm.id = row.id,
            this.updateForm.remark = remark
        },
        //编辑提交
        submitUpdate() {
            customApplyUpdate({
                id: this.updateForm.id,
                remark: this.updateForm.remark
            }).then(() => {
                this.getCustomApplyList();
            })
            this.updateShow = false
        },
        //删除事件
        deleteClick(id) {
            this.$confirm('确定要删除此交友申请?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.deleteCustomApply(id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除',
                    center: true
                });
            });
        },
        //删除请求
        deleteCustomApply(id) {
            let params = new URLSearchParams()
            params.append("idList", [id])
            customApplyDelete({ params })
                .then(() => {
                    window.location.reload() //刷新页面
                })
        },
    },
    mounted() {
        this.getCustomApplyList();

        window.onresize = () => {
            this.setTableHeight()
        }
    }
};
</script>