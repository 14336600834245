<template>
    <div>
        <div style="height: 100%; text-align:left; vertical-align:middle;">
            <el-button type="primary" @click="addClick" size="mini" icon="el-icon-plus" style="margin-bottom: 10px">添加
            </el-button>
        </div>
        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">
            <el-table-column prop="name" label="角色名称" min-width="150" align="center">
            </el-table-column>
            <el-table-column prop="code" label="角色编码" min-width="100" align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" min-width="120" align="center">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="400" align="center">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" icon="el-icon-connection" @click="bindMenu(scope.row.id)">绑定菜单
                    </el-button>
                    <el-button type="warning" size="mini" icon="el-icon-edit" @click="updateClick(scope.row)">编辑
                    </el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" @click="deleteById(scope.row.id)">删除
                    </el-button>
                    <el-button type="primary" size="mini" icon="el-icon-connection"
                        @click="bindOperation(scope.row.id)">绑定权限
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="添加角色" :visible.sync="addShow" :close-on-click-modal="false" width="25%" center>
            <el-form :model="addForm">
                <el-form-item label="角色名称" :label-width="formLabelWidth">
                    <el-input v-model="addForm.roleName" placeholder="请输入角色名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="角色编码" :label-width="formLabelWidth">
                    <el-input v-model="addForm.roleCode" placeholder="请输入角色编码" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addShow = false">取 消</el-button>
                <el-button type="primary" @click="addRole">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑角色" :visible.sync="updateShow" :close-on-click-modal="false" width="25%" center>
            <el-form :model="updateForm">
                <el-form-item label="角色名称" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.roleName" placeholder="请输入角色名称" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="角色编码" :label-width="formLabelWidth">
                    <el-input v-model="updateForm.roleCode" placeholder="请输入角色编码" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="updateShow = false">取 消</el-button>
                <el-button type="primary" @click="updateRole">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="绑定菜单" :visible.sync="bindShow" :close-on-click-modal="false" width="15%" center zIndex=1999>
            <el-tree :data="menuList" show-checkbox node-key="id" ref="tree" :props="props"
                :default-checked-keys="selectedIds" default-expand-all check-on-click-node check-strictly
                @check="checkTree"> </el-tree>
            <span slot="footer" class="dialog-footer">
                <el-button @click="bindShow = false">取 消</el-button>
                <el-button type="primary" @click="submitBind()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="绑定权限" :visible.sync="bindOperationShow" :close-on-click-modal="false" width="15%" center zIndex=1999>
            <el-tree :data="menuOperationList" show-checkbox node-key="id" ref="operationTree" :props="props"
                :default-checked-keys="selectOperationIds" default-expand-all check-on-click-node check-strictly>
            </el-tree>
            <span slot="footer" class="dialog-footer">
                <el-button @click="bindOperationShow = false">取 消</el-button>
                <el-button type="primary" @click="submitBindOperation()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
    rolePage,
    roleSave,
    roleDelete,
    roleUpdate,
    menuTreeList,
    roleBindMenu,
    roleMenu,
    menuOperationTree,
    roleBindOperation,
    roleOperation
} from '@/api/api';
export default {
    data() {
        return {
            dataList: [],
            menuList: [],
            addShow: false,
            updateShow: false,
            bindShow: false,
            bindOperationShow: false,
            addForm: {
                roleName: '',
                roleCode: ''
            },
            updateForm: {
                id: '',
                roleName: '',
                roleCode: ''
            },
            formLabelWidth: '80px',
            props: {
                label: 'menuName',
                children: 'childList'
            },
            selectRoleId: '', //绑定菜单选中的roleId
            selectedIds: [], //默认选中的id
            menuOperationList: [],
            selectOperationIds: [],//默认选中的权限id
            defaultSelectIds: []//绑定权限时默认选中的菜单id
        }
    },
    methods: {
        //添加角色事件
        addClick() {
            this.addShow = true
        },
        search() {
            this.getUserList();
        },
        //编辑角色
        updateRole() {
            this.updateShow = false
            roleUpdate({
                id: this.updateForm.id,
                name: this.updateForm.roleName,
                code: this.updateForm.roleCode
            }).then(() => {
                this.getRoleList();
            })
        },
        //编辑用户事件
        updateClick(row) {
            this.updateShow = true
            this.updateForm.id = row.id
            this.updateForm.roleName = row.name
            this.updateForm.roleCode = row.code
        },
        //删除角色事件
        deleteById(id) {
            this.$confirm('确定要删除此角色?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deleteRole(id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除',
                    center: true
                });
            });
        },
        //获取角色列表接口
        getRoleList() {
            rolePage(null).then((response) => {
                this.dataList = response.records
            })
        },
        //删除角色接口
        deleteRole(id) {
            let params = new URLSearchParams()
            params.append("idList", [id])
            roleDelete({params})
                .then(() => {
                    window.location.reload() //刷新页面
                    this.getRoleList() //删除成功，重新获取数据
                })
        },
        //新增角色
        addRole() {
            roleSave({
                "name": this.addForm.roleName,
                "code": this.addForm.roleCode
            })
                .then(() => {
                    this.getRoleList() //保存成功，重新获取数据
                })
            this.addShow = false
        },
        //获取选中角色菜单列表
        getRoleMenuList() {
            if (this.selectedIds && this.selectedIds.length > 0) {
                this.$refs.tree.setCheckedKeys([])
            }
            this.selectedIds = []
            roleMenu(this.selectRoleId, null)
                .then((response) => {
                    this.selectedIds = response
                    this.getTreeList()
                })
        },
        //绑定菜单
        bindMenu(id) {
            this.bindShow = true
            this.selectRoleId = id
            this.getRoleMenuList()
        },
        //获取菜单树结构
        getTreeList() {
            menuTreeList(null)
                .then(response => {
                    this.menuList = this.cleanEmptyMenu(response);
                })
        },
        //递归解决数组为空导致的级联错误
        cleanEmptyMenu(data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i].childList == null || data[i].childList.length < 1) {
                    data[i].childList = undefined;
                } else {
                    this.cleanEmptyMenu(data[i].childList);
                }
            }
            return data;
        },
        //选中子节点，默认选中父节点
        checkTree(data) {
            let node = this.$refs.tree.getNode(data.id) //获取当前节点
            const keys = this.$refs.tree.getCheckedKeys() //获取已勾选节点的key
            if (node.checked) {
                //当前节点如果被选中,选中父节点
                for (let i = node.level; i > 1; i--) {
                    if (!node.parent.checked) {
                        keys.push(node.parent.data.id)
                    }
                }
                //当前节点被选中，选中子节点
                for (let i = 0; i < node.childNodes.length; i++) {
                    if (!node.childNodes[i].checked) {
                        keys.push(node.childNodes[i].data.id)
                    }
                }
            } else {
                console.log(node)
                //当父节点取消选中，所有子节点也取消
                for (let i = 0; i < node.childNodes.length; i++) {
                    if (node.childNodes[i].checked) {
                        keys.splice(keys.indexOf(node.childNodes[i].data.id), 1)
                    }
                }
                //当取消选中父节点下的最后一个子节点，父节点也需要一起取消选中
                let parentNode = node.parent
                let isCancel = true
                for (let i = 0; i < parentNode.childNodes.length; i++) {
                    if (parentNode.childNodes[i].checked) {
                        isCancel = false
                    }
                }
                if (isCancel) {
                    keys.splice(keys.indexOf(parentNode.data.id), 1)
                }
            }
            this.$refs.tree.setCheckedKeys(keys) // 将所有keys数组的节点全选中
            console.log(this.$refs.tree.getCheckedKeys())
        },
        //提交绑定
        submitBind() {
            this.bindShow = false
            roleBindMenu({
                roleId: this.selectRoleId,
                menuIds: this.$refs.tree.getCheckedKeys()
            }).then(() => {
                window.location.reload() //刷新页面
                this.getRoleList() //重新获取数据
            })
        },

        //获取角色对应的权限
        getRoleOperation() {
            if (this.selectOperationIds && this.selectOperationIds.length > 0) {
                this.$refs.operationTree.setCheckedKeys([])
            }
            this.selectOperationIds = []
            this.menuOperationList = []
            roleOperation(this.selectRoleId, null)
                .then((response) => {
                    this.selectOperationIds = response
                    this.getMenuOperationTree()
                })
        },
        //绑定权限
        bindOperation(id) {
            this.bindOperationShow = true
            this.selectRoleId = id
            this.getRoleOperation()
        },
        //获取用户菜单权限树结构
        getMenuOperationTree() {
            menuOperationTree(this.selectRoleId, null)
                .then(response => {
                    this.menuOperationList = this.cleanMenuOperation(response)
                })
        },
        //递归调整菜单和权限统一字段
        cleanMenuOperation(data) {
            for (var i = 0; i < data.length; i++) {
                data[i].disabled = true
                this.selectOperationIds.push(data[i].id)
                this.defaultSelectIds.push(data[i].id)
                if (data[i].childList == null || data[i].childList.length < 1) {
                    if (data[i].operationList.length > 0) {
                        data[i].childList = []
                        for (let m = 0; m < data[i].operationList.length; m++) {
                            let operation = data[i].operationList[m]
                            data[i].childList.push({ 'id': operation.id, 'menuName': operation.name })
                        }
                    } else {
                        data[i].childList = undefined;
                    }
                } else {
                    this.cleanMenuOperation(data[i].childList);
                }
            }
            return data;
        },
        //提交权限绑定
        submitBindOperation() {
            this.bindOperationShow = false
            let operationIds = this.$refs.operationTree.getCheckedKeys().filter(items => { if (!this.defaultSelectIds.includes(items)) return items })
            if (operationIds.length > 0) {
                roleBindOperation({
                    roleId: this.selectRoleId,
                    operationIds: operationIds
                }).then(() => {
                    window.location.reload() //刷新页面
                    this.getRoleList() //重新获取数据
                })
            } else {
                this.$message.error("请选择权限")
            }
        }
    },
    mounted() {
        this.getRoleList()
    }
}
</script>
