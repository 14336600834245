<template>
    <div>
        <el-table :data="dataList" border style="width: 100%" :cell-style="{ padding: '0' }"
            :header-cell-style="{ background: '#F3F9FF', color: '#999', fontFamily: 'MicrosoftYaHeiUI', fontSize: '14px', fontWeight: 900 }"
            :row-style="{ fontSize: '12px', color: '#666', fontFamily: 'MicrosoftYaHeiUI', height: '45px' }">

            <el-table-column prop="createTime" label="日期" min-width="150" align="center"></el-table-column>
            <el-table-column prop="iosDownNumber" label="ios下载量" min-width="150" align="center"></el-table-column>
            <el-table-column prop="androidDownNumber" label="安卓下载量" min-width="150" align="center"></el-table-column>
            <el-table-column prop="iosRegisterNumber" label="ios注册数" min-width="150" align="center"></el-table-column>
            <el-table-column prop="androidRegisterNumber" label="安卓注册数" min-width="150" align="center"></el-table-column>
            <el-table-column prop="webRegisterNumber" label="h5注册数" min-width="150" align="center"></el-table-column>
        </el-table>
        <div style="text-align:right;">
            <el-pagination style="margin-top: 10px;" background :hide-on-single-page="true" layout="total, prev, pager, next"
                :page-size="size" :total="total" :current-page="page" @current-change="changeClick">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    everydayPage
} from "@/api/api";
export default {
    data() {
        return {
            page: 1,
            size: 15,
            total: 0,
            dataList: [],
            formLabelWidth: "80px",
        };
    },
    methods: {
        search() {
            this.getEverydayList();
        },
        //查看点击事件
        handleClick(row) {
            console.log(row);
        },
        //点击分页事件
        changeClick(page) {
            this.page = page;
            this.getEverydayList();
        },
        //获取产品分页列表
        getEverydayList() {
            everydayPage({
                current: this.page,
                size: this.size
            })
                .then(response => {
                    this.total = response.total;
                    this.dataList = response.records;
                })
        },
    },
    mounted() {
        this.getEverydayList();
    }
};
</script>